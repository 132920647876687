/***********************************************
                OVERLAY FORM
***********************************************/
.overlayForm {
    // height: 100%;
    background: #fafafa;
    box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 70px;
    left: 0;
    right: 0;
    margin: auto;
    width: 90%;
    max-width: 500px;
    z-index: 1120;
    -webkit-transform: translateY(-500%);
    -moz-transform: translateY(-500%);
    -ms-transform: translateY(-500%);
    transform: translateY(-500%);
    transition: transform 0.1s ease;
    will-change: transform;
    
}
.overlayForm.open {
    transform: translateY(0);
    overflow: hidden;
    position: fixed;
}
.displayNone {
    display: none !important;
}

/***********************************************
            DOUBLE OVERLAY FORM
***********************************************/
.doubleoverlayForm {
    // height: 100%;
    background: #fafafa;
    box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 210px;
    left: 0;
    right: 0;
    margin: auto;
    width: 90%;
    max-width: 240px;
    z-index: 1520;
    -webkit-transform: translateY(-500%);
    -moz-transform: translateY(-500%);
    -ms-transform: translateY(-500%);
    transform: translateY(-500%);
    transition: transform 0.1s ease;
    will-change: transform;
    
}
.doubleoverlayForm.open {
    transform: translateY(0);
    overflow: hidden;
    position: fixed;
}
.doubledisplayNone {
    display: none !important;
}