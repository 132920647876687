@import 'colorfonts';

/***********************************************
                   ADMIN
***********************************************/

.adminTopCard {
    border: 0;
    border-radius: 0px;
    margin-bottom: 30px;
    -webkit-box-shadow: 0 2px 3px rgba(0,0,0,0.03);
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12);
    -webkit-transition: .5s;
    transition: .5s;
    color: $colorWhite;                
}
.adminTopWrapper {
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    max-height: 100%;
    place-content: stretch space-between;
    align-items: stretch;
}
.adminTopInner {
    flex-direction: column;
    box-sizing: border-box;
    display: flex;
    max-width: 100%;
    align-items: stretch;
}
.adminTopInner .value {
    font-size: 36px;
    margin-top: 12px;
    margin-bottom: 12px;
}
.adminTopInner .property {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
}
.adminTopIcon {
    font-size: 25px;
    height: 35px;
    width: 25px !important;
    line-height: 25px !important;
    margin-top: -8px;
}
.adminTopData {
    flex-direction: row; 
    box-sizing: border-box; 
    display: flex; 
    max-height: 100%; 
    place-content: center flex-end; 
    align-items: center; 
    align-self: flex-end;
}
.pcspacebetween {
    place-content: stretch space-between;
}
.pcflexend {
    place-content: stretch flex-end;
}
.asflexend {
    align-self: flex-end;
}
.adminTopColor1 {
    background: rgba(0, 0, 0, 0) linear-gradient(60deg, rgb(140, 153, 224), rgb(101, 114, 184)) repeat scroll 0% 0%;
}
.adminTopColor2 {
    background: rgba(0, 0, 0, 0) linear-gradient(60deg, rgb(77, 208, 226), rgb(76, 168, 186)) repeat scroll 0% 0%;
}
.adminTopColor3 {
    background: rgba(0, 0, 0, 0) linear-gradient(60deg, rgb(129, 198, 131), rgb(98, 168, 100)) repeat scroll 0% 0%;
}

/***********************************************
                   ADMIN
***********************************************/
// .wrapper-dropdown-3 {
//     /* Size and position */
//     position: relative;
//     width: 200px;
//     margin: 0 auto;
//     padding: 10px;

//     /* Styles */
//     background: #fff;
//     border-radius: 7px;
//     border: 1px solid rgba(0,0,0,0.15);
//     box-shadow: 0 1px 1px rgba(50,50,50,0.1);
//     cursor: pointer;
//     outline: none;

//     /* Font settings */
//     font-weight: bold;
//     color: #8AA8BD;
// }
// .wrapper-dropdown-3:after {
//     content: "";
//     width: 0;
//     height: 0;
//     position: absolute;
//     right: 15px;
//     top: 50%;
//     margin-top: -3px;
//     border-width: 6px 6px 0 6px;
//     border-style: solid;
//     border-color: #8aa8bd transparent;
// }
// .wrapper-dropdown-3 .dropdown {
//     /* Size & position */
//       position: absolute;
//       top: 140%;
//       left: 0;
//       right: 0;
  
//       /* Styles */
//       background: white;
//       border-radius: inherit;
//       border: 1px solid rgba(0,0,0,0.17);
//       box-shadow: 0 0 5px rgba(0,0,0,0.1);
//       font-weight: normal;
//       transition: all 0.5s ease-in;
//       list-style: none;
  
//       /* Hiding */
//       opacity: 0;
//       pointer-events: none;
//   }
  
//   .wrapper-dropdown-3 .dropdown li a {
//       display: block;
//       padding: 10px;
//       text-decoration: none;
//       color: #8aa8bd;
//       border-bottom: 1px solid #e6e8ea;
//       box-shadow: inset 0 1px 0 rgba(255,255,255,1);
//       transition: all 0.3s ease-out;
//   }
  
//   .wrapper-dropdown-3 .dropdown li i {
//       float: right;
//       color: inherit;
//   }
  
//   .wrapper-dropdown-3 .dropdown li:first-of-type a {
//       border-radius: 7px 7px 0 0;
//   }
  
//   .wrapper-dropdown-3 .dropdown li:last-of-type a {
//       border-radius: 0 0 7px 7px;
//       border: none;
//   }
  
//   /* Hover state */
  
//   .wrapper-dropdown-3 .dropdown li:hover a {
//       background: #f3f8f8;
//   }

//   .wrapper-dropdown-3 .dropdown:after {
//     content: "";
//     width: 0;
//     height: 0;
//     position: absolute;
//     bottom: 100%;
//     right: 15px;
//     border-width: 0 6px 6px 6px;
//     border-style: solid;
//     border-color: #fff transparent;    
// }

// .wrapper-dropdown-3 .dropdown:before {
//     content: "";
//     width: 0;
//     height: 0;
//     position: absolute;
//     bottom: 100%;
//     right: 13px;
//     border-width: 0 8px 8px 8px;
//     border-style: solid;
//     border-color: rgba(0,0,0,0.1) transparent;    
// }
// .wrapper-dropdown-3.active .dropdown {
//     opacity: 1;
//     pointer-events: auto;
// }
