/***********************************************
                    GRID
***********************************************/
.leftmin135p {
    left: -135px !important;
}
.top99p {
    top: 99%;
}
.top60 {
    top: 60px;
}
.right0 {
    right: 0px;
}
.right10 {
    right: 10px;
}
.right20 {
    right: 20px;
}
.bottom20 {
    bottom: 20px;
}
.bottom30 {
    bottom: 30px;
}
.dBlock {
    display: block !important;
}
.dFlex {
    display: -ms-flex-box !important;
    display: flex !important;
}
.inlineFlex {
    display: inline-flex;
}
.dinContents {
    display: contents !important;
}
.dinBlock {
    display: inline-block !important;
}
.dinFlex {
    display: inline-flex !important;
}
.flexColumn {
    flex-direction: column !important;
}
.posAbsolute {
    position: absolute !important;
}
.posInherit {
    position: inherit !important;
}
.mxAuto {
    margin-left: auto !important;
    margin-right: auto !important;
}
.mlAuto {
    margin-left: auto !important;
}
.mrAuto {
    margin-right: auto !important;
}
.justifyContentCenter {
    -ms-flex-pack: center !important;
    justify-content: center !important;
}
.justifyContentBetween {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
}
.justifyContentFlexEnd {
    -ms-flex-pack: flex-end !important;
    justify-content: flex-end !important;
}
.hAuto {
    height: auto !important;
}
.hFullscreen {
    height: 100vh !important;
}
.wFullscreen {
    width: 100% !important;
}
.minHeightSmallTable {
    min-height: 400px !important;
}
.minHeightProduct {
    min-height: 700px !important;
}
.minHeightCustomer {
    min-height: 600px !important;
}
.minHeightDelivery {
    min-height: 590px !important;
}
.minHeight500px {
    min-height: 500px !important;
}
.minHeight530px {
    min-height: 530px !important;
}
.minHeight600px {
    min-height: 600px !important;
}
.minHeight650px {
    min-height: 650px !important;
}
.minHeight680px {
    min-height: 680px !important;
}
.h17px {
    height: 17px !important;
}
.h20px {
    height: 20px !important;
}
.h100px {
    height: 100px !important;
}
.h244px {
    height: 244px !important;
}
.h300px {
    height: 300px !important;
}
.lh1 {
    line-height: 1 !important;
}
.w35 {
    width: 35% !important;
}
.w45 {
    width: 45% !important;
}
.w100 {
    width: 100% !important;
}
.w18px {
    width: 18px !important;
}
.w30px {
    width: 30px !important;
}
.w190px {
    width: 190px !important;
}
.w200px {
    width: 200px !important;
}
.w230px {
    width: 230px !important;
}
.noMargin {
    margin: 0 !important;
}
.noPadding {
    padding: 0 !important;
}
.m0 {
    margin: 0 !important;
}
.mb0 {
    margin-bottom: 0 !important;
}
.mb3 {
    margin-bottom: 3px !important;
}
.mb5 {
    margin-bottom: 5px !important;
}
.mb10 {
    margin-bottom: 10px !important;
}
.mb15 {
    margin-bottom: 15px !important;
}
.mb20 {
    margin-bottom: 20px !important;
}
.mb30 {
    margin-bottom: 30px !important;
}
.mb40 {
    margin-bottom: 40px !important;
}
.mb50 {
    margin-bottom: 50px !important;
}
.mb60 {
    margin-bottom: 60px !important;
}
.margin80 .mb60 {
    margin-bottom: 80px !important;
}
.mtminus40 {
    margin-top: -40px !important;
}
.mtminus25 {
    margin-top: -25px !important;
}
.mtminus27 {
    margin-top: -27px !important;
}
.mt0 {
    margin-top: 0 !important;
}
.mt5 {
    margin-top: 5px !important;
}
.mt8 {
    margin-top: 8px !important;
}
.mt10 {
    margin-top: 10px !important;
}
.mt15 {
    margin-top: 15px !important;
}
.mt20 {
    margin-top: 20px !important;
}
.mt30 {
    margin-top: 30px !important;
}
.mt40 {
    margin-top: 40px !important;
    margin-bottom: 10px !important;
}
.ml260 {
    margin-left: 260px;
}
.ml0 {
    margin-left: 0 !important;
}
.ml5 {
    margin-left: 5px !important;
}
.ml10 {
    margin-left: 10px !important;
}
.ml15 {
    margin-left: 15px !important;
}
.ml18 {
    margin-left: 18px !important;
}
.ml20 {
    margin-left: 20px !important;
}
.ml22 {
    margin-left: 22px !important;
}
.mr1 {
    margin-right: .25rem !important;
}
.ml125 {
    margin-left: 1.25rem !important;
}
.mr125 {
    margin-right: 1.25rem !important;
}
.mlHalfRem {
    margin-left: .5rem !important;
}
.mb1rem {
    margin-bottom: 1rem !important;
}
.mr1rem {
    margin-right: 1rem !important;
}
.mr5 {
    margin-right: 5px !important;
}
.mr8 {
    margin-right: 8px !important;
}
.mr10 {
    margin-right: 10px !important;
}
.mr20 {
    margin-right: 20px !important;
}
.mr25 {
    margin-right: 25px !important;
}
.mt1rem {
    margin-top: 1rem !important;
}
.mr1rem {
    margin-right: 1rem !important;
}
.lh3 {
    line-height: 3 !important;
}
.pLeft0 {
    padding-left: 0 !important;
}
.pRight0 {
    padding-right: 0 !important;
}
.p125rem {
    padding: 1.25rem !important;
}
.p0 {
    padding: 0 !important;
}
.p2 {
    padding: 2px !important;
}
.p5 {
    padding: 5px !important;
}
.p7 {
    padding: 7px !important;
}
.p8 {
    padding: 8px !important;
}
.p9 {
    padding: 9px !important;
}
.p10 {
    padding: 10px !important;
}
.p12 {
    padding: 12px !important;
}
.p25 {
    padding: 25px !important;
}
.p30 {
    padding: 30px !important;
}
.pr0 {
    padding-right: 0 !important;
}
.pr10 {
    padding-right: 10 !important;
}
.pr15 {
    padding-right: 15 !important;
}
.pl0 {
    padding-left:  0 !important;
}
.pl20 {
    padding-left: 20px !important;
}
.pl35 {
    padding-left: 35px !important;
}
.pb0 {
    padding-bottom: 0 !important;
}
.pb2 {
    padding-bottom: 2px !important;
}
.pb15 {
    padding-bottom: 15px !important;
}
.pb20 {
    padding-bottom: 20px !important;
}
.pb30 {
    padding-bottom: 30px !important;
}
.pt10p {
    padding-top: 10%;
}
.pt5 {
    padding-top: 5px !important;
}
.pt6 {
    padding-top: 6px !important;
}
.pt8 {
    padding-top: 8px;
}
.pt15 {
    padding-top: 15px !important;
}
.pt20 {
    padding-top: 20px !important;
}
.pt50 {
    padding-top: 50px !important;
}
.pt56 {
    padding-top: 56px !important;
}
.pt100 {
    padding-top: 100px !important;
}
.pt125 {
    padding-top: 125px !important;
}
.pt280 {
    padding-top: 280px !important;
}
.pb5 {
    padding-bottom: 5px !important;
}
.pb50 {
    padding-bottom: 50px;
}
.pb100 {
    padding-bottom: 100px !important;
}
.ptb100 {
    padding-top: 100px;
    padding-bottom: 100px;
}
.top70 {
    top: 70% !important;
}
.br50 {
    border-radius: 50% !important;
}
.col-md-1 {
    width: 8.3333333%;
}
.col-md-2 {
    width: 16.666666667%;
}
.col-md-3 {
    width: 25%;
}
.col-md-4 {
    width: 33.3333333%;
}
.col-md-5 {
    width: 41.6666667%;
}
.col-md-6 {
    width: 50%;
}
.col-md-7 {
    width: 58.3333333%;
}
.col-md-8 {
    width: 66.6666666%;
}
.col-md-9 {
    width: 75%;
}
.col-md-10 {
    width: 83.3333333%;
}
.col-md-12 {
    width: 100%;
}
.col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
}
.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, 
.col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, 
.col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, 
.col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12,
.col-agmd-4, .col-agmd-6, .col-agmd-12  {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
.col-agmd-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 0 0 33.333333%;
}
.col-agmd-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}
.col-agmd-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
}
.container {
    width:1140px;
    margin: 0 auto;
}

@media (max-width: 767.98px) {
    .hiddenXs {
        display: none !important;
    }
    .container {
        width: 320px;
    }
    .col-xs-1 {
        width: 8.3333333%;
    }
    .col-xs-2 {
        width: 16.666666667%;
    }
    .col-xs-3 {
        width: 25%;
    }
    .col-xs-4 {
        width: 33.3333333%;
    }
    .col-xs-5 {
        width: 41.6666667%;
    }
    .col-xs-6 {
        width: 50%;
    }
    .col-xs-7 {
        width: 58.3333333%;
    }
    .col-xs-8 {
        width: 66.6666666%;
    }
    .col-xs-9 {
        width: 75%;
    }
    .col-xs-10 {
        width: 83.3333333%;
    }
    .col-xs-12 {
        width: 100%;
    }
}

@media (min-width: 768px) and (max-width: 835px) {
    .container {
        max-width: 750px;
    }
    .col-sm-1 {
        width: 8.3333333%;
    }
    .col-sm-2 {
        width: 16.666666667%;
    }
    .col-sm-3 {
        width: 25%;
    }
    .col-sm-4 {
        width: 33.3333333%;
    }
    .col-sm-5 {
        width: 41.6666667%;
    }
    .col-sm-6 {
        width: 50%;
    }
    .col-sm-7 {
        width: 58.3333333%;
    }
    .col-sm-8 {
        width: 66.6666666%;
    }
    .col-sm-9 {
        width: 75%;
    }
    .col-sm-10 {
        width: 83.3333333%;
    }
    .col-sm-12 {
        width: 100%;
    }
}

@media (min-width: 836px) and (max-width: 1025px){
    .container {
        max-width: 1000px;
    }
    .col-lg-1 {
        width: 8.3333333%;
    }
    .col-lg-2 {
        width: 16.666666667%;
    }
    .col-lg-3 {
        width: 25%;
    }
    .col-lg-4 {
        width: 33.3333333%;
    }
    .col-lg-5 {
        width: 41.6666667%;
    }
    .col-lg-6 {
        width: 50%;
    }
    .col-lg-7 {
        width: 58.3333333%;
    }
    .col-lg-8 {
        width: 66.6666666%;
    }
    .col-lg-9 {
        width: 75%;
    }
    .col-lg-10 {
        width: 83.3333333%;
    }
    .col-lg-12 {
        width: 100%;
    }
}