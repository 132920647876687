@import 'colorfonts';

/***********************************************
                    TABLE
***********************************************/
.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
    border-collapse: collapse;
}
th {
    text-align: inherit;
}
.table th {
    font-weight: 400;
}
.table td, .table th {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}
.table thead th {
    border-bottom: 1px solid #ebebeb;
    vertical-align: bottom;
    border-top: 0;
    font-weight: 400;
}
.table tbody th {
    border-top: 1px solid #eceeef;
}
.table tbody tr:hover {
    background-color: #eaeaea;
    cursor: pointer;
}
.pageNumber {
    margin-left: 10px;
}
.doubleButton {
    display: inline-block;
}
/***********************************************
                PAGINATION
***********************************************/
.pagination {
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: .25rem;
    // position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin-bottom: 20px;
}
.pageItem.disabled .pageLink, .pageItem.disabled .pageLink:focus, .pageItem.disabled .pageLink:hover {
    opacity: 0.6;
}
.pageItem.active .pageLink, .pageItem.active .pageLink:focus, .pageItem.active .pageLink:hover {
    background-color: #33cabb;
    border-color: #33cabb;
    font-weight: 500;
}
.pageItem.active .pageLink {
    z-index: 1;
    color: #fff;
}
.pageItem.disabled .pageLink {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-collapse: #dee2e6;
}
.pageItem:first-child .pageLink {
    margin-left: 0;
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
}
.pageItem:last-child .pageLink {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
}
.pageLink:not(:disabled):not(.disabled) {
    cursor: pointer;
}
.pageLink {
    color: #8b95a5;
    font-weight: 400;
    border: 1px solid #dee2e6;
    padding: 0 8px;
    margin: 0 3px;
    min-width: 31px;
    line-height: 29px;
    text-align: center;
    border-radius: 2px !important;
    position: relative;
    display: block;
}
// table#customerTable td:nth-of-type(1) {table-layout:fixed;width:45px;word-break:break-all;}/*Setting the width of column 1.*/
// table#customerTable td:nth-of-type(2) {table-layout:fixed;width:190px;word-break:break-all;}/*Setting the width of column 2.*/
// table#customerTable td:nth-of-type(3) {table-layout:fixed;width:300px;word-break:break-all;}/*Setting the width of column 3.*/
// table#customerTable td:nth-of-type(4) {table-layout:fixed;width:310px;word-break:break-all;}/*Setting the width of column 4.*/
// table#customerTable td:nth-of-type(5) {table-layout:fixed;width:260px;word-break:break-all;}/*Setting the width of column 5.*/
// table#customerTable td:nth-of-type(6) {table-layout:fixed;width:135px;word-break:break-all;}/*Setting the width of column 6.*/

.inputbutton {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 7px 13px;
    width: 100%;
    height: 42px;
    font-size: 15px;
    line-height: 1.1;
    color: rgba(0,0,0,.65);
    background-color: $colorWhite;
    background-image: none;
    border: 1px solid #cccccc !important;
    border-radius: 4px;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    cursor: pointer;
}

.inputbutton:hover {
    border-color: #aeacb4 !important;
}

.inputbutton:focus {
    box-shadow: none !important;
    border-color: #bbbac0 !important;
    background-color: #F8F8F8 !important;
}

.invoiceForm li:hover {
    background-color: #eaeaea;
    cursor: pointer;
}

.invoiceForm .menuItem .menuLink {
    border-top: 1px solid #dee2e6 !important;
}

.invoiceForm .menuItem .menuLink.noBorder {
    border-top: 0px !important;
}

.chooseCustomerButton {
    bottom: 15px;
    left: 1.25rem;
}

.chooseProductButton {
    bottom: 25px;
}

.chooseProductButton {
    bottom: 25px;
}

.confirmInvoiceButton {
    bottom: 17px;
}

.editInvoice {
    bottom: 15px;
}

.react-datepicker-popper {
    z-index: 5 !important;
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    width: 1.4rem !important;
    line-height: 1.4rem !important;
}

.address {
    margin: 5px auto;
}