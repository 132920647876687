@import 'colorfonts';

.cardImageLoading { 
    width: 170px;
    height: 172px;
}

.cardPaddingLoading {
    width: 170px;
    height: 109px;
}
.addToCartLoading {
    width: 170px;
    height: 30px;
}
.cardPaddingLoading .nameLoadingWrapper {
    width: 130px;
    height: 50px;
}
.cardPaddingLoading .nameLoading {
    width: 130px;
    height: 15px;
    margin-left: 5px;
}
// .cardPaddingLoading .descriptionLoading {

// }
.cardPaddingLoading .brandLoading {
    width: 130px;
    height: 15px;
    margin: 5px;
}
.cardPaddingLoading .priceLoading {
    width: 130px;
    height: 15px;
    margin: 5px;
}
.title1Loading {
    width: 72px;
    height: 36px;
    float: left;
}
.title2Loading {
    width: 106px;
    height: 36px;
    margin-left: 5px;
    float: left;
}
.loading {
    position: relative;
    background-color: $loadingColor;
}
.loading::after {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateX(-30%);
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.4), transparent);
    animation: loading 1.5s infinite ease-in;
}
@keyframes loading {
    // 0% {
    //     transform: translateX(-100%);
    //   }
    100% {
      transform: translateX(50%);
    }
    // 0% {
    //     background-color: rgba(255, 255, 255, 0.1);
    // }
    // 50% {
    //     background-color: rgba(255, 255, 255, 0.3);
    // }
    // 100% {
    //     background-color: rgba(255, 255, 255, 0.1);
    // }
  }

  @media (max-width: 767.98px) {
    .cardImageLoading { 
        width: 160px;
        height: 162px;
    }
    .cardPaddingLoading .nameLoading {
        width: 120px;
        height: 13px;
        margin-left: 5px;
    }
    // .cardPaddingLoading .descriptionLoading {
    
    // }
    .cardPaddingLoading .brandLoading {
        width: 130px;
        height: 13px;
        margin: 5px;
    }
    .cardPaddingLoading .priceLoading {
        width: 130px;
        height: 13px;
        margin: 5px;
    }
    .addToCartLoading {
        width: 160px;
        height: 30px;
    }
  }