@import 'colorfonts';

/***********************************************
                NOTIFICATIONS
***********************************************/

.agranotif {
    color: #FFF;
    cursor: default;
    display: block;
    font-size: 1.3em;
    left: 0;
    opacity: 1;
    padding: 25px 80px;
    position: fixed;
    right: 0;
    text-align: center;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 9999;
}

.agrabottom {
    bottom: 0;
}

.agratop {
    top: 0;
}

.agradismiss {
    background-color: #333;
    border-radius: 15px;
    box-shadow: inset 2px 2px 7px 2px #000;
    color: #DDD;
    cursor: pointer;
    display: none;
    font-size: 1.25em;
    font-weight: 700;
    height: 30px;
    line-height: 26px;
    opacity: .2;
    position: absolute;
    right: 45px;
    text-shadow: 1px 1px 5px #000;
    width: 30px;
    bottom: 20px;
}

.agrasticky .agradismiss {
    display: block;
}

.agrablue {
    background-color: #0e90d2;
}
.notificationWrapper {
    padding: 15px;
    display: block;
    // margin: 50px 0;
    // border-bottom: 1px solid #e5e5e5;
    // border-top: 1px solid #e5e5e5;
}
.notificationWrapper .itemIcon svg {
    font-size: 20px;
    color: #0190fe;
    margin-right: 15px;
}
.toast {
    max-width: 100%;
    overflow: hidden;
    font-size: .875rem;
    background-color: rgba(255,255,255,.85);
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.1);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    opacity: 0;
    border-radius: .25rem;
}
.toast.show {
    display: block;
    opacity: 1;
}
.toast:not(:last-child) {
    margin-bottom: .75rem;
}
.fade {
    transition: opacity .15s linear;
}
.toastHeader {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: 1rem 1.75rem;
    color: #6c757d;
    background-color: rgba(255,255,255,.85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0,0,0,.05);
}
.toastBody {
    padding: 1rem 1.75rem;
}

// .notificationWrapper ul {
//     height: 100%;
//     list-style: none;
//     display: flex;
//     flex-direction: column;
// }

// .notificationWrapper a, .notificationWrapper .icon {
//     color: $navigationItemsAColor;
//     text-decoration: none;
//     font-size: 1.2rem;
// }
// .notificationWrapper a:hover,
// .notificationWrapper a:active {
//     color: $navigationItemsAColorActive;
// }

// .notificationWrapper > ul > li > a {
//     border: none;
//     display: block;
//     position: relative;
//     padding: 5px;
//     border-radius: 0;
//     margin: 0;
//     text-align: -webkit-match-parent;
// }
// .notificationWrapper > ul > li a.active,
// .notificationWrapper > ul > li a.active .icon{
//     color: $sideDrawerAHover;
// }
// .notificationWrapper .itemContent {
//     align-items: center;
//     display: table-row;
// }
// .notificationWrapper .itemIcon {
//     padding: 10px 15px;
//     width: 55px;
// }
// .notificationWrapper .itemIcon svg {
//     font-size: 30px;
//     color: #0190fe;
// }
// .notificationWrapper .itemInner {
//     display: table-cell;
//     width: 100%;
//     vertical-align: middle;
//     white-space: nowrap;
//     align-items: center;
//     padding: 10px 15px 10px 10px;
// }
// .notificationWrapper .iconContent {
//     background: $sideDrawerBg;
//     box-shadow: none;
//     height: 53px;
//     border-bottom: 1px solid rgba(255, 255, 255, 0.2);
// }
// .notificationWrapper .iconContent > ul {
//     flex-direction: row;
//     position: relative;
//     justify-content: space-between;
//     // float: right;
// }
// .notificationWrapper .iconContent > ul > li {
//     float: left;
//     position: relative;
//     display: block;
// }
// .notificationWrapper .iconContent > li:before {
//     content: "";
//     position: absolute;
//     width: 1px;
//     right: 0;
//     top: 5px;
//     bottom: 5px;
//     background: -moz-linear-gradient(top, rgba(206,206,206,0) 0%, rgba(206,206,206,0.2) 50%, rgba(206,206,206,0) 100%);
//     background: -webkit-linear-gradient(top, rgba(206,206,206,0) 0%, rgba(206,206,206,0.2) 50%, rgba(206,206,206,0) 100%);
//     background: linear-gradient(to bottom, rgba(206,206,206,0) 0%, rgba(206,206,206,0.2) 50%, rgba(206,206,206,0) 100%);
//     box-sizing: border-box;
// }
// .notificationWrapper .iconContent > ul > li > a {
//     letter-spacing: 0;
//     opacity: 0.9;
//     padding: 7px;
//     position: relative;
//     text-align: center;
//     display: block;
    
// }
// .notificationWrapper .iconContent > ul > li > a.active {
//     background-color: $sideDrawerBGColor;
//     border-bottom: 4px solid $sideDrawerRightBorder;
// }
// .notificationWrapper .iconContent > ul > li > a.active > .iconWrapper > .icon {
//     color: $colorWhite;
// }
// .notificationWrapper .iconContent .iconWrapper {
//     padding: 9px;
// }