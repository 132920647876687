@import 'colorfonts';

/***********************************************
                    ORDER
***********************************************/
.orderAction {
    position: relative;
    padding: 10px 40px;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    flex-wrap: wrap;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}
.orderAction .nav {
    white-space: nowrap;
    flex-wrap: nowrap;
    margin-bottom: 0;
    border-bottom: none;
}
.orderAction .orderLink {
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;

    border: 1px solid transparent;
    color: rgba(77,82,89,0.7);
    background-color: transparent !important;
}
.orderAction .orderLink.active, .orderAction .orderLink:hover {
    color: #4d5259;
    border-color: #33cabb;
    border-radius: 50px;
}
/***********************************************
                    PROFILE
***********************************************/
.headerAction {
    position: relative;
    padding: 0 30px;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    flex-wrap: wrap;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}
.nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}
.navLink, .orderLink {
    font-weight: 400;
    color: #8b95a5;
    line-height: 33px;
    padding: 0px 12px;
    white-space: nowrap;
}

.headerAction .nav {
    white-space: nowrap;
    flex-wrap: nowrap;
    margin-bottom: 0;
    border-bottom: none;
}
.headerAction .navLink {
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 0.6rem;
    border-bottom: 3px solid transparent;
    color: rgba(77,82,89,0.7);
    background-color: transparent !important;
}
.headerAction .navLink.active, .headerAction .navLink:hover {
    color: #4d5259;
    border-bottom-color: #33cabb;
}
// .tabContent .tabPanel {
//     display: block;
//     height: 0;
//     max-width: 100%;
//     opacity: 0;
//     overflow: hidden;
//     visibility: hidden;
// }
// .tabContent .tabPanel.active {
//     height: auto;
//     opacity: 1;
//     overflow: visible;
//     visibility: visible;
// }
// .tabContent > .active {
//     display: block;
// }
// .tabContent > .tabPanel {
//     display: none;
// }
.tabWrapper {
    margin-bottom: 2.3rem;
    border-radius: 7px;
    border: none;
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: $colorWhite;
    background-clip: border-box;
    // border: 1px solid rgba(0,0,0,.125);
    border: 1px solid #e5e5e5;
    border-radius: .25rem;
    // box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    // box-shadow: 1px 0 5px rgba(0,0,0,.2);
}
.h4.tabTitle, h4.tabTitle {
    font-size: 17px;
}
.titleWrapper {
    display: flex;
    border-bottom: 1px solid rgba(77,82,89,0.07);
}
.tabTitle {
    font-family: $workSans;
    font-weight: 300;
    line-height: 1.5;
    margin-bottom: 0;
    padding: 15px 30px;
}
.tabButton {
    position: absolute;
    top: 10px;
    right: 10px;
}
// .myaccountTable {
//     white-space: nowrap;
// }

.minHeightProfileHistory {
    min-height: 380px !important;
}
.address {
    margin-bottom: 1rem;
    text-align: start;
    min-height: 20px;
    padding: 10px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
}