html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.loader {
  position: absolute;
  top: 50%;
  left: 45%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -mos-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  /* disable selection and cursor changes */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}

.circle {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
}

.circle {
  width: 150px;
  height: 150px;
  margin-top: -75px;
  margin-left: -50px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background: #F2F2EF;
  z-index: 1;
  font-family: "Uni Sans";
  font-size: 6em;
  font-weight: 300;
  color: #303030;
  line-height: 1.35em;
  -webkit-animation: circle 1.5s infinite ease-in-out;
  -moz-animation: circle 1.5s infinite ease-in-out;
  -mos-animation: circle 1.5s infinite ease-in-out;
  -o-animation: circle 1.5s infinite ease-in-out;
  animation: circle 1.5s infinite ease-in-out;
}

.scircle {
  position: absolute;
  top: -25px;
  left: -23px;
  width: 200px;
  height: 200px;
  -webkit-animation: scircle 1.5s ease-in-out infinite;
  -moz-animation: scircle 1.5s ease-in-out infinite;
  -mos-animation: scircle 1.5s ease-in-out infinite;
  -o-animation: scircle 1.5s ease-in-out infinite;
  -webkit-animation: scircle 1.5s ease-in-out infinite;
}

.scircle {
  -webkit-animation: scircle2 1.5s ease-in-out infinite;
  -moz-animation: scircle2 1.5s ease-in-out infinite;
  -mos-animation: scircle2 1.5s ease-in-out infinite;
  -o-animation: scircle2 1.5s ease-in-out infinite;
  -webkit-animation: scircle2 1.5s ease-in-out infinite;
}

.scircle:before {
  content: "";
  position: absolute;
  width: 0.1em;
  height: 0.1em;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
  background: #ffffff;
}

.scircle:after {
  content: "";
  position: absolute;
  top: 190px;
  width: 0.1em;
  height: 0.1em;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
  background: #ffffff;
}

@-webkit-keyframes circle {
  0% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=50);
    -moz-opacity: 0.5;
    -khtml-opacity: 0.5;
    opacity: 0.5;
  }
  45%, 50%, 55% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
  }
  100% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=50);
    -moz-opacity: 0.5;
    -khtml-opacity: 0.5;
    opacity: 0.5;
  }
}
@-moz-keyframes circle {
  0% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=50);
    -moz-opacity: 0.5;
    -khtml-opacity: 0.5;
    opacity: 0.5;
  }
  45%, 50%, 55% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
  }
  100% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=50);
    -moz-opacity: 0.5;
    -khtml-opacity: 0.5;
    opacity: 0.5;
  }
}
@-mos-keyframes circle {
  0% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=50);
    -moz-opacity: 0.5;
    -khtml-opacity: 0.5;
    opacity: 0.5;
  }
  45%, 50%, 55% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
  }
  100% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=50);
    -moz-opacity: 0.5;
    -khtml-opacity: 0.5;
    opacity: 0.5;
  }
}
@-o-keyframes circle {
  0% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=50);
    -moz-opacity: 0.5;
    -khtml-opacity: 0.5;
    opacity: 0.5;
  }
  45%, 50%, 55% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
  }
  100% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=50);
    -moz-opacity: 0.5;
    -khtml-opacity: 0.5;
    opacity: 0.5;
  }
}
@keyframes circle {
  0% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=50);
    -moz-opacity: 0.5;
    -khtml-opacity: 0.5;
    opacity: 0.5;
  }
  45%, 50%, 55% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
  }
  100% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=50);
    -moz-opacity: 0.5;
    -khtml-opacity: 0.5;
    opacity: 0.5;
  }
}
@-webkit-keyframes scircle {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes scircle {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-mos-keyframes scircle {
  0% {
    -mos-transform: rotate(0deg);
  }
  100% {
    -mos-transform: rotate(360deg);
  }
}
@-o-keyframes scircle {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(360deg);
  }
}
@keyframes scircle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes scircle2 {
  0% {
    -webkit-transform: rotate(90deg);
  }
  100% {
    -webkit-transform: rotate(450deg);
  }
}
@-moz-keyframes scircle2 {
  0% {
    -moz-transform: rotate(90deg);
  }
  100% {
    -moz-transform: rotate(450deg);
  }
}
@-mos-keyframes scircle2 {
  0% {
    -mos-transform: rotate(90deg);
  }
  100% {
    -mos-transform: rotate(450deg);
  }
}
@-o-keyframes scircle2 {
  0% {
    -o-transform: rotate(90deg);
  }
  100% {
    -o-transform: rotate(450deg);
  }
}
@keyframes scircle2 {
  0% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(450deg);
  }
}
.hometitle {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1000px;
  height: 160px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.hometitlemobile {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  padding: 0px 10px;
  height: 160px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.hometitle .big_white {
  color: #fff;
  font-size: 45px;
  margin: 10px 15px;
  text-transform: uppercase;
  line-height: 70px;
  font-weight: 700;
}

.hometitlemobile .big_whitemobile {
  color: #fff;
  font-size: 25px;
  margin: 10px 15px;
  text-transform: uppercase;
  line-height: 30px;
  font-weight: 700;
}

.hometitlemobile .hero-copy {
  color: #fff;
  margin: 0 15px;
  font-size: 18px;
  line-height: 22px;
  font-weight: 300;
}

.hometitle .hero-copy {
  color: #fff;
  margin: 0 15px;
  font-size: 22px;
  line-height: 32px;
  font-weight: 300;
}

.hometitle .btn-holder {
  display: block;
  margin: 30px 15px;
}

.big_white span {
  color: #f64747;
}

.big_whitemobile span {
  color: #f64747;
  display: block;
}

.lm-button {
  border: 1px solid #f64747;
  color: #f64747;
}

.lm-button {
  font-family: "Montserrat", "Open Sans", sans-serif;
  font-size: 13px;
  letter-spacing: 1.5px;
  color: #fff;
  display: inline-block;
  padding: 10px 36px;
  border: 1px solid #888;
  border-radius: 5px;
  text-transform: uppercase;
  border: 1px solid #f64747;
  color: #f64747;
}

.slidercontainer {
  width: 100%;
  margin: 0 auto;
}

.carouselimage {
  background-size: cover !important;
}

/***********************************************
                  ABOUT US
***********************************************/
#aboutus.menuin {
  padding-top: 80px;
  display: block;
}

h2.sectiontitleku {
  color: #aaa;
  font-size: 41px;
  line-height: 70px;
  font-weight: 700;
  text-align: center;
  padding-bottom: 10px;
}

.sectiontitleku::after {
  background: #f64747;
  position: relative;
  display: block;
  content: "";
  height: 2px;
  width: 30px;
  margin: 10px auto 20px;
}

.sectiontagline {
  font: 18px/29px "Open Sans", Arial, sans-serif;
  font-weight: normal;
  font-weight: 300;
  text-align: center;
  max-width: 550px;
  margin: auto auto 80px auto;
  color: #aaa;
}

.abouticons {
  border-top: 1px solid #111;
  border-bottom: 1px solid #111;
  text-align: center;
}

.abouticons .column {
  overflow: hidden;
  position: relative;
  padding: 120px 50px;
  min-height: 380px;
}

.abouticons .column:hover {
  background-color: rgba(25, 25, 25, 0.95);
}

.abouticons .column.border {
  border-right: 1px solid #111;
}

.abouticons .column.bordermobile {
  border-bottom: 1px solid #111;
}

.column {
  float: left;
  margin: 0;
  padding-left: 15px;
  padding-right: 15px;
}

.column.four {
  width: 33.33333%;
}

.column.six {
  width: 50%;
}

.abouticons h2 {
  color: #aaa;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  font-weight: 500;
  margin: 20px 0;
}

.abouticons .main {
  -webkit-transition: all 300ms cubic-bezier(0.07, 0.58, 0.39, 1);
  -moz-transition: all 300ms cubic-bezier(0.07, 0.58, 0.39, 1);
  -o-transition: all 300ms cubic-bezier(0.07, 0.58, 0.39, 1);
  -ms-transition: all 300ms cubic-bezier(0.07, 0.58, 0.39, 1);
  transition: all 300ms cubic-bezier(0.07, 0.58, 0.39, 1);
}

.abouticons span.desc {
  opacity: 0;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  padding: 0 50px;
  -webkit-transform: perspective(500px) rotateX(-90deg) rotateY(0deg) rotateZ(0deg);
  -moz-transform: perspective(500px) rotateX(-90deg) rotateY(0deg) rotateZ(0deg);
  -o-transform: perspective(500px) rotateX(-90deg) rotateY(0deg) rotateZ(0deg);
  -ms-transform: perspective(500px) rotateX(-90deg) rotateY(0deg) rotateZ(0deg);
  transform: perspective(500px) rotateX(-90deg) rotateY(0deg) rotateZ(0deg);
  -webkit-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
  -moz-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
  -o-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
  -ms-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
  transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);
  font: 16px/20px "Open Sans", Arial, sans-serif;
  font-weight: normal;
  font-weight: 300;
  text-align: center;
  color: #aaa;
}

.abouticons span.description {
  opacity: 1;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  padding: 0 50px;
  font: 16px/20px "Open Sans", Arial, sans-serif;
  font-weight: normal;
  font-weight: 300;
  text-align: center;
  color: #aaa;
}

.abouticons .main svg {
  backface-visibility: hidden;
}

.abouticons svg {
  font-size: 64px;
  color: #f64747;
}

.abouticons .main svg, #abouticons .main h2 {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

#contact {
  text-align: center;
}

#contact .title {
  font-size: 30px;
  line-height: 34px;
  color: #fff;
  font-weight: 300;
  text-align: center;
}

#contact .column svg {
  font-size: 40px;
  color: #f64747;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

#contact span {
  display: block;
  color: #fff;
  margin: 5px 0 30px 0;
}

#contact .column a, #contact .column span {
  color: #808080;
  margin: 15px 0 0 0;
  display: block;
  font: 15px/26px "Open Sans", sans-serif;
  text-decoration: none;
  -webkit-transform: translateZ(0);
  -webkit-transition: 0.3s all linear;
  -moz-transition: 0.3s all linear;
  transition: 0.3s all linear;
}

.abouticons .column:hover .service-intro {
  opacity: 0;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -o-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}

.abouticons .column:hover .desc {
  opacity: 1;
  -webkit-transform: perspective(500px) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  -moz-transform: perspective(500px) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  -o-transform: perspective(500px) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  -ms-transform: perspective(500px) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  transform: perspective(500px) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
}

.abouticons .column:hover .main {
  margin-top: -75px;
}

.abouticons .column .mainmobile {
  margin-top: -75px;
}

.htmlparallax {
  padding: 20;
  position: "absolute";
  top: "50%";
  left: "50%";
  transform: "translate(-50%,-50%)";
}

.quote {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: 170px 0;
}

.parallaxquote {
  color: #fff;
  font-weight: 300;
  font-family: "Open Sans", Arial, sans-serif;
  text-align: center;
  font-size: 34px;
  line-height: 48px;
}

.quoteauthor {
  margin-top: 20px;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  display: block;
  font-size: 14px;
  font-weight: 700;
}

.locationtag {
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.locationtag div {
  background: #2c2c2c;
  color: #ffffff;
  text-transform: uppercase;
  width: 220px;
  margin: 0 auto;
  font-size: 29px;
  padding: 15px 20px;
  text-align: center;
}

footer {
  background-color: #222222;
  text-align: center;
  padding: 60px 0;
}

footer.light {
  background-color: #f3f3f3;
}

footer .block {
  color: #808080;
  display: block;
}

footer .block a {
  color: #808080;
}

footer .block a:hover {
  color: #d3145a;
}

footer ul {
  margin: 0;
}

footer img {
  margin-bottom: 15px;
}

.gray {
  background-color: rgba(25, 25, 25, 0.95);
}

.frontendtable ul {
  margin-bottom: 20px;
}

/***********************************************
                PRICING TABLE
***********************************************/
#pricing-tables {
  padding-bottom: 90px;
}

.frontendtable {
  border: 1px solid rgba(246, 58, 42, 0.5);
  padding: 80px 35px;
  text-align: center;
  -o-transition: 0.4s;
  -ms-transition: 0.4s;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  max-width: 350px;
  margin: auto;
}

.frontendtable.popular, .frontendtable:hover {
  border: 1px solid #f64747;
  background-color: rgba(160, 160, 160, 0.1);
}

.frontendtable ul li {
  font: 17px/20px "Open Sans", Arial, sans-serif;
  font-weight: normal;
  font-weight: 300;
  text-align: center;
  color: #aaa;
}

.frontendtable ul li {
  list-style: none;
  text-align: center;
}

.tabletitle {
  font: 17px/20px "Open Sans", Arial, sans-serif;
  color: #aaa;
  display: block;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin-bottom: 20px;
}

.frontendtable .lm-button {
  margin: 35px 0 0 0;
  text-decoration: none !important;
  border: 1px solid #d3145a;
  color: #d3145a;
}

.frontendtable .lm-button:hover {
  border: 1px solid #d3145a;
  background-color: #d3145a;
  color: #fff;
}

.frontendtable .lm-button.fill {
  background-color: #d3145a;
  color: #fff;
}

.frontendtable .lm-button.fill:hover {
  background-color: transparent;
  color: #d3145a;
}

.toggleButton.white svg {
  color: #ffffff;
}

.sideDrawerLogo {
  padding: 14px !important;
  background-color: rgba(25, 25, 25, 0.95);
}

.sideDrawer button, .sideDrawer .icon {
  color: #aaa;
  text-decoration: none;
  font-size: 1.2rem;
  background-color: transparent;
  cursor: pointer;
}

.sideDrawer > ul > li > button span:hover,
.sideDrawer > ul > li > button span:active {
  color: #fff;
}

.sideDrawer > ul > li > button {
  border: none;
  display: block;
  position: relative;
  padding: 5px;
  border-radius: 0;
  margin: 0;
  width: 100%;
  text-align: left;
  border-bottom: 1px solid rgba(25, 25, 25, 0.95);
}

.sideDrawer > ul > li > button.active {
  background-color: #fff;
  border-right: 4px solid #d3145a;
}

.sideDrawer > ul > li button.active,
.sideDrawer > ul > li button.active .icon {
  color: #fff;
}

.sideDrawer .iconContent > ul > li > button {
  letter-spacing: 0;
  opacity: 0.9;
  padding: 7px;
  position: relative;
  text-align: center;
  display: block;
}

.sideDrawer .iconContent > ul > li > button.active {
  background-color: #222222;
  border-bottom: 4px solid #222222;
}

.sideDrawer .iconContent > ul > li > button.active > .iconWrapper > .icon {
  color: #fff;
}

@media (min-width: 850px) {
  .toggleButton {
    display: none;
  }
}
@media (max-width: 767.98px) {
  #aboutus.menuin {
    padding-top: 60px;
  }
  .sectiontagline {
    padding: 0 10px;
  }
  .marginbottom20 {
    margin-bottom: 30px;
  }
  .abouticons h2 {
    margin: 0;
  }
  .abouticons span.description {
    top: 40%;
  }
  .slidercontainer {
    height: 350px !important;
    overflow: hidden;
  }
}
@media (min-width: 767.99px) and (max-width: 835.98px) {
  .toggleButton {
    display: none;
  }
  .hometitle {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 600px;
    height: 160px;
    transform: translate(-50%, -50%);
  }
  .hometitle .big_white {
    color: #fff;
    font-size: 45px;
    margin: 10px 15px;
    text-transform: uppercase;
    line-height: 50px;
    font-weight: 700;
  }
  #pricing-tables .table {
    border: 1px solidrgba(246, 58, 42, 0.5);
    padding: 40px 15px;
    text-align: center;
    transition: 0.4s;
    max-width: 350px;
    margin: auto;
    margin-bottom: auto;
    height: 100%;
  }
  .abouticons .column {
    overflow: hidden;
    position: relative;
    padding: 100px 50px;
    min-height: 380px;
  }
  .abouticons span.description {
    opacity: 1;
    display: block;
    position: absolute;
    top: 40%;
    left: 0;
    width: 100%;
    padding: 0 20px;
    font: 16px/20px "Open Sans", Arial, sans-serif;
    font-weight: normal;
    font-weight: normal;
    font-weight: 300;
    text-align: center;
    color: #aaa;
  }
  .abouticons h2 {
    margin: 0;
  }
  .slidercontainer {
    height: 435px !important;
    overflow: hidden;
  }
}
@media (min-width: 835.99px) and (max-width: 1025.98px) {
  .slidercontainer {
    height: 500px !important;
    overflow: hidden;
  }
  .hometitle {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 600px;
    height: 160px;
    transform: translate(-50%, -50%);
  }
  .hometitle .big_white {
    color: #fff;
    font-size: 45px;
    margin: 10px 15px;
    text-transform: uppercase;
    line-height: 50px;
    font-weight: 700;
  }
  .abouticons span.description {
    top: 47%;
  }
  .abouticons h2 {
    margin: 10px;
  }
  #pricing-tables .frontendtable {
    height: 100%;
  }
}
/***********************************************
                    HEADER
***********************************************/
.topBar {
  padding: 0 25px;
  height: 65px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 101;
  -webkit-transition: margin-left 0.3s ease;
  transition: margin-left 0.3s ease;
}

.topBar.transparent {
  background-color: transparent;
}

.topBar.white {
  background-color: #ffffff;
  -webkit-box-shadow: 4px 0 5px rgba(0, 0, 0, 0.3);
  box-shadow: 4px 0 5px rgba(0, 0, 0, 0.3);
}

.topBar.black {
  background-color: rgba(25, 25, 25, 0.95);
}

.topBarLeft {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.topBarRight {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  flex-direction: row-reverse;
}

.bgImg {
  position: relative;
  border-bottom: none;
  background-position: center;
  -webkit-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
}

.header {
  position: relative;
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  background-color: #fff;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
  border-bottom: 1px solid #ebebeb;
}

.headerInfo {
  padding: 0 30px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.media {
  padding: 16px 12px;
  -webkit-transition: background-color 0.2s linear;
  transition: background-color 0.2s linear;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
}

.media .avatarNew {
  flex-shrink: 0;
}

.media > * {
  margin: 0 8px;
}

.mediaBody > * {
  margin-bottom: 0;
}

.mediaBody {
  min-width: 0;
  -ms-flex: 1;
  flex: 1;
}

.alignItemsEnd {
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

header .logo {
  /* font-family: 'Monoton', cursive;
  color: #ffffff;
  font-size: 40px;
  */
  padding: 0;
  margin-top: 0;
  margin-left: 1rem;
}

header .navigation {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 1rem;
}

header .clear {
  flex: 1;
}

header .navigation_items ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

header .navigation_items li {
  position: relative;
  float: left;
  display: block;
}

header .navigation_items a {
  color: #fff;
  text-decoration: none;
  display: inline-block;
  font-weight: 600;
  font-family: "Work Sans", sans-serif;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 1rem 1rem 0.6rem 1rem;
  border-bottom: 3px solid transparent;
  background-color: transparent !important;
  -webkit-transition: 0.2s linear;
  transition: 0.2s linear;
}

header .navigation_items a:hover,
header .navigation_items a:active,
header .navigation_items a.active {
  color: #f63a2a;
}

header .navigation_items button {
  color: #fff;
  outline: none;
  white-space: nowrap;
  text-decoration: none;
  border: 0;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  font-family: "Work Sans", sans-serif;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 1rem 1rem 0.6rem 1rem;
  border-bottom: 3px solid transparent;
  background-color: transparent !important;
  -webkit-transition: 0.2s linear;
  transition: 0.2s linear;
}

header .navigation_items button:hover,
header .navigation_items button:active,
header .navigation_items button.active {
  color: #f63a2a;
}

header .navigation_items .icon {
  font-size: 18px;
}

header .navigation_items .itemContent {
  align-items: center;
  display: table-row;
}

header .navigation_items .itemIcon {
  padding: 7px 3px;
}

header .navigation_items .itemInner {
  display: table-cell;
  width: 100%;
  vertical-align: middle;
  white-space: nowrap;
  align-items: center;
  padding: 10px 5px 10px 5px;
}

.flexShrink {
  flex-shrink: 0;
  position: relative;
}

.itemIcon.hasNew {
  position: relative;
}

.itemIcon.hasNew::after {
  content: "";
  position: absolute;
  top: -3px;
  right: -3px;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  border: 2px solid #fff;
  background-color: #f96868;
}

@media (max-width: 767.98px) {
  .headerInfo {
    padding: 0;
  }
}
/* header a {
    margin-left: 20px;
}

header .container {
    display: flex;
}

header .container .left{
    width: 250px;
}

header .container .right{
   flex-grow: 1;
   border-left: 1px solid #eeeeee;
   border-right: 1px solid #eeeeee;
}
header .container .right .top ,
header .container .right .bottom {
    text-transform: uppercase;
    font-weight: 700;
    text-align: right;
    color:#ffffff;
    font-size: 15px;
    padding: 10px 20px;
}

header .container .right .top {
    color:#c9c9c9;
    border-bottom: 1px solid #ffffff;
}

header .cart_link {
    display: inline-block;
    position: relative;
}

header .cart_link span {
    position: absolute;
    background: red;
    top: 2px;
    color: #ffff;
    left: -8px;
    border-radius: 50%;
    padding: 0px 7px 2px 7px;
    font-size: 12px;
}
header .log_out_link {
    cursor: pointer;
    display: inline-block;
    margin-left: 20px;
} */
/***********************************************
                   ADMIN
***********************************************/
#main {
  position: relative;
  min-height: 100%;
  background: #f1f3fa;
}

#main:before {
  content: "";
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #f1f3fa;
}

.navbar {
  z-index: 1030;
  margin-bottom: 0;
  height: 56px;
  color: #30333a;
  background-color: #fff;
  border-radius: 0;
  border: 0;
  box-shadow: none;
}

.navbar .nav {
  margin: 0;
}

.navbar .nav > li {
  float: left;
}

.nav > li {
  position: relative;
  display: block;
}

.nav > li > a,
.nav > li > .memberDiv {
  position: relative;
  display: block;
  padding: 10px 15px;
}

.nav > li > a .name,
.nav > li > .memberDiv .name {
  color: #30333a;
  padding-left: 5px;
  font-size: 16px;
  font-weight: 400;
}

.nav .dropdown [class*=profile-] {
  margin-left: 2px;
  position: relative;
  display: inline-block;
}

.nav > li > a .profile-online:before,
.nav > li > .memberDiv .profile-online:before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 8px;
  cursor: default;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid #fff;
  background-color: #73cd61;
}

.nav > li > a img,
.navbar .nav > li > .memberDiv img {
  border-radius: 50%;
  border: 3px solid #dfdfdf;
  max-width: 49px;
  margin-top: 2px;
  margin-bottom: 5px;
}

.navbar .nav > li > a.dropdowntoggle,
.navbar .nav > li > .memberDiv.dropdowntoggle {
  margin-left: 22px;
}

.navbar .nav li a.dropdowntoggle svg,
.navbar .nav li .memberDiv svg {
  font-size: 10px;
  position: relative;
  top: -2px;
  text-align: center;
  width: 20px;
}

.navbarnav.navbarleft > li > a {
  padding-right: 12px;
  padding-left: 7px;
  margin-top: 2px;
}

.navbar .nav > li > a,
.navbar .nav > li > .memberDiv {
  color: #30333a;
  font-size: 16px;
  font-weight: 400;
  line-height: 55px;
  padding-top: 0;
  padding-bottom: 0;
  height: 55px;
  max-height: 55px;
  cursor: pointer;
}

.navbar .nav > li.dropdown.open .dropdownmenu {
  margin-top: 0;
  border-radius: 0;
}

.navbar .nav > li.dropdown .dropdownmenu {
  margin-top: 0;
  border-radius: 0;
  padding: 18px 0 13px 0;
}

.navbar .nav > li.dropdown .dropdownmenu li > a,
.navbar .nav > li.dropdown .dropdownmenu li > .memberHeaderLink {
  padding: 0 33px;
  line-height: initial;
  font-size: 15px;
  cursor: pointer;
}

.navbar .nav > li.dropdown .dropdownmenu .divider {
  margin: 7px 0 22px;
  background-color: #dfdfdf;
}

.navbar .nav > li.dropdown .dropdownmenu li:not(.divider):not(.media):not(.timeline-item) {
  height: 30px;
}

.navbar .navbarbtn {
  padding: 12px 5px 12px 5px;
}

.navbar .navbarbtn.btngroup > .btn svg {
  color: #30333a;
}

.navbar .navbarbtn.btngroup > .btn {
  color: #b3b3b3;
  background-color: #fff;
  border: 2px solid #30333a;
  border-radius: 50%;
  font-size: 10px;
  width: 31px;
  height: 31px;
  line-height: 31px;
  padding: 0;
  z-index: 5;
}

.navbar .navbarbtn.btngroup > .btn.bgcolor1 {
  background-color: #10cfbd;
}

.navbar .navbarbtn.btngroup > .btn.bgcolor2 {
  background-color: #6d5cae;
}

.navbar .navbarbtn.btngroup > .btn + .btn {
  color: #fff !important;
  width: initial;
  z-index: 5;
  width: 16px;
  height: 16px;
  line-height: 13px;
  border-radius: 50%;
  border: 1px solid #fff;
  position: absolute;
  bottom: 10px;
  left: 25px;
}

.navbar .navbarbtn.btngroup.open > .btn svg,
.navbar .navbarbtn.btngroup > .btn.active svg {
  color: #222;
}

.open > .dropdownmenu {
  display: block;
}

.navbarnav > li > a {
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 20px;
}

.dropdownmenu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  padding: 14px 0;
  margin: 0;
  list-style: none;
  font-size: 13px;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  background-clip: padding-box;
}

.dropdownmenu > li > a,
.dropdownmenu > li > .memberHeaderLink {
  display: block;
  padding: 5px 18px;
  clear: both;
  color: #666;
  font-weight: normal;
  line-height: 1.49;
  white-space: nowrap;
}

.dropdownmenu > li > a:hover,
.dropdownmenu > li > .memberHeaderLink:hover,
.navbar .nav .listgroup > li.listgroupitem svg:hover {
  color: #213e96;
}

.dropdownmenu .divider {
  height: 1px;
  margin: 9px 18px;
  overflow: hidden;
  background-color: #4c536e;
}

.divider {
  height: 1px;
  margin: 25px 0;
  background: #e2e2e2;
}

.navbarfixedtop {
  top: 0;
  border-width: 0 0 1px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.navbarfixedtop, .navbarfixedbottom {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 890;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.navbarlogowrapper {
  border-bottom: 0;
  float: left;
  height: 56px;
  margin-right: 0;
  overflow: hidden;
  position: relative;
  width: 245px;
}

.navbarlogoimage {
  display: block;
  margin: 8px 0 0 20px;
}

.navbar .navbarleft {
  border-left: 1px solid #dfdfdf;
}

.navbarnav.navbarright {
  border-left: 1px solid #dfdfdf;
}

.navbar .navbarnav.navbarright, .navbar .navbarnav.navbarright:last-child {
  float: right;
  margin: 0 30px 0 0;
}

.navbarnav.navbarright .dropdowntoggle {
  margin-top: 1px;
}

.navbarform.searchform {
  min-height: 56px;
}

.navbarform {
  margin: 0;
  padding: 14px 20px 13px 18px;
}

.inputgroup {
  position: relative;
  display: table;
  border-collapse: separate;
}

.navbarform.searchform.square input {
  height: initial;
  border-radius: 0;
  border: none;
  outline: none;
  max-width: 170px;
  font-size: 16px;
}

.navbarform.searchform input {
  height: 38px;
  line-height: 36px;
  padding: 0 12px;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  box-shadow: none;
  border-radius: 20px;
  border-color: #EEE;
}

.inputgroup .formcontrol {
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0;
  padding-left: 54px;
}

.formcontrol {
  display: block;
  width: 100%;
  height: 60px;
  padding: 6px 15px;
  font-size: 14px;
  line-height: 1.49;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 3px solid #cfdce2;
  border-radius: 0;
  -webkit-appearance: none;
  -webkit-transition: border-color ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s;
}

.inputgroupbtn {
  position: relative;
  font-size: 0;
  white-space: nowrap;
}

.inputgroupaddon, .inputgroupbtn {
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
}

.inputgroupaddon, .inputgroupbtn, .inputgroup .formcontrol {
  display: table-cell;
}

.navbarform.searchform .btn {
  background-color: transparent;
  border-color: transparent;
  border-radius: 0;
  color: #000;
  line-height: 38px;
  margin-left: 0;
  padding: 0 6px;
  width: initial;
  font-size: 14px;
}

.inputgroupbtn > .btn {
  position: relative;
}

.navbarform.searchform .btn svg {
  vertical-align: 0;
}

.navbarnav.navbarleft {
  float: left;
  position: relative;
  max-height: 56px;
}

.navbarform {
  margin-left: -7px;
  margin-right: -7px;
  padding: 10px 7px;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.1);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.1);
}

.listgroup {
  margin-bottom: 20px;
  padding-left: 0;
}

.navbar .nav .listgroup > li.listgroupitem {
  border: 0;
  padding: 0;
}

.listgroupitem:first-child {
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
}

.listgroupitem {
  position: relative;
  display: block;
  padding: 10px 15px;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid #e7e7e7;
}

.navbar .navbarright > li:last-child > .dropdownmenu > li > a,
.navbar .navbarright > li:last-child > .dropdownmenu > li > .memberHeaderLink {
  white-space: inherit;
}

.navbar .nav .listgroup > li.listgroupitem svg,
.navbar .nav .listgroup > li.listgroupitem > a .glyphicon,
.navbar .nav .listgroup > li.listgroupitem > a .glyphicons {
  padding-right: 0;
  float: right;
  color: #cfdce2;
}

.navbar .navbarbtn > .btn > span.ad,
.navbar .navbarbtn > .btn > span > svg,
.navbar .navbarbtn > .btn > span.glyphicon,
.navbar .navbarbtn > .btn > span.glyphicons {
  position: relative;
  top: -3px;
  font-size: 17px;
  vertical-align: middle;
}

.btngroup, .btngroupvertical {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.btngroup > .btn:first-child {
  margin-left: 0;
}

.btngroup > .btn, .btngroupvertical > .btn {
  position: relative;
  float: left;
}

header .navigationicon {
  color: rgba(77, 82, 89, 0.7);
  text-decoration: none;
  display: inline-block;
  font-weight: 600;
  font-family: "Work Sans", sans-serif;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 1rem 1rem 0.6rem 1rem;
  border-bottom: 3px solid transparent;
  background-color: transparent !important;
  transition: 0.2s linear;
  cursor: pointer;
}

header .navigationicon:hover,
header .navigationicon:active,
header .navigationicon.active {
  color: #4d5259;
  border-bottom-color: #33cabb;
}

@media (min-width: 480px) {
  .navbar {
    border-radius: 0;
  }
  .navbarnav {
    float: left;
    margin: 0;
  }
  .navbarnav > li {
    float: left;
  }
  .navbarRight .dropdownmenu {
    left: auto;
    right: 0;
  }
  .navbarfixedtop {
    border-radius: 0;
  }
  .navbarform {
    width: auto;
    border: 0;
    margin-left: 0;
    margin-right: 0;
    padding-top: 0;
    padding-bottom: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .navbarleft {
    float: left;
    float: left !important;
  }
  .navbarright {
    float: right;
    float: right !important;
  }
}
@media (min-width: 768px) {
  .navbarform .inputgroup {
    display: inline-table;
    vertical-align: middle;
    padding-top: 9px;
  }
  .navbarform .inputgroup > .formcontrol {
    width: 100%;
  }
  .navbarform .formcontrol {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .navbarform .inputgroup .inputgroupaddon,
.navbarform .inputgroup .inputgroupbtn,
.navbarform .inputgroup .formcontrol {
    width: auto;
  }
  .navbarform.searchform input {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .navbarform.searchform {
    margin-left: 0 !important;
  }
}
@media (min-width: 850px) {
  .memberToggleButton {
    display: none;
  }
}
@media (max-width: 360px) {
  .nav > li > .memberDiv {
    padding: 10px 0;
  }
  .nav .ml20 {
    margin-left: 5px !important;
  }
  .nav.mr25 {
    margin-right: 5px !important;
  }
}
@media (max-width: 479px) {
  .navbarform.searchform .btn {
    line-height: 45px !important;
  }
  .navbarnav .open .dropdownmenu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    box-shadow: none;
  }
  .navbar .nav > li > a.dropdowntoggle,
.navbar .nav > li > .memberDiv.dropdowntoggle {
    margin-left: 6px;
  }
  .navbarleft .dropdownmenu {
    left: 0 !important;
  }
  .navbarright .profilemenu {
    left: -200% !important;
  }
  .nav > li > .memberDiv {
    padding: 10px 5px;
  }
  .nav .ml20 {
    margin-left: 10px !important;
  }
  .nav.mr25 {
    margin-right: 10px !important;
  }
  .menuLink {
    padding: 0 !important;
  }
}
@media (max-width: 767.98px) {
  input[placeholder] {
    text-overflow: ellipsis;
  }
}
@media (max-width: 849px) {
  .memberSideDrawerLogo {
    box-shadow: 4px 0 5px rgba(0, 0, 0, 0.2);
  }
  .memberSideDrawer {
    box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  }
  .memberToggleButton {
    width: 53px;
    line-height: 56px;
    margin-top: 1px;
    float: left;
  }
  .navbarform.searchform .btn {
    line-height: 30px;
  }
  .navbarleft .dropdownmenu,
.navbarright .dropdownmenu,
.navbarleft .open .dropdownmenu,
.navbarright .open .dropdownmenu {
    z-index: 1000;
    margin-top: 0 !important;
    float: left;
    position: absolute;
    top: 99%;
    left: -100%;
    min-width: 101%;
    list-style: none;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    background-clip: padding-box;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  }
}
@media (max-width: 900px) {
  .navbarlogowrapper {
    width: 0;
  }
  .navbarlogowrapper .navbarlogoimage img.mobileHidden {
    display: none;
  }
  .navbarform.searchform {
    padding: 6px 16px;
    font-size: 16px;
    margin: 0;
    cursor: pointer;
    width: 61px;
    float: left;
    height: 56px;
  }
  .navbarform.searchform input {
    opacity: 0;
    z-index: -1;
    visibility: hidden;
    position: absolute;
    top: -56px;
    left: 0;
    width: 100%;
    height: 66px;
    margin: 0;
    font-size: 24px;
    color: #AAA;
    border-radius: 0;
    border: 0;
    text-align: center;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  .navbarform.searchform.square input {
    -webkit-border-radius: 5px;
    border-radius: 5px;
  }
  .navbarform.searchform > .inputgroup.addon {
    position: inherit;
  }
}
/***********************************************
                    LOGIN
***********************************************/
.loginWrapper {
  background: #fafafa;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  margin: auto;
  width: 100%;
  position: relative;
  min-height: 100%;
}

.loginContainer {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.loginWrapper .loginContainer .loginTitle {
  padding: 0 0 4.61rem;
  max-width: 61.53rem;
  font-size: 1.23rem;
  line-height: 2rem;
  margin: 0 auto;
}

.loginWrapper .loginContainer .loginInner {
  min-width: 14rem;
  max-width: 26rem;
  margin: 0 auto;
  padding: 2rem;
  border-radius: 10px;
  overflow: hidden;
  background-color: #ffffff;
  position: relative;
  z-index: 2;
}

/***********************************************
                GENERAL LOGIN
***********************************************/
.loginMemberContainer {
  display: flex;
  flex-direction: column;
  min-height: 95vh;
}

.loginWrapper .loginMemberContainer .loginTitle {
  padding: 0 0 4.61rem;
  max-width: 61.53rem;
  font-size: 1.23rem;
  line-height: 2rem;
  margin: 0 auto;
}

.loginWrapper .loginMemberContainer .loginInner {
  min-width: 14rem;
  max-width: 26rem;
  margin: 0 auto;
  padding: 2rem;
  border-radius: 10px;
  overflow: hidden;
  background-color: #ffffff;
  position: relative;
  z-index: 2;
}

.loginWrapper .loginMemberContainer .loginMemberTitle {
  flex: 1 1 0%;
  box-sizing: border-box;
  padding: 128px;
}

.loginMemberTitle .memberLoginLogo {
  width: 128px;
  margin-bottom: 32px;
}

.loginMemberTitle .memberLoginTitle {
  font-size: 42px;
  font-weight: 300;
  line-height: 1;
}

.loginMemberTitle .memberLoginDescription {
  padding-top: 16px;
  font-size: 14px;
  max-width: 600px;
}

.loginFlexDirectionRow {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: stretch flex-start;
  align-items: stretch;
}

.loginFormWrapper {
  width: 400px;
  min-width: 400px;
  max-width: 400px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2), 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #fff;
  position: relative;
  overflow: hidden !important;
  overflow-anchor: none;
  transform: translateX(100%);
  transition: transform 0.2s ease;
  will-change: transform;
}

.loginFormWrapperOpen {
  transform: translateX(0) !important;
}

.loginFormInner {
  padding: 128px 48px 48px;
}

.loginFormInner .memberLoginFormLogo {
  margin: 32px auto;
  text-align: center;
}

.loginFormInner .memberLoginFormTitle {
  font-size: 21px;
}

@media (max-width: 767.98px) {
  .loginFormWrapper {
    min-width: 0;
    width: 100%;
    max-width: 100%;
  }
  .loginFormInner {
    padding: 28px;
  }
  .loginMemberTitle {
    display: none;
  }
}
@media (min-width: 768px) {
  .memberLoginFormLogo .pcHidden {
    display: none;
  }
}
/***********************************************
                MAIN DASHBOARD
***********************************************/
.masterDashboard {
  margin-left: 200px;
  flex: 1 0 auto;
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  position: relative;
  overflow: hidden;
}

.masterDashboard.rightsidebar > .center {
  order: 1;
}

.masterDashboard.simple.rightsidebar > .center {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  z-index: 3;
  min-width: 0;
  width: 100%;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}

.ps {
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
}

.headerDashboard {
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  place-content: stretch space-between;
  align-items: stretch;
}

.center > .headerDashboard {
  padding-bottom: 20px;
}

.accent {
  background-color: #213e96 !important;
  color: #fff !important;
}

.headerDashboardTitle {
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.headerDashboardName {
  font: 400 34px/40px Muli, Helvetica Neue, Arial, sans-serif;
}

.masterDashboard > .center > .contentDashboard {
  background: #f5f5f5;
  flex: 1;
}

.masterDashboard > .center > .contentDashboard .contentDashboardWrapper {
  height: 100%;
}

.contentDashboardWrapper {
  display: flex;
  flex-direction: column;
}

.masterDashboard .contentDashboardHeader {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.contentDashboardHeader {
  display: flex;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
}

.masterDashboard > .center > .contentDashboard .contentDashboardWrapper .contentDashboardBody {
  flex-grow: 1;
}

.contentDashboardBody {
  position: relative;
  overflow: hidden;
  display: flex;
  transition: height 0.5s cubic-bezier(0.35, 0, 0.25, 1);
}

.masterDashboard > .center > .contentDashboard .contentDashboardWrapper .contentDashboardBody .contentDashboardBodyWrapper {
  overflow-y: hidden;
}

.contentDashboardBodyWrapper.active {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1;
  flex-grow: 1;
}

.contentDashboardBodyWrapper {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  display: block;
  overflow: hidden;
  flex-basis: 100%;
}

.contentDashboardBodyContent {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  transform: none;
}

.dynamicHeight .contentDashboardBodyContent {
  height: 100%;
  overflow: hidden;
}

.widgetDashboardGroup {
  flex-flow: row wrap;
  box-sizing: border-box;
  display: flex;
  flex: 1 1 100%;
  max-width: 100%;
}

.widgetDashboard {
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  padding: 12px;
}

.widgetDashboard .widgetFront {
  background: #fff;
  border-color: rgba(0, 0, 0, 0.12) !important;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  position: relative;
  overflow: hidden;
  visibility: visible;
  width: 100%;
  opacity: 1;
  z-index: 10;
  border-radius: 8px;
  transition: visibility 0s ease-in 0.2s, opacity 0s ease-in 0.2s, -webkit-transform 0.5s ease-out 0s;
  transition: transform 0.5s ease-out 0s, visibility 0s ease-in 0.2s, opacity 0s ease-in 0.2s;
  transition: transform 0.5s ease-out 0s, visibility 0s ease-in 0.2s, opacity 0s ease-in 0.2s, -webkit-transform 0.5s ease-out 0s;
  -webkit-transform: rotateY(0);
  transform: rotateY(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border: 1px solid;
}

.widgetrow1, .sidewidget1 {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.widgetrow2, .sidewidget2 {
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  place-content: center;
  align-items: center;
}

.widgetrow3 {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center flex-start;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding: 16px;
}

.widgetrow1 {
  height: 52px !important;
  min-height: 52px !important;
  max-height: 52px !important;
}

.widgetrow1, .sidewidget1 {
  padding-left: 16px !important;
  padding-right: 8px !important;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.widgetrow2 {
  padding-top: 8px !important;
  padding-bottom: 32px !important;
}

.widgetrow1Text {
  display: inline-block;
  position: relative;
  text-align: left;
  font: 400 16px/1.125 Muli, Helvetica Neue, Arial, sans-serif;
}

.widgetrow2Text {
  color: #03a9f4 !important;
}

.widgetrow2Text {
  font-size: 72px;
  line-height: 72px;
}

.widgetrow3Text {
  font-size: 15px;
  font-weight: 400;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.54);
}

.widgetrow2Subtext {
  color: rgba(0, 0, 0, 0.54);
  font-weight: 500;
  font-size: 16px;
}

.widgetrow3Subtext {
  font-size: 15px;
  font-weight: 400;
  margin-left: 8px !important;
}

.widgetrow1TextWrapper {
  padding: 18px 0;
  font-family: Muli, Helvetica Neue, Arial, sans-serif;
}

.widgetrow1Button {
  padding: 0;
  min-width: 0;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  line-height: 40px;
  border-radius: 50%;
}

.widgetrow1Button {
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: 0;
  border: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 32px;
  padding: 6px 0px 4px 16px;
  border-radius: 4px;
  overflow: visible;
  background-color: transparent;
}

.widgetrow1ButtonWrapper {
  line-height: normal;
}

.widgetrow1ButtonWrapper svg {
  font-size: 18px;
}

.masterDashboard > .sidebar {
  width: 280px;
  min-width: 280px;
  max-width: 280px;
}

.masterDashboard.simple.rightsidebar > .sidebar.locked-open {
  background: 0 0;
  box-shadow: none;
}

.masterDashboard.simple.rightsidebar > .sidebar {
  order: 2;
  overflow: hidden;
}

.masterDashboard .posRelative {
  position: relative !important;
}

.masterDashboard .rightposition {
  right: 0;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  transition: transform 0.2s ease;
  will-change: transform;
}

.sidebarDashboard {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  width: 280px;
  min-width: 280px;
  max-width: 280px;
  z-index: 860;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.35);
}

.sidebarDashboard.lockedopen {
  position: relative;
}

.sidebarNotification {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  width: 280px;
  min-width: 280px;
  max-width: 280px;
  z-index: 1000;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.35);
  position: absolute !important;
  top: 0 !important;
  bottom: 0 !important;
}

.sidebarNotification.lockedopen {
  position: relative;
}

.sidebarNotification.rightposition {
  right: 0;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  transition: transform 0.2s ease;
  will-change: transform;
}

.sidebarNotification.open {
  -webkit-transform: translateX(0) !important;
  transform: translateX(0) !important;
}

.sidebarContent {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  flex: 1 1 auto;
  background-color: #f5f5f5;
}

.sidebarWidgetGroup {
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  flex: 1 1 100%;
  max-width: 100%;
}

.sidebarWidget {
  display: block;
  position: relative;
  -webkit-perspective: 3000px;
  perspective: 3000px;
  padding: 12px;
}

.sidebarWidget > .sidebarWidgetFront {
  background: #fff;
  border-color: rgba(0, 0, 0, 0.3) !important;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  position: relative;
  overflow: hidden;
  visibility: visible;
  width: 100%;
  opacity: 1;
  z-index: 10;
  border-radius: 8px;
  transition: visibility 0s ease-in 0.2s, opacity 0s ease-in 0.2s, -webkit-transform 0.5s ease-out 0s;
  transition: transform 0.5s ease-out 0s, visibility 0s ease-in 0.2s, opacity 0s ease-in 0.2s;
  transition: transform 0.5s ease-out 0s, visibility 0s ease-in 0.2s, opacity 0s ease-in 0.2s, -webkit-transform 0.5s ease-out 0s;
  -webkit-transform: rotateY(0);
  transform: rotateY(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border: 1px solid;
}

.sidewidget1Text {
  font-size: 16px;
  font-weight: 400;
}

.sidewidget2 {
  padding-bottom: 24px;
  padding: 16px;
}

.sidewidget2Text, .sidewidget2Text3 {
  font-size: 24px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.54);
  font-family: Muli, Helvetica Neue, Arial, sans-serif;
}

.sidewidget2Text2 {
  line-height: 88px;
  font-size: 72px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.74);
  font-family: Muli, Helvetica Neue, Arial, sans-serif;
}

.dashboardCard {
  background: #fff;
  border-color: rgba(0, 0, 0, 0.3) !important;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  position: relative;
  overflow: hidden;
  visibility: visible;
  width: 100%;
  opacity: 1;
  z-index: 10;
  border-radius: 8px;
  border: 1px solid;
}

.dashboardCard .cardTitles {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.74);
  font-family: Muli, Helvetica Neue, Arial, sans-serif;
  padding: 20px;
}

.dashboardCard .cardBodys {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 44px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.74);
  font-family: Muli, Helvetica Neue, Arial, sans-serif;
  padding-bottom: 20px;
}

@media (max-width: 767.98px) {
  .headerDashboardName {
    font: 400 27px/32px Muli, Helvetica Neue, Arial, sans-serif;
  }
  .loginMemberTitle {
    display: none;
  }
}
@media (max-width: 1025px) {
  .masterDashboard {
    margin-left: 0;
  }
  .sidebarDashboard {
    position: absolute !important;
    top: 0 !important;
    bottom: 0 !important;
  }
  .masterDashboard .open {
    -webkit-transform: translateX(0) !important;
    transform: translateX(0) !important;
  }
}
@media (min-width: 1026px) {
  .masterDashboard .open {
    -webkit-transform: translateX(0) !important;
    transform: translateX(0) !important;
  }
}
/***********************************************
                   CARD
***********************************************/
.card {
  margin-bottom: 2.3rem;
  border-radius: 7px;
  border: none;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border-radius: 0.25rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.h4.cardTitle, h4.cardTitle {
  font-size: 17px;
}

.cardTitle {
  font-family: "Work Sans", sans-serif;
  font-weight: 300;
  line-height: 1.5;
  margin-bottom: 0;
  padding: 15px 20px;
  border-bottom: 1px solid rgba(77, 82, 89, 0.07);
}

.cardBody {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
}

.cardBody > :last-child {
  margin-bottom: 0;
}

.cardImage {
  padding: 0;
  border: 1px solid #e5e5e5;
}

.cardPadding {
  padding: 0 15px 5px 15px;
}

.cardItemWrapper {
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background: #ffffff;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  -ms-transition: 0.3s;
  transition: 0.3s;
  position: relative;
  margin-bottom: 20px;
}

.cardItemWrapper .image {
  width: 100%;
  height: 185.5px;
  background-position: center !important;
  background-size: cover !important;
}

.cardItemWrapper .tags {
  position: relative;
  border-top: 1px solid #e5e5e5;
  margin-top: 10px;
  padding-top: 3px;
}

.tags .brand,
.tags .name,
.tags .category,
.tags .price,
.tags .description {
  margin: 5px 0;
  overflow: hidden;
}

.cardItemWrapper .tags .brand {
  font-size: 15px;
  font-weight: 700;
  color: #767676;
}

.cardItemWrapper .tags .category {
  font-size: 11px;
  color: #767676;
}

.cardItemWrapper .tags .name {
  font-size: 13px;
  height: 50px;
}

.cardItemWrapper .tags .price {
  font-weight: 700;
  font-size: 15px;
}

.cardItemTitle {
  font-size: 30px;
  margin-top: 2rem;
  margin-bottom: 0.7142857143rem;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  padding-top: 40px;
  padding-bottom: 16px;
}

.cardItemTitle span {
  color: rgba(77, 82, 89, 0.7);
}

.addToCart {
  position: relative;
  z-index: 5;
  text-align: center;
}

.bag_link {
  font-weight: 500;
}

.cardTransparent {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
}

.myCard {
  border: 0;
  border-radius: 0px;
  margin-bottom: 30px;
  -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.03);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.03);
  -webkit-transition: 0.5s;
  transition: 0.5s;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba 0, 0, 0, 0.125;
}

.myCard > hr {
  margin-right: 0;
  margin-left: 0;
}

hr {
  border-top-color: rgba(77, 82, 89, 0.07);
  margin: 2rem auto;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

@media (max-width: 767.98px) {
  .cardItemWrapper .image {
    height: 158px !important;
  }
}
@media (min-width: 768px) and (max-width: 799px) {
  .cardItemWrapper .image {
    height: 152.5px !important;
  }
}
@media (min-width: 800px) and (max-width: 835.98px) {
  .cardItemWrapper .image {
    height: 169px !important;
  }
}
@media (min-width: 836px) and (max-width: 1025px) {
  .cardItemWrapper .image {
    height: 152.5px !important;
  }
}
.grid_bars {
  position: relative;
  height: 222px;
}

.grid_bars .cardImage {
  padding: 0;
  width: 52.98%;
  float: left;
}

.grid_bars .cardImage .image {
  width: 100%;
  height: 212.5px;
  background-position: center !important;
  background-size: cover !important;
}

.grid_bars .cardPadding {
  padding: 0 10px 10px 10px;
  width: 46.98%;
  float: left;
}

.paddingShop .grid_bars .image {
  width: 142px;
  height: 142px;
  position: relative;
  float: left;
}

.grid_bars .tags {
  position: relative;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
}

.grid_bars .name {
  height: 30px !important;
}

.grid_bars .description {
  overflow: hidden;
  height: 50px !important;
}

.grid_bars .description p {
  margin: 0 !important;
  font-size: 13px;
}

.grid_bars .brand {
  height: 23px;
}

.grid_bars .cardItemWrapper .tags .brand,
.grid_bars .cardItemWrapper .tags .category,
.grid_bars .cardItemWrapper .tags .name,
.grid_bars .cardItemWrapper .tags .price {
  margin: 0;
  overflow: hidden;
}

.grid_bars .name,
.grid_bars .brand,
.grid_bars .category,
.grid_bars .price,
.grid_bars .description {
  padding: 0 0 5px 5px !important;
}

.grid_bars .addToCart {
  right: 0;
  bottom: 0;
  position: absolute;
  width: 100%;
}

.cardItemWrapper.grid_bars {
  height: 250px;
  margin-bottom: 20px !important;
}

@media (max-width: 767.98px) {
  .grid_bars {
    height: 240px !important;
    margin-bottom: 20px;
  }
  .grid_bars .image {
    height: 181.5px !important;
  }
  .grid_bars .cardImage {
    width: 53.98%;
  }
  .grid_bars .cardPadding {
    width: 45.98%;
  }
  .grid_bars .name,
.grid_bars .brand,
.grid_bars .category,
.grid_bars .price,
.grid_bars .description {
    padding: 0 !important;
  }
  .grid_bars .addToCart {
    right: 0;
    bottom: 0;
    padding: 0;
  }
  .grid_bars .name {
    height: 35px !important;
  }
  .grid_bars .description {
    height: 65px !important;
  }
  .grid_bars .brand {
    height: 27px;
  }
}
@media (min-width: 768px) and (max-width: 835px) {
  .grid_bars {
    height: 220px !important;
    margin-bottom: 20px;
  }
  .grid_bars .name,
.grid_bars .brand,
.grid_bars .category,
.grid_bars .price,
.grid_bars .description {
    padding: 0 !important;
  }
  .grid_bars .cardImage .image {
    height: 177.6px !important;
  }
}
@media (min-width: 800px) and (max-width: 835.98px) {
  .grid_bars {
    height: 235px !important;
    margin-bottom: 20px;
  }
  .grid_bars .cardImage .image {
    height: 195px !important;
  }
}
@media (min-width: 836px) and (max-width: 1025px) {
  .grid_bars {
    height: 220px !important;
    margin-bottom: 20px;
  }
  .grid_bars .cardImage .image {
    height: 177.6px !important;
  }
  .grid_bars .name,
.grid_bars .brand,
.grid_bars .category,
.grid_bars .price,
.grid_bars .description {
    padding: 0 !important;
  }
}
.cartTable .table {
  margin: 0;
  white-space: nowrap;
}

.cartTable .tableBordered {
  border: 1px solid #dee2e6;
}

.cartTable .tableBordered td, .tableBordered th {
  border: 1px solid #dee2e6;
}

.cartTable .tableBordered thead td, .tableBordered thead th {
  border-bottom-width: 2px;
}

.cartTable .table thead {
  background-color: #bc9051;
}

.cartTable .table thead tr th {
  border-color: #dee2e6;
  border-bottom: 0 solid transparent;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 15px 20px;
  text-align: center;
  text-transform: uppercase;
}

.myaccountHistory.cartTable .table tbody tr td {
  border-color: #ccc;
  text-align: center;
  padding: 10px 20px;
  vertical-align: middle;
  font-weight: 400;
  color: #333333;
  background-color: white;
}

.cartTable .table tbody tr td {
  border-color: #ccc;
  text-align: center;
  padding: 10px 20px;
  vertical-align: middle;
  font-weight: 600;
  color: #333333;
}

.cartTable .table tbody tr td a {
  color: #333333;
  font-weight: 500;
  text-transform: capitalize;
}

.cartTable .table tbody tr td a:hover {
  color: #bc9051;
}

.cartTable .table tbody tr td a.btn {
  color: #fff;
}

.cartTable .table tbody tr td a.btn:hover {
  color: #fff;
}

.cartTable .table tbody tr td a.check-btn {
  color: #fff;
}

.cartTable .table tr .proThumbnails,
.cartTable .table tr .proPrice,
.cartTable .table tr .proQuantity,
.cartTable .table tr .proSubtotal,
.cartTable .table tr .proRemove {
  width: 140px;
}

.cartTable .table tbody tr .proThumbnails {
  padding: 10px !important;
}

.cartTable .table tbody tr .proTitle {
  text-align: start !important;
}

.cartTable .table tbody tr .proTitle a {
  font-size: 17px;
}

.cartTable .table tbody tr .proTitle p {
  font-weight: 400;
  font-size: 14px;
}

.cartTable .table tbody tr .proPrice,
.cartTable .table tbody tr .proSubtotal {
  text-align: end !important;
}

.cartTable .table tr .quantityBox {
  width: 90px;
  height: 40px;
  border: 1px solid #ddd;
  padding: 0 15px;
  float: left;
}

.cartTable .table tr .quantityBox .qtybtn {
  width: 15px;
  display: block;
  float: left;
  line-height: 38px;
  cursor: pointer;
  text-align: center;
  font-size: 22px;
  font-weight: 400;
  color: #666666;
}

.cartTable .table tr .quantityBox input {
  width: 28px;
  float: left;
  border: none;
  height: 40px;
  line-height: 34px;
  padding: 0;
  text-align: center;
  background-color: transparent;
}

.cartTable .cartIcon {
  min-height: 400px;
}

.cartTable .cartIcon .icon {
  font-size: 250px;
  color: #3bb75e;
}

.cartTable .cartIcon .successText {
  color: #3bb75e;
  font-size: 25px;
}

.cartUpdateLogin,
.cartUpdateOption {
  border: 1px solid #ccc;
  margin-top: 15px;
  padding: 15px 20px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media only screen and (min-width: 1200px), only screen and (min-width: 992px) and (max-width: 1199.98px) {
  .cartUpdateOption {
    margin-top: 0;
    border-top: 0 solid transparent;
  }
  .cartUpdateLogin {
    margin-top: 0;
  }
}
.cartUpdateOption .applyCouponWrapper,
.cartUpdateLogin .applyCouponWrapper {
  width: 55%;
}

@media only screen and (max-width: 767.98px), only screen and (min-width: 768px) and (max-width: 991.98px) {
  .cartUpdateOption .applyCouponWrapper, .cartUpdateLogin .applyCouponWrapper {
    width: auto;
  }
}
.cartUpdateOption .applyCouponWrapper form,
.cartUpdateLogin .applyCouponWrapper form,
.cartUpdateLogin .cartUpdate form {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  white-space: nowrap;
}

.cartUpdateOption .applyCouponWrapper form input,
.cartUpdateLogin .applyCouponWrapper form input,
.cartUpdateLogin .cartUpdate form input {
  border: 1px solid #ccc;
  font-size: 14px;
  padding: 6px 10px;
  outline: none;
  margin-right: 15px;
  width: 100%;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.cartUpdateOption .applyCouponWrapper form input:focus, .cartUpdateOption .applyCouponWrapper form input:active,
.cartUpdateLogin .applyCouponWrapper form input:focus, .cartUpdateLogin .applyCouponWrapper form input:active,
.cartUpdateLogin .cartUpdate form input:focus, .cartUpdateLogin .cartUpdate form input:active {
  border-color: #bc9051;
}

@media only screen and (max-width: 767.98px) {
  .cartUpdateOption .applyCouponWrapper button,
.cartUpdateLogin .applyCouponWrapper button,
.cartUpdateLogin .cartUpdate button {
    display: block;
    width: 100%;
    margin-top: 15px;
    border-radius: 0;
  }
  .cartUpdateLogin .applyCouponWrapper button {
    margin-bottom: 25px;
  }
  .cartUpdateLogin .w35 {
    width: 100% !important;
  }
  .cartUpdateLogin .applyCouponWrapper form input,
.cartUpdateLogin .cartUpdate form input {
    width: 100%;
    display: block;
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 767.98px) {
  .cartUpdateOption .cartUpdate .btn,
.cartUpdateLogin .cartUpdate .btn {
    width: 100%;
  }
  .proThumbnails .cartThumbnail {
    height: 103.6px !important;
  }
}
@media (min-width: 768px) and (max-width: 799px) {
  .proThumbnails .cartThumbnail {
    height: 103.6px !important;
  }
}
@media (min-width: 800px) and (max-width: 835.98px) {
  .proThumbnails .cartThumbnail {
    height: 105.7px !important;
  }
}
.checkoutBillingDetailsWrapper h2,
.orderSummaryDetails h2 {
  color: #333333;
  font-size: 20px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
  margin-bottom: 30px;
}

.checkoutname {
  font-size: 20px;
  font-family: "Lato", sans-serif;
}

.checkoutBillingDetailsWrapper .billingFormWrapper {
  margin-top: -20px;
}

.singleInputItem {
  margin-top: 20px;
  margin-bottom: 5px;
}

.singleInputItem label {
  margin-bottom: 0.5rem;
}

.singleInputItem label.required::after {
  content: "*";
  color: red;
  font-size: 14px;
  margin-left: 3px;
  margin-top: 5px;
}

.singleInputItem input, .singleInputItem textarea {
  color: #666666 !important;
  border: 1px solid #ccc !important;
  padding: 15px 10px !important;
  width: 100% !important;
  font-size: 14px !important;
  height: 50px !important;
  background-color: #f7f7f7 !important;
  border-radius: 0px !important;
}

.checkoutBoxWrapper .customControl {
  padding-left: 0;
}

.customControl {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.customControlInput {
  opacity: 0;
  position: absolute;
  margin-left: -20px;
}

.customControlLabel::after {
  display: flex;
  margin-right: 1.5rem;
  width: 1rem;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.customCheckbox .customControlLabel::before {
  border-radius: 0.25rem;
}

.customControlLabel::before {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.customControlLabel::before {
  display: flex;
  margin-right: 1.5rem;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #dee2e6;
}

@media (max-width: 767.98px) {
  .customControlLabel::before {
    margin-right: 9px;
  }
  .profilecheckoutcheck .customCheckbox input ~ svg,
.customCheckbox input ~ svg {
    bottom: 10px !important;
  }
  .customCheckbox label::before, .customCheckbox .customControlLabel::before {
    width: 34px !important;
  }
}
@media (min-width: 768px) and (max-width: 835px) {
  .customCheckbox label::before, .customCheckbox .customControlLabel::before {
    width: 26px !important;
  }
  .profilecheckoutcheck .customCheckbox input ~ svg,
.customCheckbox input ~ svg {
    bottom: 10px !important;
  }
}
@media (min-width: 836px) and (max-width: 1025px) {
  .customCheckbox label::before, .customCheckbox .customControlLabel::before {
    width: 19px !important;
  }
  .profilecheckoutcheck .customCheckbox input ~ svg,
.customCheckbox input ~ svg {
    bottom: 10px !important;
  }
}
.customCheckbox label, .customCheckbox .customControlLabel,
.customRadio label, .customRadio .customControlLabel {
  color: #333333;
  line-height: 1;
  cursor: pointer;
  font-size: 14px;
  display: flex;
}

.customCheckbox label::before, .customCheckbox .customControlLabel::before,
.customRadio label::before, .customRadio .customControlLabel::before {
  background-color: #ddd;
  border: 1px solid #ddd;
  border-radius: 0;
  height: 17px;
  top: 0;
  width: 17px;
}

.customCheckbox label::after, .customCheckbox .customControlLabel::after,
.customRadio label::after, .customRadio .customControlLabel::after {
  background-size: 10px 10px;
  height: 17px;
  top: 0;
  width: 17px;
}

.profilecheckoutcheck .customCheckbox input:checked ~ .customControlLabel::before,
.profilecheckoutcheck .customCheckbox input:checked label::before,
.customCheckbox input:checked ~ .customControlLabel::before,
.customCheckbox input:checked label::before,
.customCheckbox .customControlInput:checked ~ .customControlLabel::before,
.customCheckbox .customControlInput:checked label::before,
.customRadio input:checked ~ .customControlLabel::before,
.customRadio input:checked label::before,
.customRadio .customControlInput:checked ~ .customControlLabel::before,
.customRadio .customControlInput:checked label::before {
  background-color: #bc9051;
  border-color: #bc9051;
}

.customRadio label::before,
.customRadio .customControlLabel::before {
  border-radius: 50%;
  height: 17px;
  width: 17px;
}

.customRadio label::after,
.customRadio .customControlLabel::after {
  background-position: 1px 1px;
  background-size: 9px 8px;
  left: -22px;
  top: 2px;
  height: 10px;
  width: 10px;
}

.customControl input {
  height: 23px !important;
  z-index: 9;
}

.profilecheckoutcheck .customCheckbox input:checked ~ svg,
.customCheckbox input:checked ~ svg,
.customRadio input:checked ~ svg {
  display: block;
}

.customCheckbox input ~ svg {
  margin: auto;
  position: absolute;
  left: 2px;
  top: 0;
  bottom: 7px;
  display: block;
  width: 4;
  height: 4;
  display: none;
}

.profilecheckoutcheck .customCheckbox input ~ svg {
  margin: auto;
  position: absolute;
  left: 1px;
  top: 0;
  bottom: 11px;
  display: block;
  width: 4;
  height: 4;
  display: none;
}

.customRadio input ~ svg {
  margin: auto;
  position: absolute;
  left: 5px;
  top: 0;
  bottom: 7px;
  display: block;
  width: 4;
  height: 4;
  display: none;
}

.customCheckbox .customControlLabel span {
  margin-top: 1px;
}

.orderSummaryContent .tableResponsive {
  display: block;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.orderSummaryContent .tableResponsive > .tableBordered {
  border: 0;
}

.orderSummaryTable {
  background-color: #f7f7f7;
  margin-top: 28px;
}

.orderSummaryTable .table tr td, .orderSummaryTable .table tr th, .orderSummaryTable table tr td, .orderSummaryTable table tr th {
  font-weight: 500;
  vertical-align: middle;
  padding: 15px 10px;
  border-width: 1px;
}

.orderSummaryTable .table tr td.textRight, .orderSummaryTable table tr td.textRight {
  padding: 15px;
}

.orderSummaryTable .tableBordered thead td, .orderSummaryTable .tableBordered thead th {
  border-bottom-width: 2px;
}

.orderSummaryTable .tableBordered th {
  border-bottom: 0 !important;
}

.orderSummaryTable .tableBordered th, .orderSummaryTable .tableBordered td {
  vertical-align: bottom;
  border: 1px solid #dee2e6;
}

.orderPaymentMethod {
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  padding: 3px 20px 20px;
  margin-top: 20px;
}

.cartTableResponsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.cartTableResponsive > .table-bordered {
  border: 0;
}

.subtotalMainWrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.subtotalWrapper {
  margin-top: 30px;
  background-color: #f8f8f8;
}

.subtotalWrapper h3 {
  color: #333333;
  font-size: 18px;
  font-weight: 500;
  padding: 27px 15px 25px;
  margin-bottom: 0;
}

.subtotalWrapper .subtotalItems {
  font-weight: 500;
}

.subtotalWrapper .subtotalItems .table {
  margin-bottom: 0;
}

.subtotalWrapper .subtotalItems .table tr td {
  color: #333333;
  padding: 15px 20px;
}

.subtotalWrapper .subtotalItems .table tr td:nth-child(2) {
  color: #333333;
  text-align: right;
}

.subtotalWrapper .subtotalItems .table tr td.totalAmount {
  color: #bc9051;
  font-weight: 700;
}

.subtotalWrapper a {
  border-radius: 0;
  text-align: center;
}

.cartmobilepadding {
  padding: 100px 0;
}

@media (max-width: 767.98px) {
  .cartmobilepadding {
    padding: 20px 0;
  }
}
@media (min-width: 768px) and (max-width: 835px) {
  .cartmobilepadding {
    padding: 30px 0;
  }
}
/********************************
             Loading
********************************/
.thumbnailLoading {
  width: 119px;
  height: 19px;
}

.titleheadLoading {
  width: 300px;
  height: 19px;
}

.priceheadLoading {
  width: 90px;
  height: 19px;
}

.quantityheadLoading {
  width: 90px;
  height: 19px;
}

.subtotalheadLoading {
  width: 90px;
  height: 19px;
}

.removeheadLoading {
  width: 90px;
  height: 19px;
}

.cartImageLoading {
  width: 119px;
  height: 119px;
}

.titleLoading {
  width: 300px;
  height: 19px;
}

.priceLoading {
  width: 90px;
  height: 19px;
}

.quantityLoading {
  width: 90px;
  height: 19px;
}

.subtotalLoading {
  width: 90px;
  height: 19px;
}

.removeLoading {
  width: 90px;
  height: 19px;
}

.inputLoading {
  width: 487px;
  height: 19px;
}

.cartTable.tableLoading .tableBordered {
  border: 1px solid #e2e2e2;
}

.cartTable.tableLoading .table thead {
  background-color: #ffffff !important;
}

.cartTable.tableLoading .table thead tr th {
  border-color: #ffffff;
}

.cartTable.tableLoading .table tbody tr td {
  border-color: #ffffff;
}

.cartTable.tableLoading .table tbody tr .proThumbnails {
  padding: 10px 20px !important;
}

.cartUpdateOption.cartUpdateOptionLoading {
  border: 0;
}

.cartUpdateOption.cartUpdateOptionLoading .applyCouponWrapper {
  width: 40%;
}

.cartUpdateOption.cartUpdateOptionLoading .applyCouponWrapper .inputLoading {
  width: 550px;
}

/***********************************************
                    SHOP
***********************************************/
.page_top {
  color: rgba 0, 0, 0, 0.87;
  font-size: 1rem;
  font-weight: 400;
  font-family: "Work Sans", sans-serif;
  border-bottom: 1px solid;
}

.gridsRow {
  padding: 20px 0px 20px 20px;
}

.active .gridsRowView {
  color: #212121;
}

.gridsRowView {
  color: #878787;
  margin: 0 12px 0 0;
  font-size: 26px;
  line-height: 32px;
  cursor: pointer;
}

.displayFlex {
  display: flex;
}

.paddingShop {
  padding-bottom: 25px !important;
}

.paddingShop .cardItemWrapper .image {
  height: 165px;
}

.paddingShop .addToCart {
  margin-left: 22px;
}

.filterWrapper {
  padding-top: 25px;
}

.filterTitle {
  margin-bottom: 20px;
  position: relative;
}

.filterTitle h3 {
  color: #333333;
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  margin-top: -3px;
  padding-left: 10px;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
}

.filterLists {
  margin: 0;
  padding: 0;
}

.filterLists li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
}

.filterLists li:last-child {
  border-bottom: none;
}

.filterLists li .filterItem {
  color: #333333;
  font-size: 15px;
  line-height: 1.5;
  padding: 10px;
  display: inline-block;
  text-transform: capitalize;
  width: 100%;
  cursor: pointer;
}

.filterLists li .filterItem:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.filterLists li span {
  padding-bottom: 2px;
  float: right;
  margin-right: 5px;
}

.filterLists li input {
  float: right;
}

.filterHidden {
  position: absolute;
  z-index: 1000;
  left: 0;
  background-color: #fff;
  width: 250px;
  transform: translateX(-100%);
  transition: transform 0.2s ease;
  will-change: transform;
  top: 0;
}

.filterHidden.open {
  transform: translateX(0) !important;
}

.additionaldata {
  display: none;
}

.productInfo h3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 26px;
  margin-top: 0;
  margin-bottom: 0;
  text-transform: capitalize;
  font-family: "Lato", sans-serif;
}

.productInfo .productInfoBrand {
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
}

.productInfo .productInfoPrice {
  padding-top: 10px;
}

.productInfo .productInfoPrice .regularPrice {
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
  font-family: "Lato", sans-serif;
}

.productInfo .availability svg {
  font-size: 13px;
  color: #90EE90;
}

.productInfo .availability span {
  color: #666666;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  padding-left: 3px;
  text-transform: uppercase;
  font-family: "Lato", sans-serif;
}

.productInfo .quantityCart {
  margin-right: 15px;
}

.productInfo .quantityCart .quantityBox {
  width: 90px;
  height: 46px;
  border: 1px solid #ddd;
  padding: 0 15px;
  float: left;
}

.productInfo .quantityCart .quantityBox .qtybtn {
  width: 15px;
  display: block;
  float: left;
  line-height: 40px;
  cursor: pointer;
  text-align: center;
  font-size: 22px;
  color: #666666;
}

.productInfo .quantityCart .quantityBox input {
  width: 28px;
  float: left;
  border: none;
  height: 40px;
  line-height: 40px;
  padding: 0;
  text-align: center;
  background-color: transparent;
}

.productInfo .quantityCart .quantityBox .qtybtn:hover {
  color: #bc9051;
}

.productInfo .addtocartButton .buyBtn {
  color: #fff;
  height: 46px;
  font-weight: 500;
  padding: 14px 26px;
  display: inline-block;
  background-color: #bc9051;
  margin-left: 10px;
  font-family: "Lato", sans-serif;
  cursor: pointer;
}

.productInfo .addtocartButton .buyBtn:hover {
  background-color: #333333;
}

.productInfo .addtocartButton .buyBtn span {
  padding-left: 10px;
}

.productInfo .tagLine {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.productInfo .tagLine h5 {
  color: #333333;
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 0;
  margin-right: 10px;
  text-transform: uppercase;
}

.productInfo .tagLine a {
  color: #666666;
  font-size: 14px;
  margin-right: 10px;
  text-transform: uppercase;
}

.productInfo .tagLine .tagLineDetail {
  color: #666666;
  font-size: 14px;
  margin-right: 10px;
  text-transform: uppercase;
}

.productInfo .likeIcon {
  padding-top: 20px;
  border-top: 1px solid #e5e5e5;
}

.productInfo .likeIcon a.facebook {
  background-color: #3B5999;
}

.productInfo .likeIcon a.twitter {
  background-color: #1DA1F2;
}

.productInfo .likeIcon a.pinterest {
  background-color: #CB2028;
}

.productInfo .likeIcon a {
  color: #fff;
  text-align: center;
  display: inline-block;
  font-size: 12px;
  line-height: 22px;
  padding: 0 8px;
  margin-right: 6px;
  border-radius: 3px;
  text-transform: capitalize;
}

.productInfo .likeIcon a span {
  padding-left: 5px;
}

.productInfo .shareIcon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.productInfo .shareIcon h5 {
  color: #333333;
  font-size: 15px;
  font-weight: 700;
  line-height: 1;
  margin-right: 10px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.productInfo .shareIcon a {
  color: #666666;
  text-align: center;
  display: inline-block;
  font-size: 16px;
  padding: 0 8px;
  margin-right: 5px;
}

.mainImage .slick-list {
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: 10px;
}

.mainImage .slick-list .slick-track img {
  display: inline-block;
}

.thumbnail1pxSolid li {
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-right: 5px;
}

.thumbnail1pxSolid li a img {
  opacity: 0.7;
}

.thumbnail1pxSolid li.slick-active a img {
  opacity: 1;
}

@media (max-width: 767.98px) {
  .thumbnail1pxSolid li a img {
    width: 100px;
    height: 100px;
  }
}
/***********************************************
                    ORDER
***********************************************/
.orderAction {
  position: relative;
  padding: 10px 40px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex-wrap: wrap;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.orderAction .nav {
  white-space: nowrap;
  flex-wrap: nowrap;
  margin-bottom: 0;
  border-bottom: none;
}

.orderAction .orderLink {
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: 1px solid transparent;
  color: rgba(77, 82, 89, 0.7);
  background-color: transparent !important;
}

.orderAction .orderLink.active, .orderAction .orderLink:hover {
  color: #4d5259;
  border-color: #33cabb;
  border-radius: 50px;
}

/***********************************************
                    PROFILE
***********************************************/
.headerAction {
  position: relative;
  padding: 0 30px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex-wrap: wrap;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navLink, .orderLink {
  font-weight: 400;
  color: #8b95a5;
  line-height: 33px;
  padding: 0px 12px;
  white-space: nowrap;
}

.headerAction .nav {
  white-space: nowrap;
  flex-wrap: nowrap;
  margin-bottom: 0;
  border-bottom: none;
}

.headerAction .navLink {
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 0.6rem;
  border-bottom: 3px solid transparent;
  color: rgba(77, 82, 89, 0.7);
  background-color: transparent !important;
}

.headerAction .navLink.active, .headerAction .navLink:hover {
  color: #4d5259;
  border-bottom-color: #33cabb;
}

.tabWrapper {
  margin-bottom: 2.3rem;
  border-radius: 7px;
  border: none;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid #e5e5e5;
  border-radius: 0.25rem;
}

.h4.tabTitle, h4.tabTitle {
  font-size: 17px;
}

.titleWrapper {
  display: flex;
  border-bottom: 1px solid rgba(77, 82, 89, 0.07);
}

.tabTitle {
  font-family: "Work Sans", sans-serif;
  font-weight: 300;
  line-height: 1.5;
  margin-bottom: 0;
  padding: 15px 30px;
}

.tabButton {
  position: absolute;
  top: 10px;
  right: 10px;
}

.minHeightProfileHistory {
  min-height: 380px !important;
}

.address {
  margin-bottom: 1rem;
  text-align: start;
  min-height: 20px;
  padding: 10px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
}

/***********************************************
                   ADMIN
***********************************************/
.adminTopCard {
  border: 0;
  border-radius: 0px;
  margin-bottom: 30px;
  -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.03);
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: #ffffff;
}

.adminTopWrapper {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  max-height: 100%;
  place-content: stretch space-between;
  align-items: stretch;
}

.adminTopInner {
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  max-width: 100%;
  align-items: stretch;
}

.adminTopInner .value {
  font-size: 36px;
  margin-top: 12px;
  margin-bottom: 12px;
}

.adminTopInner .property {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 14px;
}

.adminTopIcon {
  font-size: 25px;
  height: 35px;
  width: 25px !important;
  line-height: 25px !important;
  margin-top: -8px;
}

.adminTopData {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  max-height: 100%;
  place-content: center flex-end;
  align-items: center;
  align-self: flex-end;
}

.pcspacebetween {
  place-content: stretch space-between;
}

.pcflexend {
  place-content: stretch flex-end;
}

.asflexend {
  align-self: flex-end;
}

.adminTopColor1 {
  background: rgba(0, 0, 0, 0) linear-gradient(60deg, rgb(140, 153, 224), rgb(101, 114, 184)) repeat scroll 0% 0%;
}

.adminTopColor2 {
  background: rgba(0, 0, 0, 0) linear-gradient(60deg, rgb(77, 208, 226), rgb(76, 168, 186)) repeat scroll 0% 0%;
}

.adminTopColor3 {
  background: rgba(0, 0, 0, 0) linear-gradient(60deg, rgb(129, 198, 131), rgb(98, 168, 100)) repeat scroll 0% 0%;
}

/***********************************************
                   ADMIN
***********************************************/
/***********************************************
                    MASTER
***********************************************/
.masterContainer {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-transition: margin-left 0.3s ease-out;
  transition: margin-left 0.3s ease-out;
  background: #ccc;
}

.masterContent {
  margin-left: 200px;
  padding: 30px 30px 0;
  -webkit-box-flex: 1;
  flex: 1 0 auto;
  overflow: hidden;
}

.profileContainer {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  min-height: 100vh;
  -webkit-transition: margin-left 0.3s ease-out;
  transition: margin-left 0.3s ease-out;
}

.profileContent {
  padding: 30px 30px 0;
  -webkit-box-flex: 1;
  flex: 1 0 auto;
}

.progress {
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.bgDanger {
  background-color: #f96868 !important;
  color: #fff;
}

.progressBar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #33cabb;
  transition: width 0.6s ease;
}

/***********************************************
                    CUSTOMERS
***********************************************/
.flexbox {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.flexbox > :first-child {
  margin-left: 0;
}

.flexbox > :last-child {
  margin-right: 0;
}

.flexbox > * {
  margin-left: 4px;
  margin-right: 4px;
}

.lookup {
  position: relative;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
}

.lookup::before {
  font-size: 16px;
  position: absolute;
  top: 52%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: rgba(77, 82, 89, 0.4);
  width: 36px;
  text-align: center;
  cursor: text;
}

.lookup > input {
  color: #4d5259 !important;
  border: 1px solid #ebebeb !important;
  border-radius: 18px !important;
  height: 36px !important;
  width: 200px;
  max-width: 100% !important;
  padding-left: 36px !important;
  padding-right: 18px !important;
  font-size: 15px !important;
  font-weight: 300 !important;
  letter-spacing: 0.5px !important;
  outline: none !important;
  -webkit-transition: 0.5s;
  transition: 0.5s !important;
}

@media (max-width: 767.98px) {
  .masterContent {
    margin-left: 0 !important;
  }
}
.pageWrapper .memberpageLeftNav {
  position: fixed;
  top: 56px;
  left: 0;
  bottom: 0;
  width: 200px;
  background-color: #fff;
  white-space: nowrap;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  display: -webkit-box;
  display: flex;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  z-index: 45;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  transition: 0.3s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: transform;
}

.memberpageLeftNav .navigationHeader {
  font-size: 0.6875rem;
  display: block;
  text-transform: uppercase;
  font-weight: 500;
  color: #272723;
  padding: 11px 15px;
}

.memberpageLeftNav .version {
  font-size: 0.8rem;
  display: block;
  color: #272723;
  padding: 11px 15px;
}

.memberpageLeftNav nav ul li a.menuLink {
  color: #213e96;
}

.memberpageLeftNav nav ul .menuItem {
  vertical-align: top;
  -webkit-transition: opacity 0.5s linear;
  transition: opacity 0.5s linear;
}

.memberpageLeftNav nav ul .menuItem a {
  text-decoration: none;
}

.memberpageLeftNav nav ul .menuItem > a:hover {
  background-color: #213e96;
  color: #fff;
  border-right: 4px solid #fff;
}

.memberpageLeftNav nav ul .menuItem > a.active {
  background-color: #213e96;
  color: #fff;
  border-right: 4px solid #fff;
}

.menu {
  list-style: none;
  padding-left: 0;
  margin-bottom: 30px;
  margin-top: 0;
}

.menuItem {
  vertical-align: top;
  -webkit-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

.menuItem a {
  text-decoration: none;
}

.menuItem > a.active {
  background-color: #168970;
  color: #fff;
}

.menuLink {
  position: relative;
  height: 40px;
  padding: 0 12px;
  font-weight: 400;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-transition: 0.2s linear;
  transition: 0.2s linear;
  color: #fff;
}

.pageLeftNav .navigationHeader {
  font-size: 0.6875rem;
  display: block;
  text-transform: uppercase;
  font-weight: 500;
  color: #a1a1a1;
  padding: 11px 15px;
}

.pageWrapper {
  margin: 0;
  width: 100%;
}

.pageWrapper .pageRight {
  margin-left: 260px;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  min-height: 100vh;
  -webkit-transition: margin-left 0.3s ease-out;
  transition: margin-left 0.3s ease-out;
}

.pageWrapper .pageContainer .pageInner .pageRight h2 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.pageWrapper .pageContainer .pageInner .pageRight p {
  padding: 0 15px;
  margin: 0;
}

.pageWrapper .pageContainer .pageInner .pageRight .pageRightWrapper {
  padding: 0 15px 15px 15px;
}

.pageRightWrapper .pageRightInner {
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  width: 100%;
}

@media (max-width: 767.98px) {
  .pageWrapper {
    padding: 0;
  }
  .pageWrapper .pageContainer .pageInner {
    padding: 2rem !important;
    display: table !important;
  }
  .pageWrapper .pageContainer .pageInner .pageLeftNav {
    position: fixed;
    left: 0;
    width: 298px;
    margin-left: 0;
    bottom: 0;
    z-index: 45;
    border-right: 1px solid #2a3e4c;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
  .pageWrapper .pageContainer .pageInner .memberpageLeftNav {
    position: fixed;
    left: 0;
    width: 298px;
    margin-left: 0;
    bottom: 0;
    z-index: 45;
    border-right: 1px solid #2a3e4c;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
  .pageWrapper .pageContainer .pageInner .pageLeftNav .pageLeftNavScroll {
    position: relative;
    overflow: hidden;
    width: auto;
    height: 100%;
  }
  .pageWrapper .pageContainer .pageInner .memberpageLeftNav .pageLeftNavScroll {
    position: relative;
    overflow: hidden;
    width: auto;
    height: 100%;
  }
  .pageWrapper .pageContainer .pageInner .pageRight {
    width: 298px;
    display: block;
  }
  .pageWrapper .pageContainer .pageInner .pageRight .pageRightWrapper {
    padding: 0;
  }
  .pageWrapper .pageContainer .pageInner .pageRight .pageRightWrapper .pageRightInner {
    display: block;
  }
  .pageWrapper .pageContainer .pageInner .pageRight h2 {
    font-size: 18px;
    font-weight: 600;
  }
  .pageWrapper .pageContainer .pageInner .pageRight p {
    padding-bottom: 1rem;
  }
  .innerLeft {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .innerRight {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
/***********************************************
                SIDE DRAWER
***********************************************/
.memberSideDrawerLogo {
  padding: 3px 3px 3px 7px;
}

.memberSideDrawer {
  height: 100%;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 70%;
  max-width: 400px;
  z-index: 1200;
  -webkit-transform: translateX(-110%);
  -moz-transform: translateX(-110%);
  -ms-transform: translateX(-110%);
  transform: translateX(-110%);
  transition: transform 0.2s ease;
  will-change: transform;
}

.memberSideDrawer.open {
  transform: translateX(0);
  overflow-y: auto;
  top: 0%;
  bottom: 0%;
  position: fixed;
}

.memberSideDrawer.open ul {
  max-height: 50vh;
}

.memberSideDrawer ul {
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
}

.memberSideDrawer a, .sideDrawer .icon {
  color: #213e96;
  text-decoration: none;
  font-size: 1.2rem;
}

.memberSideDrawer a:hover {
  color: #404040;
}

.memberSideDrawer a:active {
  color: #ffffff;
}

.memberSideDrawer > ul > li > a {
  border: none;
  display: block;
  position: relative;
  border-radius: 0;
  margin: 0;
  text-align: -webkit-match-parent;
}

.memberSideDrawer > ul > li > a.active {
  background-color: #213e96;
  border-right: 4px solid #fff;
}

.memberSideDrawer > ul > li a.active,
.memberSideDrawer > ul > li a.active .icon {
  color: #ffffff;
}

.memberSideDrawer .itemContent {
  align-items: center;
  display: table-row;
}

.memberSideDrawer .itemIcon {
  padding: 10px 15px;
  width: 55px;
}

.memberSideDrawer .itemInner {
  display: table-cell;
  width: 100%;
  vertical-align: middle;
  white-space: nowrap;
  align-items: center;
  padding: 10px 15px 10px 10px;
}

.memberSideDrawer .iconContent {
  background: #272723;
  box-shadow: none;
  height: 53px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.memberSideDrawer .iconContent > ul {
  flex-direction: row;
  position: relative;
  justify-content: space-between;
}

.memberSideDrawer .iconContent > ul > li {
  float: left;
  position: relative;
  display: block;
}

.memberSideDrawer .iconContent > li:before {
  content: "";
  position: absolute;
  width: 1px;
  right: 0;
  top: 5px;
  bottom: 5px;
  background: -moz-linear-gradient(top, rgba(206, 206, 206, 0) 0%, rgba(206, 206, 206, 0.2) 50%, rgba(206, 206, 206, 0) 100%);
  background: -webkit-linear-gradient(top, rgba(206, 206, 206, 0) 0%, rgba(206, 206, 206, 0.2) 50%, rgba(206, 206, 206, 0) 100%);
  background: linear-gradient(to bottom, rgba(206, 206, 206, 0) 0%, rgba(206, 206, 206, 0.2) 50%, rgba(206, 206, 206, 0) 100%);
  box-sizing: border-box;
}

.memberSideDrawer .iconContent > ul > li > a {
  letter-spacing: 0;
  opacity: 0.9;
  padding: 7px;
  position: relative;
  text-align: center;
  display: block;
}

.memberSideDrawer .iconContent > ul > li > a.active {
  background-color: #181816;
  border-bottom: 4px solid #7266BA;
}

.memberSideDrawer .iconContent > ul > li > a.active > .iconWrapper > .icon {
  color: #ffffff;
}

.memberSideDrawer .iconContent .iconWrapper {
  padding: 9px;
}

.memberSidenavTitle {
  padding: 5px 20px 8px 20px;
  background-color: #fff;
  color: #272723;
  text-transform: uppercase;
  font-weight: 500;
  display: block;
}

/***********************************************
                TOGGLE BUTTON
***********************************************/
.memberToggleButton .toggle_button {
  display: flex;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  padding: 15px;
}

.memberToggleButton .toggle_button:focus,
.memberToggleButton .toggle_button:active,
.memberToggleButton .toggle_button:hover {
  outline: none !important;
}

.memberToggleButton .toggle_button .icon {
  font-size: 26px;
}

.memberToggleButton .toggle_button .icon:focus,
.memberToggleButton .toggle_button .icon:active,
.memberToggleButton .toggle_button .icon:hover {
  outline: none !important;
}

@media (max-width: 1025px) {
  .memberpageLeftNav {
    display: none !important;
  }
}
/***********************************************
                    TABLE
***********************************************/
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  border-collapse: collapse;
}

th {
  text-align: inherit;
}

.table th {
  font-weight: 400;
}

.table td, .table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  border-bottom: 1px solid #ebebeb;
  vertical-align: bottom;
  border-top: 0;
  font-weight: 400;
}

.table tbody th {
  border-top: 1px solid #eceeef;
}

.table tbody tr:hover {
  background-color: #eaeaea;
  cursor: pointer;
}

.pageNumber {
  margin-left: 10px;
}

.doubleButton {
  display: inline-block;
}

/***********************************************
                PAGINATION
***********************************************/
.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
  left: 0;
  right: 0;
  bottom: 0;
  margin-bottom: 20px;
}

.pageItem.disabled .pageLink, .pageItem.disabled .pageLink:focus, .pageItem.disabled .pageLink:hover {
  opacity: 0.6;
}

.pageItem.active .pageLink, .pageItem.active .pageLink:focus, .pageItem.active .pageLink:hover {
  background-color: #33cabb;
  border-color: #33cabb;
  font-weight: 500;
}

.pageItem.active .pageLink {
  z-index: 1;
  color: #fff;
}

.pageItem.disabled .pageLink {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-collapse: #dee2e6;
}

.pageItem:first-child .pageLink {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.pageItem:last-child .pageLink {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pageLink:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.pageLink {
  color: #8b95a5;
  font-weight: 400;
  border: 1px solid #dee2e6;
  padding: 0 8px;
  margin: 0 3px;
  min-width: 31px;
  line-height: 29px;
  text-align: center;
  border-radius: 2px !important;
  position: relative;
  display: block;
}

.inputbutton {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 7px 13px;
  width: 100%;
  height: 42px;
  font-size: 15px;
  line-height: 1.1;
  color: rgba(0, 0, 0, 0.65);
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #cccccc !important;
  border-radius: 4px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
}

.inputbutton:hover {
  border-color: #aeacb4 !important;
}

.inputbutton:focus {
  box-shadow: none !important;
  border-color: #bbbac0 !important;
  background-color: #F8F8F8 !important;
}

.invoiceForm li:hover {
  background-color: #eaeaea;
  cursor: pointer;
}

.invoiceForm .menuItem .menuLink {
  border-top: 1px solid #dee2e6 !important;
}

.invoiceForm .menuItem .menuLink.noBorder {
  border-top: 0px !important;
}

.chooseCustomerButton {
  bottom: 15px;
  left: 1.25rem;
}

.chooseProductButton {
  bottom: 25px;
}

.chooseProductButton {
  bottom: 25px;
}

.confirmInvoiceButton {
  bottom: 17px;
}

.editInvoice {
  bottom: 15px;
}

.react-datepicker-popper {
  z-index: 5 !important;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  width: 1.4rem !important;
  line-height: 1.4rem !important;
}

.address {
  margin: 5px auto;
}

/***********************************************
                    COLLAPSE
***********************************************/
.collapseItemsWrapper {
  cursor: pointer;
}

.collapseItemsWrapper span {
  padding-left: 20px;
}

.collapseItemsWrapper label {
  padding-left: 14px;
}

.collapseTitle > span {
  font-weight: 500;
}

.itemCheckbox, .itemRadio {
  padding: 0 !important;
}

/***********************************************
                OVERLAY FORM
***********************************************/
.overlayForm {
  background: #fafafa;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 70px;
  left: 0;
  right: 0;
  margin: auto;
  width: 90%;
  max-width: 500px;
  z-index: 1120;
  -webkit-transform: translateY(-500%);
  -moz-transform: translateY(-500%);
  -ms-transform: translateY(-500%);
  transform: translateY(-500%);
  transition: transform 0.1s ease;
  will-change: transform;
}

.overlayForm.open {
  transform: translateY(0);
  overflow: hidden;
  position: fixed;
}

.displayNone {
  display: none !important;
}

/***********************************************
            DOUBLE OVERLAY FORM
***********************************************/
.doubleoverlayForm {
  background: #fafafa;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 210px;
  left: 0;
  right: 0;
  margin: auto;
  width: 90%;
  max-width: 240px;
  z-index: 1520;
  -webkit-transform: translateY(-500%);
  -moz-transform: translateY(-500%);
  -ms-transform: translateY(-500%);
  transform: translateY(-500%);
  transition: transform 0.1s ease;
  will-change: transform;
}

.doubleoverlayForm.open {
  transform: translateY(0);
  overflow: hidden;
  position: fixed;
}

.doubledisplayNone {
  display: none !important;
}

/***********************************************
                    BACKDROP
***********************************************/
.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 105;
}

.memberbackdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 850;
}

.dashboardbackdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 900;
}

.doublebackdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1500;
}

/***********************************************
                    GRID
***********************************************/
.leftmin135p {
  left: -135px !important;
}

.top99p {
  top: 99%;
}

.top60 {
  top: 60px;
}

.right0 {
  right: 0px;
}

.right10 {
  right: 10px;
}

.right20 {
  right: 20px;
}

.bottom20 {
  bottom: 20px;
}

.bottom30 {
  bottom: 30px;
}

.dBlock {
  display: block !important;
}

.dFlex {
  display: -ms-flex-box !important;
  display: flex !important;
}

.inlineFlex {
  display: inline-flex;
}

.dinContents {
  display: contents !important;
}

.dinBlock {
  display: inline-block !important;
}

.dinFlex {
  display: inline-flex !important;
}

.flexColumn {
  flex-direction: column !important;
}

.posAbsolute {
  position: absolute !important;
}

.posInherit {
  position: inherit !important;
}

.mxAuto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.mlAuto {
  margin-left: auto !important;
}

.mrAuto {
  margin-right: auto !important;
}

.justifyContentCenter {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justifyContentBetween {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justifyContentFlexEnd {
  -ms-flex-pack: flex-end !important;
  justify-content: flex-end !important;
}

.hAuto {
  height: auto !important;
}

.hFullscreen {
  height: 100vh !important;
}

.wFullscreen {
  width: 100% !important;
}

.minHeightSmallTable {
  min-height: 400px !important;
}

.minHeightProduct {
  min-height: 700px !important;
}

.minHeightCustomer {
  min-height: 600px !important;
}

.minHeightDelivery {
  min-height: 590px !important;
}

.minHeight500px {
  min-height: 500px !important;
}

.minHeight530px {
  min-height: 530px !important;
}

.minHeight600px {
  min-height: 600px !important;
}

.minHeight650px {
  min-height: 650px !important;
}

.minHeight680px {
  min-height: 680px !important;
}

.h17px {
  height: 17px !important;
}

.h20px {
  height: 20px !important;
}

.h100px {
  height: 100px !important;
}

.h244px {
  height: 244px !important;
}

.h300px {
  height: 300px !important;
}

.lh1 {
  line-height: 1 !important;
}

.w35 {
  width: 35% !important;
}

.w45 {
  width: 45% !important;
}

.w100 {
  width: 100% !important;
}

.w18px {
  width: 18px !important;
}

.w30px {
  width: 30px !important;
}

.w190px {
  width: 190px !important;
}

.w200px {
  width: 200px !important;
}

.w230px {
  width: 230px !important;
}

.noMargin {
  margin: 0 !important;
}

.noPadding {
  padding: 0 !important;
}

.m0 {
  margin: 0 !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mb3 {
  margin-bottom: 3px !important;
}

.mb5 {
  margin-bottom: 5px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mb15 {
  margin-bottom: 15px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.mb40 {
  margin-bottom: 40px !important;
}

.mb50 {
  margin-bottom: 50px !important;
}

.mb60 {
  margin-bottom: 60px !important;
}

.margin80 .mb60 {
  margin-bottom: 80px !important;
}

.mtminus40 {
  margin-top: -40px !important;
}

.mtminus25 {
  margin-top: -25px !important;
}

.mtminus27 {
  margin-top: -27px !important;
}

.mt0 {
  margin-top: 0 !important;
}

.mt5 {
  margin-top: 5px !important;
}

.mt8 {
  margin-top: 8px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt15 {
  margin-top: 15px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.mt40 {
  margin-top: 40px !important;
  margin-bottom: 10px !important;
}

.ml260 {
  margin-left: 260px;
}

.ml0 {
  margin-left: 0 !important;
}

.ml5 {
  margin-left: 5px !important;
}

.ml10 {
  margin-left: 10px !important;
}

.ml15 {
  margin-left: 15px !important;
}

.ml18 {
  margin-left: 18px !important;
}

.ml20 {
  margin-left: 20px !important;
}

.ml22 {
  margin-left: 22px !important;
}

.mr1 {
  margin-right: 0.25rem !important;
}

.ml125 {
  margin-left: 1.25rem !important;
}

.mr125 {
  margin-right: 1.25rem !important;
}

.mlHalfRem {
  margin-left: 0.5rem !important;
}

.mb1rem {
  margin-bottom: 1rem !important;
}

.mr1rem {
  margin-right: 1rem !important;
}

.mr5 {
  margin-right: 5px !important;
}

.mr8 {
  margin-right: 8px !important;
}

.mr10 {
  margin-right: 10px !important;
}

.mr20 {
  margin-right: 20px !important;
}

.mr25 {
  margin-right: 25px !important;
}

.mt1rem {
  margin-top: 1rem !important;
}

.mr1rem {
  margin-right: 1rem !important;
}

.lh3 {
  line-height: 3 !important;
}

.pLeft0 {
  padding-left: 0 !important;
}

.pRight0 {
  padding-right: 0 !important;
}

.p125rem {
  padding: 1.25rem !important;
}

.p0 {
  padding: 0 !important;
}

.p2 {
  padding: 2px !important;
}

.p5 {
  padding: 5px !important;
}

.p7 {
  padding: 7px !important;
}

.p8 {
  padding: 8px !important;
}

.p9 {
  padding: 9px !important;
}

.p10 {
  padding: 10px !important;
}

.p12 {
  padding: 12px !important;
}

.p25 {
  padding: 25px !important;
}

.p30 {
  padding: 30px !important;
}

.pr0 {
  padding-right: 0 !important;
}

.pr10 {
  padding-right: 10 !important;
}

.pr15 {
  padding-right: 15 !important;
}

.pl0 {
  padding-left: 0 !important;
}

.pl20 {
  padding-left: 20px !important;
}

.pl35 {
  padding-left: 35px !important;
}

.pb0 {
  padding-bottom: 0 !important;
}

.pb2 {
  padding-bottom: 2px !important;
}

.pb15 {
  padding-bottom: 15px !important;
}

.pb20 {
  padding-bottom: 20px !important;
}

.pb30 {
  padding-bottom: 30px !important;
}

.pt10p {
  padding-top: 10%;
}

.pt5 {
  padding-top: 5px !important;
}

.pt6 {
  padding-top: 6px !important;
}

.pt8 {
  padding-top: 8px;
}

.pt15 {
  padding-top: 15px !important;
}

.pt20 {
  padding-top: 20px !important;
}

.pt50 {
  padding-top: 50px !important;
}

.pt56 {
  padding-top: 56px !important;
}

.pt100 {
  padding-top: 100px !important;
}

.pt125 {
  padding-top: 125px !important;
}

.pt280 {
  padding-top: 280px !important;
}

.pb5 {
  padding-bottom: 5px !important;
}

.pb50 {
  padding-bottom: 50px;
}

.pb100 {
  padding-bottom: 100px !important;
}

.ptb100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.top70 {
  top: 70% !important;
}

.br50 {
  border-radius: 50% !important;
}

.col-md-1 {
  width: 8.3333333%;
}

.col-md-2 {
  width: 16.666666667%;
}

.col-md-3 {
  width: 25%;
}

.col-md-4 {
  width: 33.3333333%;
}

.col-md-5 {
  width: 41.6666667%;
}

.col-md-6 {
  width: 50%;
}

.col-md-7 {
  width: 58.3333333%;
}

.col-md-8 {
  width: 66.6666666%;
}

.col-md-9 {
  width: 75%;
}

.col-md-10 {
  width: 83.3333333%;
}

.col-md-12 {
  width: 100%;
}

.col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
  float: left;
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3,
.col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6,
.col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9,
.col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12,
.col-agmd-4, .col-agmd-6, .col-agmd-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-agmd-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 0 0 33.333333%;
}

.col-agmd-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-agmd-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
}

.container {
  width: 1140px;
  margin: 0 auto;
}

@media (max-width: 767.98px) {
  .hiddenXs {
    display: none !important;
  }
  .container {
    width: 320px;
  }
  .col-xs-1 {
    width: 8.3333333%;
  }
  .col-xs-2 {
    width: 16.666666667%;
  }
  .col-xs-3 {
    width: 25%;
  }
  .col-xs-4 {
    width: 33.3333333%;
  }
  .col-xs-5 {
    width: 41.6666667%;
  }
  .col-xs-6 {
    width: 50%;
  }
  .col-xs-7 {
    width: 58.3333333%;
  }
  .col-xs-8 {
    width: 66.6666666%;
  }
  .col-xs-9 {
    width: 75%;
  }
  .col-xs-10 {
    width: 83.3333333%;
  }
  .col-xs-12 {
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 835px) {
  .container {
    max-width: 750px;
  }
  .col-sm-1 {
    width: 8.3333333%;
  }
  .col-sm-2 {
    width: 16.666666667%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-4 {
    width: 33.3333333%;
  }
  .col-sm-5 {
    width: 41.6666667%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-7 {
    width: 58.3333333%;
  }
  .col-sm-8 {
    width: 66.6666666%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-10 {
    width: 83.3333333%;
  }
  .col-sm-12 {
    width: 100%;
  }
}
@media (min-width: 836px) and (max-width: 1025px) {
  .container {
    max-width: 1000px;
  }
  .col-lg-1 {
    width: 8.3333333%;
  }
  .col-lg-2 {
    width: 16.666666667%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-4 {
    width: 33.3333333%;
  }
  .col-lg-5 {
    width: 41.6666667%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-7 {
    width: 58.3333333%;
  }
  .col-lg-8 {
    width: 66.6666666%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-10 {
    width: 83.3333333%;
  }
  .col-lg-12 {
    width: 100%;
  }
}
/***********************************************
                    BUTTONS
***********************************************/
.btn {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 5px 16px;
  line-height: inherit;
  color: #8b95a5;
  letter-spacing: 1px;
  border: 1px solid transparent;
  border-radius: 2px;
  background-color: #fff;
  border-color: #ebebeb;
  outline: none !important;
  -webkit-transition: 0.15s linear;
  transition: 0.15s linear;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btnGroup, .btnGroupVertical {
  vertical-align: initial;
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.btnGroup > .btnGroup:not(:last-child) > .btn, .btnGroup > .btn:not(:last-child):not(.dropdownToggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btnGroup > .btnGroup:not(:first-child) > .btn, .btnGroup > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btnGroup .btn + .btn, .btnGroup .btn + .btnGroup, .btnGroup .btnGroup + .btn, .btnGroup .btnGroup + .btnGroup, .btnGroupVertical .btn + .btn, .btnGroupVertical .btn + .btnGroup, .btnGroupVertical .btnGroup + .btn, .btnGroupVertical .btnGroup + .btnGroup {
  margin-left: -1px;
}

.btnGroup > .btn:first-child {
  margin-left: 0;
}

.btnGroupSM > .btn, .btnSM {
  font-size: 12px !important;
  padding: 7px 8px;
  line-height: 20px !important;
  border-radius: 0.2rem;
}

.btnGroupVertical > .btn, .btnGroup > .btn {
  position: relative;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
}

.btnXs,
.btnGroupXs > .btn {
  font-size: 11px;
  padding: 9px 8.5px 6px;
}

.btnPrimary {
  background-color: #33cabb;
  border-color: #33cabb;
  color: #fff;
}

.btnToolbar {
  display: -ms-flexbox;
  display: inline-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.divBtn {
  padding: 7px 15px !important;
}

.divBtn2 {
  padding: 7px 19px !important;
}

/***********************************************
            MEMBER LOGIN BUTTONS
***********************************************/
.submitButton {
  width: 100%;
  margin: 16px auto;
  display: block;
}

.submitButtonShadow:not([class*=mat-elevation-z]) {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.submitButtonShadow {
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: 0;
  border: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 16px;
  border-radius: 4px;
  overflow: visible;
  transform: translate3d(0, 0, 0);
  transition: background 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

.submitButtonShadow.blueColor {
  background-color: #213e96;
  color: #fff;
}

.khususaddress .disabled {
  background-color: rgba(188, 144, 81, 0.5) !important;
  border: 1px solid rgba(188, 144, 81, 0.5) !important;
}

.khususcheckout.disabled {
  background-color: rgba(188, 144, 81, 0.5) !important;
  border: 1px solid rgba(188, 144, 81, 0.5) !important;
}

.newbtn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.newbtn {
  border-radius: 0;
  font-size: 13px;
  font-weight: 400;
  color: #bc9051;
  text-transform: uppercase;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: 0.4s;
  -o-translation: 0.4s;
  transition: 0.4s;
  display: inline-block;
  padding: 7px 21px;
  border: 1px solid #bc9051;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 1.5;
}

@media (max-width: 767.98px) {
  .newbtn.checkout {
    font-size: 13px;
    padding: 7px 26px;
  }
}
@media (min-width: 768px) and (max-width: 835px) {
  .newbtn.checkout {
    font-size: 10px;
    padding: 7px 25px;
  }
}
.newbtn:hover {
  background-color: rgba(77, 82, 89, 0.7);
}

.newbtnbg {
  color: #fff;
  background-color: #bc9051;
}

.newbtnaddtocart {
  color: #fff;
  background-color: #bc9051;
  width: 100%;
}

/***********************************************
                    FOOTER
***********************************************/
footer .logo {
  text-align: center;
  font-family: "Monoton", cursive;
  color: #ffffff;
  font-size: 40px;
  padding: 11px 0px;
  border-bottom: 1px solid #4d4d4d;
}

footer .wrapper {
  display: flex;
  color: #828282;
}

footer .left,
footer .right {
  flex: 1;
  padding: 11px;
}

footer .business_info {
  display: flex;
  flex-wrap: wrap;
  padding-top: 11px;
}

footer .business_info .tag {
  flex-grow: 1;
  position: relative;
  width: 50%;
  margin-bottom: 30px;
}

footer .business_info .icon {
  position: absolute;
  font-size: 26px;
  top: 3px;
}

footer .business_info .info {
  padding-left: 40px;
}

.websiteFooter {
  display: inline-block;
  width: 100%;
}

.siteFooter {
  margin-left: 260px;
  background-color: #ffffff;
  font-size: 14px;
  color: #616a78;
  padding: 15px 30px;
  box-shadow: 0 1px 12px rgba(0, 0, 0, 0.1);
}

.siteFooter p {
  margin-bottom: 0;
  font-weight: 400;
}

.profileFooter {
  background-color: #ffffff;
  font-size: 14px;
  color: #616a78;
  padding: 15px 30px;
  box-shadow: 0 1px 12px rgba(0, 0, 0, 0.1);
}

.profileFooter p {
  margin-bottom: 0;
  font-weight: 400;
}

.textMdLeft {
  text-align: left;
}

/***********************************************
                    NEW FOOTER
***********************************************/
.footerpertama img {
  margin-bottom: 25px;
}

.footerpertama p {
  line-height: 32px;
  color: #ffffff;
  font-size: 15px;
}

.footerkedua {
  padding-left: 35px;
}

.footerkedua h3 {
  color: #ffffff;
}

.footerkedua .list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footerkedua .list li:not(:last-child) {
  margin-bottom: 10px;
}

.footerkedua .list li a {
  font-size: 18px;
  color: #ffffff;
  -webkit-transition: all 600ms linear 0s;
  -o-transition: all 600ms linear 0s;
  transition: all 600ms linear 0s;
}

.footersocial h3 {
  color: #ffffff;
}

.footersocial ul {
  padding-top: 5px;
}

.footersocial ul li {
  display: inline-block;
  text-align: center;
  margin-right: 10px;
}

.footersocial ul li a {
  width: 35px;
  height: 35px;
  line-height: 35px;
  font-size: 14px;
  color: #ffffff;
  border-radius: 3px;
  background: #aaaaaa;
  display: inline-block;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.footertext h3 {
  color: #ffffff;
}

.footertext .list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footertext .list li:not(:last-child) {
  margin-bottom: 10px;
}

.footertext .list li a {
  font-size: 18px;
  color: #ffffff;
  -webkit-transition: all 600ms linear 0s;
  -o-transition: all 600ms linear 0s;
  transition: all 600ms linear 0s;
}

.footercopy p {
  margin-bottom: 0;
  font-size: 16px;
  color: #ffffff;
  padding: 20px 0;
}

.AppDrop {
  text-align: center;
  min-height: 25vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.CardDrop {
  background-color: white;
  display: block;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  text-align: -webkit-center;
  text-align: -moz-center;
  width: 100%;
}

.uploadImageWrap {
  width: 100%;
  float: left;
}

.uploadImageWrap img {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  width: 100px;
  height: 100px;
}

.Dropzone {
  height: 100px;
  width: 100px;
  background-color: #fff;
  border: 2px dashed rgb(187, 186, 186);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 20px;
  margin-bottom: 20px;
}

.FileInput {
  display: none;
}

.Highlight {
  background-color: rgb(188, 185, 236);
}

#drop-area {
  border: 2px dashed #ccc;
  border-radius: 20px;
  width: 480px;
  font-family: sans-serif;
  margin: 100px auto;
  padding: 20px;
}

#drop-area.highlight {
  border-color: purple;
}

p {
  margin-top: 0;
}

.my-form {
  margin-bottom: 10px;
}

#gallery {
  margin-top: 10px;
}

#gallery img {
  width: 150px;
  margin-bottom: 10px;
  margin-right: 10px;
  vertical-align: middle;
}

.button {
  display: inline-block;
  padding: 10px;
  background: #ccc;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.button:hover {
  background: #ddd;
}

#fileElem {
  display: none;
}

.cardImageLoading {
  width: 170px;
  height: 172px;
}

.cardPaddingLoading {
  width: 170px;
  height: 109px;
}

.addToCartLoading {
  width: 170px;
  height: 30px;
}

.cardPaddingLoading .nameLoadingWrapper {
  width: 130px;
  height: 50px;
}

.cardPaddingLoading .nameLoading {
  width: 130px;
  height: 15px;
  margin-left: 5px;
}

.cardPaddingLoading .brandLoading {
  width: 130px;
  height: 15px;
  margin: 5px;
}

.cardPaddingLoading .priceLoading {
  width: 130px;
  height: 15px;
  margin: 5px;
}

.title1Loading {
  width: 72px;
  height: 36px;
  float: left;
}

.title2Loading {
  width: 106px;
  height: 36px;
  margin-left: 5px;
  float: left;
}

.loading {
  position: relative;
  background-color: #E2E2E2;
}

.loading::after {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translateX(-30%);
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.4), transparent);
  animation: loading 1.5s infinite ease-in;
}

@keyframes loading {
  100% {
    transform: translateX(50%);
  }
}
@media (max-width: 767.98px) {
  .cardImageLoading {
    width: 160px;
    height: 162px;
  }
  .cardPaddingLoading .nameLoading {
    width: 120px;
    height: 13px;
    margin-left: 5px;
  }
  .cardPaddingLoading .brandLoading {
    width: 130px;
    height: 13px;
    margin: 5px;
  }
  .cardPaddingLoading .priceLoading {
    width: 130px;
    height: 13px;
    margin: 5px;
  }
  .addToCartLoading {
    width: 160px;
    height: 30px;
  }
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #213e96;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #213e96 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.imageLoadingWidth {
  width: 470px;
  height: 470px;
  margin-bottom: 10px;
}

.thumbnailLoadingWidth {
  width: 470px;
  height: 158px;
}

.productDetailTitle {
  width: 260px;
  height: 26px;
  margin-bottom: 5px;
}

.productDetailSubtitle {
  width: 100px;
  height: 18px;
  margin-bottom: 1rem;
}

.productDetailCategory {
  width: 160px;
  height: 18px;
  margin-bottom: 1rem;
}

.productDetailPrice {
  width: 120px;
  height: 27px;
  margin-bottom: 1rem;
}

.productDetailAvailable {
  width: 100px;
  height: 18px;
  margin-bottom: 20px;
}

.productLeftButton {
  width: 90px;
  height: 46px;
}

.productRightButton {
  width: 155px;
  height: 46px;
  margin-left: 10px;
}

.productColor, .productCategory {
  width: 100px;
  height: 18px;
  margin-top: 20px;
}

.productSocial, .productShare {
  width: 260px;
  height: 18px;
  margin-bottom: 1rem;
}

.productLine {
  width: 670px;
  height: 1px;
  margin: 2rem auto;
}

.masterContainer .card .rightSideOption.open {
  transform: translateX(0);
  overflow-y: auto;
  top: 0%;
  bottom: 0%;
  scrollbar-width: thin !important;
}

.masterContainer .card .rightSideOption.open .actionTitle {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  line-height: 1.5;
  margin-bottom: 0;
  padding: 15px 20px;
  float: right;
  min-height: 69px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.masterContainer .card .rightSideOption.open .actionTitle .actionTitleText {
  height: 38px;
  vertical-align: middle;
  display: table-cell;
}

.masterContainer .card .rightSideOption.open .rightSideButtonWrapper {
  padding: 15px 20px;
  display: flex;
  flex-direction: row;
  position: relative;
  min-height: 1px;
  width: 100%;
  float: left;
}

.masterContainer .card .rightSideOption.open .rightSideButtonWrapper .rightSideButton {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 3px;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  min-width: 100px;
  opacity: 0.8;
  background-color: #8FBC8F;
  color: #ffffff;
  border: 1px solid #8FBC8F;
}

.masterContainer .card .rightSideOption.open .rightSideButtonWrapper .rightSideButton:hover {
  background-color: #8FBC8F;
  color: #ffffff;
  border: 1px solid #8FBC8F;
  opacity: 1;
}

.masterContainer .card .rightSideOption.open .rightSideButtonWrapper .rightSideButton .rightSideButtonText {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
}

.masterContainer .card .rightSideOption.open .rightSideButtonWrapper .rightSideButton svg {
  margin-right: 0.5rem;
}

.masterContainer .card .rightSideOption {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 500px;
  background-color: #ffffff;
  white-space: nowrap;
  display: -webkit-box;
  display: flex;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  z-index: 999;
  -webkit-transform: translateX(150%);
  -moz-transform: translateX(150%);
  -ms-transform: translateX(150%);
  transform: translateX(150%);
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  transition: 0.3s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: transform;
}

.masterContainer .card .tablebackdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 995;
  cursor: pointer;
}

@media (max-width: 767.98px) {
  .masterContainer .card .rightSideOption {
    width: 85%;
  }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/***********************************************
                NOTIFICATIONS
***********************************************/
.agranotif {
  color: #FFF;
  cursor: default;
  display: block;
  font-size: 1.3em;
  left: 0;
  opacity: 1;
  padding: 25px 80px;
  position: fixed;
  right: 0;
  text-align: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 9999;
}

.agrabottom {
  bottom: 0;
}

.agratop {
  top: 0;
}

.agradismiss {
  background-color: #333;
  border-radius: 15px;
  box-shadow: inset 2px 2px 7px 2px #000;
  color: #DDD;
  cursor: pointer;
  display: none;
  font-size: 1.25em;
  font-weight: 700;
  height: 30px;
  line-height: 26px;
  opacity: 0.2;
  position: absolute;
  right: 45px;
  text-shadow: 1px 1px 5px #000;
  width: 30px;
  bottom: 20px;
}

.agrasticky .agradismiss {
  display: block;
}

.agrablue {
  background-color: #0e90d2;
}

.notificationWrapper {
  padding: 15px;
  display: block;
}

.notificationWrapper .itemIcon svg {
  font-size: 20px;
  color: #0190fe;
  margin-right: 15px;
}

.toast {
  max-width: 100%;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

.fade {
  transition: opacity 0.15s linear;
}

.toastHeader {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 1rem 1.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toastBody {
  padding: 1rem 1.75rem;
}

.rdtPicker {
  padding: 10px !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4) !important;
}

.rdtPicker th {
  padding: 4px;
}

.rdtPicker td {
  padding: 6px !important;
}

.rdtPicker .rdtMonths td, .rdtPicker .rdtYears td {
  padding: 17px 6px 6px 6px !important;
}

.rdtPicker th.rdtSwitch {
  padding: 6px !important;
}

@media (min-width: 767.99px) and (max-width: 799.98px) {
  .masterContent {
    margin-left: 0 !important;
  }
}
@media (min-width: 799.99px) and (max-width: 835.98px) {
  .masterContent {
    margin-left: 0 !important;
  }
}
@media (min-width: 835.99px) and (max-width: 1025.98px) {
  .masterContent {
    margin-left: 0 !important;
  }
}
/***********************************************
                    GENERAL
***********************************************/
*, ::after, ::before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

::selection {
  background: #58748B;
  text-shadow: none;
  color: #ffffff;
}

.clearfix:before, .clearfix:after, .dl-horizontal dd:before, .dl-horizontal dd:after, .container:before, .container:after, .container-fluid:before, .container-fluid:after, .row:before, .row:after, .form-horizontal .form-group:before, .form-horizontal .form-group:after, .btn-toolbar:before, .btn-toolbar:after, .btn-group-vertical > .btn-group:before, .btn-group-vertical > .btn-group:after, .nav:before, .nav:after, .navbar:before, .navbar:after, .navbar-header:before, .navbar-header:after, .navbar-collapse:before, .navbar-collapse:after, .pager:before, .pager:after, .panel-body:before, .panel-body:after, .modal-footer:before, .modal-footer:after, #topbar:before, #topbar:after {
  content: " ";
  display: table;
}

.clearfix:after, .dl-horizontal dd:after, .container:after, .container-fluid:after, .row:after, .form-horizontal .form-group:after, .btn-toolbar:after, .btn-group-vertical > .btn-group:after, .nav:after, .navbar:after, .navbar-header:after, .navbar-collapse:after, .pager:after, .panel-body:after, .modal-footer:after, #topbar:after {
  clear: both;
}

html, body, img, figure {
  max-width: 100%;
}

html, body {
  overflow-y: auto;
}

img {
  vertical-align: middle;
  border: 0;
  border-style: none;
}

body {
  font-family: "Work Sans", sans-serif;
  background: #222;
}

.container {
  width: 1140px;
  margin: 0 auto;
}

.page_container {
  min-height: 90vh;
  padding-top: 65px;
  display: block;
}

a {
  color: #1E90FF;
  text-decoration: none;
  background-color: transparent;
}

.h1, h1 {
  font-size: 2.5rem;
}

.h3, h3 {
  font-size: 23px;
}

.h4, h4 {
  font-size: 1.5rem;
}

.h5, h5 {
  font-size: 1.2rem;
}

.h6, h6 {
  font-size: 14px;
  font-weight: 400;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
}

b, strong {
  font-weight: bolder;
}

p {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
}

.fw200 {
  font-weight: 200 !important;
}

.fw500 {
  font-weight: 500 !important;
}

.fs10 {
  font-size: 10px !important;
}

.fs12 {
  font-size: 12px !important;
}

.fs14 {
  font-size: 14px !important;
}

.fs16 {
  font-size: 16px !important;
}

.fs17 {
  font-size: 17px !important;
}

.fs20 {
  font-size: 20px !important;
}

.fs28 {
  font-size: 28px !important;
}

.fs40 {
  font-size: 40px !important;
}

.fs170 {
  font-size: 170px !important;
}

.fs200 {
  font-size: 200px;
}

.borderTop1pxSolid {
  border-top: 1px solid;
}

.borderBottom1pxSolid {
  border-bottom: 1px solid;
}

.bg_white {
  background: #ffffff;
}

.bg_light {
  background: #cccccc;
}

.bg_dark {
  background: #272723;
}

.bg_table {
  background: #33cabb;
}

.textBlack {
  color: #212121 !important;
}

.textGray {
  color: #868e96 !important;
}

.textWhiteImportant {
  color: #ffffff !important;
}

.textWhite {
  color: #ffffff;
}

.textDanger {
  color: #DC143C !important;
}

.textSuccess {
  color: #15c377 !important;
}

.textCenter {
  text-align: center !important;
}

.textRight {
  text-align: right !important;
}

.textUppercase {
  text-transform: uppercase !important;
}

input {
  outline: none !important;
  font: inherit;
  color: inherit;
}

button, input {
  box-shadow: none !important;
  overflow: visible;
}

button, select {
  text-transform: none;
}

.small, small {
  font-size: 80%;
  font-weight: 400;
}

input[type=text], input[type=password], input[type=email], textarea {
  appearance: none !important;
}

input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  cursor: pointer;
  line-height: normal;
}

input[type=checkbox], input[type=radio] {
  margin: 4px 0 0;
  padding: 0;
}

[type=reset],
[type=submit],
button,
html [type=button] {
  -webkit-appearance: button;
}

label {
  display: inline-block;
  margin-bottom: 0;
  max-width: 100%;
}

.checkbox, .radio {
  position: relative;
  display: block;
}

.clipCheck {
  margin-bottom: 10px;
  margin-top: 10px;
  padding-left: 0;
}

.mainLoader {
  position: absolute; /* or absolute */
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -20px;
}

.border1pxSolid {
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.border1pxRight {
  border-right: 1px solid rgba(0, 0, 0, 0.125);
}

.borderRight {
  border-right: 1px solid #eaeaea;
}

.textNowrap {
  white-space: nowrap !important;
}

.donut {
  display: inline-block;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 100%;
  border: 4px solid #acb7bf;
  position: relative;
  top: 0.15rem;
  margin-right: 0.3rem;
}

.donutDanger {
  border-color: #DC143C;
}

.textMuted {
  color: #c0bdd0 !important;
}

.cartLink {
  position: absolute;
  background-color: #fe4536;
  color: #fff;
  font-size: 13px;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  font-weight: 500;
  border-radius: 10px;
  line-height: 21px;
  top: 10px;
  left: auto;
  right: -2px;
  padding: 0 0 0 1px;
  height: 20px;
  min-width: 20px;
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  display: inline-block;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.textSecondary {
  color: #e4e7ea !important;
}

.lead {
  font-size: 16px;
  line-height: 32px;
  font-weight: 300;
}

.floatRight {
  float: right !important;
}

.bgBlackOp15 {
  background-color: rgba(0, 0, 0, 0.15);
}

.bgBlackOp20 {
  background-color: rgba(0, 0, 0, 0.2);
}

.opacity60 {
  opacity: 0.6 !important;
}

.opacity70 {
  opacity: 0.7 !important;
}

.opacity80 {
  opacity: 0.8 !important;
}

.opacity90 {
  opacity: 0.9 !important;
}

.loadingContainer {
  display: flex;
  min-height: 70px;
}

.vaInherit {
  vertical-align: inherit !important;
}

.grayBackground {
  background: #2F4F4F !important;
  color: white !important;
  border-color: #2F4F4F !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
}

.greenBackground {
  background: #008000 !important;
  color: white !important;
  border-color: #008000 !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
}

.blueBackground {
  background: #0190fe !important;
  color: white !important;
  border-color: #0190fe !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
}

.redBackground {
  background: #DC143C !important;
  color: white !important;
  border-color: #DC143C !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
}

.salmonBackground {
  background: #E9967A !important;
  color: white !important;
  border-color: #E9967A !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
}

.midnightblueBackground {
  background: #191970 !important;
  color: white !important;
  border-color: #191970 !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
}

.purpleBackground {
  background: #800080 !important;
  color: white !important;
  border-color: #800080 !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
}

.lightblueBackground {
  background: #add8e6 !important;
  color: black !important;
  border-color: #add8e6 !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
}

.border4 {
  border-radius: 4px;
}

.divider {
  border-left: 1px solid rgb(77, 82, 89);
  height: 20px;
  align-self: center;
  margin: 0 12px;
}

.borderbottom {
  border-bottom: 1px solid #868e96 !important;
}

.borderbottomaddress {
  border-bottom: 1px solid #e4e9f0 !important;
}

.disabled {
  pointer-events: none;
  color: rgba(0, 0, 0, 0.3) !important;
}

.disabled2 {
  pointer-events: none;
  color: rgba(0, 0, 0, 0.3) !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
  border-color: rgba(0, 0, 0, 0.3) !important;
}

.slick-initialized .slick-slide {
  outline: none;
}

.cursorpointer {
  cursor: pointer;
}

tr.verticalCenter td {
  vertical-align: inherit !important;
}

/***********************************************
                AUTH CONTAINER
***********************************************/
.auth_container {
  min-height: 90vh;
  display: flex;
}

/***********************************************
                TOGGLE BUTTON
***********************************************/
.toggle_button {
  display: flex;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

.toggle_button:focus, .toggle_button:active, .toggle_button:hover {
  outline: none !important;
}

.toggle_button .icon {
  font-size: 26px;
}

.toggle_button .icon:focus, .toggle_button .icon:active, .toggle_button .icon:hover {
  outline: none !important;
}

/***********************************************
                   FILTER
***********************************************/
.filterDrawer {
  height: 100%;
  background: #ffffff;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 70%;
  max-width: 400px;
  z-index: 200;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
  transition: transform 0.2s ease;
  will-change: transform;
}

.filterDrawer.open {
  transform: translateX(0);
  overflow: hidden;
  position: absolute;
}

/***********************************************
                SIDE DRAWER
***********************************************/
.sideDrawerLogo {
  padding: 3px;
  text-align: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.sideDrawer {
  height: 100%;
  background: #272723;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 70%;
  max-width: 400px;
  z-index: 950;
  -webkit-transform: translateX(-110%);
  -moz-transform: translateX(-110%);
  -ms-transform: translateX(-110%);
  transform: translateX(-110%);
  transition: transform 0.2s ease;
  will-change: transform;
}

.sideDrawer.open {
  transform: translateX(0);
  overflow-y: auto;
  top: 0%;
  bottom: 0%;
  position: fixed;
}

.sideDrawer.open ul {
  max-height: 50vh;
}

.sideDrawer ul {
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
}

.sideDrawer a, .sideDrawer .icon {
  color: #a7b1c2;
  text-decoration: none;
  font-size: 1.2rem;
}

.sideDrawer a:hover,
.sideDrawer a:active {
  color: #ffffff;
}

.sideDrawer > ul > li > a {
  border: none;
  display: block;
  position: relative;
  padding: 5px;
  border-radius: 0;
  margin: 0;
  text-align: -webkit-match-parent;
}

.sideDrawer > ul > li > a.active {
  background-color: #181816;
  border-right: 4px solid #7266BA;
}

.sideDrawer > ul > li a.active,
.sideDrawer > ul > li a.active .icon {
  color: #ffffff;
}

.sideDrawer .itemContent {
  align-items: center;
  display: table-row;
}

.sideDrawer .itemIcon {
  padding: 10px 15px;
  width: 55px;
}

.sideDrawer .itemInner {
  display: table-cell;
  width: 100%;
  vertical-align: middle;
  white-space: nowrap;
  align-items: center;
  padding: 10px 15px 10px 10px;
}

.sideDrawer .iconContent {
  background: #272723;
  box-shadow: none;
  height: 53px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.sideDrawer .iconContent > ul {
  flex-direction: row;
  position: relative;
  justify-content: space-between;
}

.sideDrawer .iconContent > ul > li {
  float: left;
  position: relative;
  display: block;
}

.sideDrawer .iconContent > li:before {
  content: "";
  position: absolute;
  width: 1px;
  right: 0;
  top: 5px;
  bottom: 5px;
  background: -moz-linear-gradient(top, rgba(206, 206, 206, 0) 0%, rgba(206, 206, 206, 0.2) 50%, rgba(206, 206, 206, 0) 100%);
  background: -webkit-linear-gradient(top, rgba(206, 206, 206, 0) 0%, rgba(206, 206, 206, 0.2) 50%, rgba(206, 206, 206, 0) 100%);
  background: linear-gradient(to bottom, rgba(206, 206, 206, 0) 0%, rgba(206, 206, 206, 0.2) 50%, rgba(206, 206, 206, 0) 100%);
  box-sizing: border-box;
}

.sideDrawer .iconContent > ul > li > a {
  letter-spacing: 0;
  opacity: 0.9;
  padding: 7px;
  position: relative;
  text-align: center;
  display: block;
}

.sideDrawer .iconContent > ul > li > a.active {
  background-color: #181816;
  border-bottom: 4px solid #7266BA;
}

.sideDrawer .iconContent > ul > li > a.active > .iconWrapper > .icon {
  color: #ffffff;
}

.sideDrawer .iconContent .iconWrapper {
  padding: 9px;
}

.sidenavTitle {
  padding: 5px 20px;
  background-color: #353535;
  color: #a1a1a1;
  text-transform: uppercase;
  font-weight: 500;
  display: block;
}

/***********************************************
                   DASHBOARD
***********************************************/
.pageWrapper .pageLeftNav {
  position: fixed;
  top: 56px;
  left: 0;
  bottom: 0;
  width: 260px;
  background-color: #272723;
  white-space: nowrap;
  -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  display: -webkit-box;
  display: flex;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  z-index: 45;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  transition: 0.3s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: transform;
}

.sidebarNavigation {
  position: relative;
  overflow: hidden;
  -webkit-box-flex: 1;
  flex: 1 1;
}

.agContainer {
  -ms-touch-action: auto;
  touch-action: auto;
  overflow: hidden !important;
  -ms-overflow-style: none;
}

.menu {
  list-style: none;
  padding-left: 0;
  margin-bottom: 30px;
  margin-top: 0;
}

.menuItem {
  vertical-align: top;
  -webkit-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

.menuItem a {
  text-decoration: none;
}

.menuItem > a.active {
  background-color: #168970;
  color: #fff;
}

.menuLink {
  position: relative;
  height: 40px;
  padding: 0 12px;
  font-weight: 400;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-transition: 0.2s linear;
  transition: 0.2s linear;
}

.pageLeftNav .navigationHeader {
  font-size: 0.6875rem;
  display: block;
  text-transform: uppercase;
  font-weight: 500;
  color: #a1a1a1;
  padding: 11px 15px;
}

.innerWrapper {
  padding-right: 1.15rem;
  position: relative;
  width: 100%;
  min-height: 1px;
}

.innerLeft {
  padding-right: 1.15rem;
  position: relative;
  width: 100%;
  min-height: 1px;
}

.innerLeft .profileHeader {
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.15);
}

.innerRight {
  padding-left: 1.15rem;
  padding-right: 1.15rem;
  position: relative;
  min-height: 1px;
  width: 100%;
}

.profileInfo, .profileSocialCounts {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
}

.masterContent .card.profileInfo {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1.53rem;
  padding-bottom: 1.15rem;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.03);
}

.profileContent .card.profileInfo {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1.53rem;
  padding-bottom: 1.15rem;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.03);
}

.profileInfo .profileSocialCounts {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  color: #514d6a;
}

.avatar {
  width: 2.69rem;
  height: 2.69rem;
  background-color: #eef0f4;
  border-color: #b8beca;
  display: inline-block;
  overflow: hidden;
  border-radius: 500px;
}

.avatarNew {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  border-radius: 100%;
  background-color: #f5f6f7;
  color: #8b95a5;
  text-transform: uppercase;
}

.avatarBordered {
  border: 4px solid rgba(255, 255, 255, 0.5);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

.avatarXl {
  width: 64px;
  height: 64px;
  line-height: 64px;
  font-size: 1.25rem;
}

.avatar110 {
  width: 6rem;
  height: 6rem;
}

.avatarBorder {
  border: 4px solid #fff;
}

.avatar img {
  width: 100%;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  border-style: none;
}

/***********************************************
                    FORM
***********************************************/
.formWrapper {
  font-size: 15px;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.65);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  display: inline-block;
  width: 100%;
}

.mobileIconPosition {
  position: inherit;
  top: 100%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  line-height: 0;
  color: rgba(0, 0, 0, 0.25);
  z-index: 3;
  margin-left: 10px;
  text-align: center;
  right: 0 !important;
  cursor: pointer;
}

.iconPosition {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  line-height: 0;
  color: rgba(0, 0, 0, 0.25);
  z-index: 3;
  margin-left: 10px;
  cursor: pointer;
}

.agraicon {
  display: inline-block;
  font-style: normal;
  vertical-align: -0.125em;
  text-align: center;
  text-transform: none;
  line-height: 1;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.formGroup {
  display: block;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 40px;
  position: relative;
}

.formGroup .controlLabel {
  font-weight: normal;
  color: #858585;
  display: inline-block;
  max-width: 100%;
  margin-left: 1px;
  margin-bottom: 10px;
  position: relative;
}

.formGroup .formGroupWrapper {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: unset;
  cursor: pointer;
  display: inline-block;
}

.formGroup .formGroupWrapper .checkboxWrapper {
  margin: 0;
  padding: 0;
  list-style: none;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  display: inline-block;
  line-height: 1;
  position: relative;
  vertical-align: middle;
  top: -0.09em;
}

.formActions {
  border-top: 1px solid #e4e9f0;
  padding-top: 1.9rem;
  margin: 1.53rem 0 0.5rem 0;
}

.loginLink {
  display: inline-flex;
  margin: 6px;
}

.loginLink .myButton {
  padding: 0 6px;
}

.loginLink .linkText {
  margin-left: 6px;
  margin-right: 6px;
  display: contents;
}

.registerLink {
  display: inline-flex;
  margin-bottom: 0.5rem;
}

.registerLink .myButton {
  padding: 0 6px 0 0;
}

.registerLink .linkText {
  margin-left: 6px;
  margin-right: 6px;
  display: contents;
}

.form-control {
  padding-left: 32px !important;
}

.form-control::-moz-placeholder {
  color: #9b9b9f;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #9b9b9f;
  opacity: 1;
}

.form-control::-webkit-input-placeholder {
  color: #9b9b9f;
  opacity: 1;
}

textarea, input[type=text], input[type=password], input[type=datetime], input[type=daterange], input[type=datetime-local], input[type=date], input[type=month], input[type=time], input[type=week], input[type=number], input[type=email], input[type=url], input[type=search], input[type=tel], input[type=color] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  display: inline-block;
  padding: 7px 13px;
  width: 100%;
  height: 42px;
  font-size: 15px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #cccccc !important;
  border-radius: 4px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

textarea:hover, input[type=text]:hover, input[type=password]:hover, input[type=datetime]:hover, input[type=daterange]:hover, input[type=datetime-local]:hover, input[type=date]:hover, input[type=month]:hover, input[type=time]:hover, input[type=week]:hover, input[type=number]:hover, input[type=email]:hover, input[type=url]:hover, input[type=search]:hover, input[type=tel]:hover, input[type=color]:hover {
  border-color: #aeacb4 !important;
}

textarea:focus, input[type=text]:focus, input[type=password]:focus, input[type=datetime]:focus, input[type=daterange]:focus, input[type=datetime-local]:focus, input[type=date]:focus, input[type=month]:focus, input[type=time]:focus, input[type=week]:focus, input[type=number]:focus, input[type=email]:focus, input[type=url]:focus, input[type=search]:focus, input[type=tel]:focus, input[type=color]:focus {
  box-shadow: none !important;
  border-color: #bbbac0 !important;
  background-color: #F8F8F8 !important;
}

select {
  background-color: #FFFFFF !important;
  background-image: none !important;
  border: 1px solid #e4e9f0;
  border-radius: 0;
  color: rgba(0, 0, 0, 0.65) !important;
  font-family: inherit !important;
  font-size: 14px !important;
  line-height: 1.2 !important;
  padding: 5px 4px !important;
  box-shadow: none !important;
  width: 100%;
  height: 32px;
}

select:hover {
  border-color: #aeacb4 !important;
}

select:focus {
  box-shadow: none !important;
  border-color: #bbbac0 !important;
  background-color: #F8F8F8 !important;
}

.hasError {
  border-color: #DC143C !important;
}

.errorLabel {
  position: absolute;
  font-size: 14px;
  color: #DC143C;
  margin: 3px;
}

.successLabel {
  position: relative;
  font-size: 14px;
  color: #8FBC8F;
  margin: 3px;
}

.closeRed {
  color: #DC143C;
}

/***********************************************
                    HOME
***********************************************/
.featuredContainer {
  width: 100%;
  float: left;
}

.featuredContainer .featuredRow {
  margin: 0 auto;
  max-width: 1200px;
}

.featuredContainer .featuredSlider {
  margin: 0 auto;
}

.featuredBg {
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.featuredBgImage {
  height: 650px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.featuredBgImage .contentInner {
  padding: 0 80px;
}

.featuredImage {
  width: 570px;
  height: 570px;
  position: relative;
  background-size: cover !important;
  background-position: center left !important;
}

.featuredAction {
  position: absolute;
  top: 65%;
  left: 8%;
}

.featuredAction .tag {
  text-transform: uppercase;
  display: table;
}

.featuredAction .tag.title {
  font-size: 35px;
  font-weight: 700;
}

.featuredAction .tag.lowTitle {
  font-size: 25px;
  font-weight: 300;
}

/***********************************************
                   TRISULA
***********************************************/
.trisula {
  color: #272723;
  background-color: rgba(0, 0, 0, 0.2);
  height: 80px;
  position: relative;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.trisula a {
  text-decoration: none;
  color: inherit;
}

.trisulaInner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  padding: 56px 0 56px;
  position: relative;
}

.trisulaBorder {
  border-bottom: 1px solid #e5e5e5;
}

.trisulaFeatures {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-right: 130px;
}

.trisulaBorder a .trisulaFeatures {
  border-right: 1px solid #eaeaea;
}

.trisulaBorder a:last-child .trisulaFeatures {
  padding-right: 0;
  border-right: none;
}

.trisulaBorder .featuresIcon {
  font-size: 34px;
  padding-right: 20px;
}

.trisulaFeatures .featuresContent h5 {
  color: #333333;
  font-size: 16px;
  line-height: 1.2;
  font-weight: 400;
  padding-bottom: 0;
  margin-bottom: 0;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
}

.trisulaFeatures .featuresContent p {
  color: #333333;
  font-size: 15px;
  line-height: 1.5;
  margin-bottom: 0;
}

.trisulaOhid {
  overflow: hidden;
  z-index: 1;
  margin-top: 50px;
}

.trisulaLink {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: block;
  z-index: 3;
}

.trisulaCaptionLeft {
  right: auto;
  left: 5%;
}

.trisulaCaptionLeft, .trisulaCaptionRight {
  top: 55%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.trisulaCaptionLeft, .trisulaCaptionRight {
  z-index: 2;
  width: 70%;
  position: absolute;
}

.trisulaImg {
  -webkit-filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.2));
  position: absolute;
  width: 120px;
  right: 1%;
  top: 5%;
  z-index: 5;
}

.trisulaTitle {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  display: table;
}

p.trisulaDesc {
  margin-top: 0;
  margin-bottom: 5px;
}

/***********************************************
                   BANNER
***********************************************/
.banner {
  color: #272723;
  background-color: #ffffff;
  height: 171.5px;
  position: relative;
  -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.banner a {
  text-decoration: none;
  color: inherit;
}

.bannerOhid {
  overflow: hidden;
  z-index: 1;
  margin-bottom: 27px;
}

.bannerOhid:last-child {
  margin-bottom: 0;
}

.bannerLink {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: block;
  z-index: 3;
}

.bannerCaptionLeft {
  right: auto;
  left: 5%;
}

.bannerCaptionLeft, .bannerCaptionRight {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.bannerCaptionLeft, .bannerCaptionRight {
  z-index: 2;
  width: 70%;
  position: absolute;
}

.bannerImg {
  -webkit-filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.2));
  position: absolute;
  width: 150px;
  right: 0%;
  top: 7%;
}

.bannerTitle {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  display: table;
}

p.bannerDesc {
  margin-top: 0;
  margin-bottom: 0;
}

p.bannerDesc2 {
  margin-top: 0;
}

/***********************************************
                   BUTTON
***********************************************/
.agrapanaBtn, .agrapanaBtn:active, .agrapanaBtn:focus {
  outline: 0;
}

.agrapanaBtn::before {
  position: absolute;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  background: #ffffff;
  opacity: 0.35;
  content: "";
  border-radius: inherit;
  z-index: 1;
  -webkit-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s;
  pointer-events: none;
  display: none;
}

.agrapanaBtn {
  line-height: 1;
  display: initial;
  font-weight: 400;
  text-align: center;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 5px 15px;
  font-size: 14px;
  border-radius: 4px;
  height: 34px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all 0.3s cubic cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.3s cubic cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic cubic-bezier(0.645, 0.045, 0.355, 1);
  position: relative;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  color: rgba(0, 0, 0, 0.65);
  background-color: #ffffff;
  border-color: #e4e9f0;
  text-decoration: none;
}

.agrapanaBtn > span {
  pointer-events: none;
}

.agrapanaBtnPrimary {
  color: #ffffff !important;
  background-color: #0190fe;
  border-color: #0190fe;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
}

.agrapanaBtn.purpleBackground.p8.mr5.br50,
.agrapanaBtn.greenBackground.p8.mr5.br50,
.agrapanaBtn.lightblueBackground.p8.mr5.br50,
.agrapanaBtn.redBackground.p8.mr5.br50 {
  width: 20px;
  height: 20px;
}

.btnGroup {
  position: relative;
  display: inline-block;
}

.btnGroup > .agraBtn:first-child:not(:last-child) {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.btnGroup > .agraBtn:last-child {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.btnGroup > .agraBtn:first-child {
  margin-left: 0;
}

.btnGroup .agraBtn {
  border-radius: 0;
}

.btnGroup > .agraBtn {
  position: relative;
  line-height: 30px;
}

.agraBtn, .agraBtn:active, .agraBtn:focus {
  outline: 0;
}

.agraBtn {
  line-height: 1.5;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 1px 23px 0 23px;
  font-size: 13px;
  border-radius: 4px;
  height: 32px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all 0.3s cubic cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 3s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: relative;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  color: rgba(0, 0, 0, 0.65);
  background-color: #ffffff;
  border-color: #e4e9f0;
}

.agraBtn > span {
  pointer-events: none;
}

.tableResponsiveSm #customerTable tr td:first-child {
  max-width: 52px;
  width: 52px;
}

.tableResponsiveSm #customerTable tr td.customerTableId {
  max-width: 220px;
  width: 220px;
}

.tableResponsiveSm #customerTable tr td.customerTableName {
  max-width: 325px;
  width: 325px;
}

.tableResponsiveSm #productTable tr td:first-child {
  max-width: 65px;
  width: 65px;
}

.tableResponsiveSm #productTable tr td.productTableName {
  max-width: 520px;
  width: 520px;
}

.tableResponsiveSm #productTable tr td.productTableId,
.tableResponsiveSm #productTable tr td.productTableCategories,
.tableResponsiveSm #productTable tr td.productTablePrice {
  max-width: 200px;
  width: 200px;
}

.tableResponsiveSm #productTable tr td.productTableQuantity {
  max-width: 100px;
  width: 100px;
}

/***********************************************
                    MEDIA
***********************************************/
@media (max-width: 575.98px) {
  .tableResponsiveSm {
    display: block;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .tableResponsiveSm > .table > tbody > tr > td, .tableResponsiveSm > .table > tbody > tr > th, .tableResponsiveSm > .table > tfoot > tr > td, .tableResponsiveSm > .table > tfoot > tr > th, .tableResponsiveSm > .table > thead > tr > td, .tableResponsiveSm > .table > thead > tr > th {
    white-space: nowrap;
  }
}
@media (max-width: 767.98px) {
  .zoid-outlet {
    width: 270px !important;
  }
  .footercopy {
    padding-bottom: 30px;
  }
  .footersocial ul li {
    margin-right: 3px;
  }
  .footertext .list li a {
    font-size: 13px;
  }
  .footerpertama img, .footerkedua .mb30, .footertext .mb30, .footersocial .mb30 {
    margin-bottom: 0 !important;
  }
  .footerkedua {
    padding-left: 0;
  }
  .trisulaBorder a .trisulaFeatures {
    border-right: none;
  }
  .trisulaBorder a:last-child .trisulaFeatures {
    padding: 0 15px;
    border-right: none;
  }
  .trisulaFeatures {
    display: block;
    padding: 0 15px;
    margin-bottom: 30px;
  }
  .trisulaFeatures .featuresIcon {
    margin-bottom: 10px;
  }
  .trisulaInner {
    display: block;
    padding: 47px 0 20px;
    text-align: center;
  }
  .page_container {
    min-height: 70vh;
  }
  .flexbox {
    display: block !important;
  }
  .centerXs {
    text-align: center !important;
  }
  .btnToolbar {
    margin-top: 10px;
  }
  .siteFooter {
    margin-left: 0 !important;
  }
  .hFullscreen {
    height: 70vh !important;
  }
  .col-agxs-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
  }
  .xsNoPadding {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .col-xs-12 {
    width: 100% !important;
  }
  .col-xs-6 {
    width: 50% !important;
    padding: 5px !important;
  }
  .ml35 {
    margin-left: 35px !important;
  }
  .mt15xs {
    margin-top: 15px !important;
  }
  .bannerOhid {
    margin-bottom: 10px !important;
  }
  .container {
    width: 320px;
  }
  .mobileDisplayNone {
    display: none;
  }
  .logo .logo_img.mobileHidden {
    display: none;
  }
  footer .wrapper {
    display: block;
  }
  footer .business_info .tag {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
  }
  .pageLeftNav {
    display: none !important;
  }
  .memberpageLeftNav {
    display: none !important;
  }
  .pageRight {
    margin-left: 0 !important;
  }
  header.ml260 {
    margin-left: 0 !important;
  }
  .masterContent {
    margin-left: 0 !important;
  }
  .profileContent {
    margin-left: 0 !important;
  }
  .pageWrapper {
    padding: 0;
  }
  .pageWrapper .pageContainer .pageInner {
    padding: 2rem !important;
    display: table !important;
  }
  .pageWrapper .pageContainer .pageInner .pageLeftNav {
    position: fixed;
    left: 0;
    width: 298px;
    margin-left: 0;
    bottom: 0;
    z-index: 45;
    border-right: 1px solid #2a3e4c;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
  .pageWrapper .pageContainer .pageInner .memberpageLeftNav {
    position: fixed;
    left: 0;
    width: 298px;
    margin-left: 0;
    bottom: 0;
    z-index: 45;
    border-right: 1px solid #2a3e4c;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
  .pageWrapper .pageContainer .pageInner .pageLeftNav .pageLeftNavScroll {
    position: relative;
    overflow: hidden;
    width: auto;
    height: 100%;
  }
  .pageWrapper .pageContainer .pageInner .memberpageLeftNav .pageLeftNavScroll {
    position: relative;
    overflow: hidden;
    width: auto;
    height: 100%;
  }
  .pageWrapper .pageContainer .pageInner .pageRight {
    width: 298px;
    display: block;
  }
  .pageWrapper .pageContainer .pageInner .pageRight .pageRightWrapper {
    padding: 0;
  }
  .pageWrapper .pageContainer .pageInner .pageRight .pageRightWrapper .pageRightInner {
    display: block;
  }
  .pageWrapper .pageContainer .pageInner .pageRight h2 {
    font-size: 18px;
    font-weight: 600;
  }
  .pageWrapper .pageContainer .pageInner .pageRight p {
    padding-bottom: 1rem;
  }
  .innerLeft {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .innerRight {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .loginContainer {
    min-height: 95vh !important;
  }
  .loginWrapper .loginContainer .loginInner {
    margin: 0 30px;
    padding: 2rem;
  }
  .formGroup {
    margin-bottom: 20px;
  }
  .formGroup .controlLabel {
    font-size: 14px;
    margin-left: 1px;
    margin-bottom: 3px;
  }
  .formActions .registerLink {
    display: block;
    margin: 10px 0 0 0;
  }
  .registerLink .myButton {
    padding-left: 0 !important;
    margin-bottom: 5px;
    display: inline-flex;
  }
  .registerLink .linkText {
    margin: 0 !important;
    font-size: 15px;
  }
  .featuredContainer .featuredRow {
    margin: 0 auto;
    max-width: 340px;
  }
  .featuredRow .featuredImage {
    display: block;
    max-width: 100%;
    width: 100% !important;
    height: 100% !important;
    margin-top: 10px;
    background-size: 100% !important;
  }
  .featured_action {
    height: 320px !important;
  }
  .featuredAction .tag.title {
    font-size: 25px;
    font-weight: 700;
  }
  .featuredAction .tag.lowTitle {
    font-size: 15px;
    font-weight: 300;
  }
  .hiddenXs {
    display: none !important;
  }
  .cardItemWrapper {
    margin-bottom: 0;
  }
  .cardItemWrapper .addToCart {
    margin-left: 0 !important;
  }
  .cardItemWrapper .tags .price, .cardItemWrapper .tags .brand {
    font-size: 13px;
  }
  .trisulaOhid {
    margin-bottom: 25px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .zoid-outlet {
    width: 314px !important;
  }
  .trisulaFeatures .featuresIcon {
    padding-right: 0;
    margin-bottom: 10px;
  }
  .hiddenSm {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .innerWrapper {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .pageRightWrapper .pageRightInner .innerLeft {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .pageRightWrapper .pageRightInner .innerRight {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
}
@media (min-width: 850px) {
  .toggleButton {
    display: none;
  }
}
@media (min-width: 768px) {
  header .logo {
    margin-left: 0;
  }
  .logo .logo_img.pcHidden {
    display: none;
  }
  .sideDrawer {
    display: none;
  }
}

