@media (max-width: 767.98px) {
    
}
@media (min-width: 767.99px) and (max-width: 799.98px) {
    .masterContent {
        margin-left: 0 !important;
    }
}
@media (min-width: 799.99px) and (max-width: 835.98px) {
    .masterContent {
        margin-left: 0 !important;
    }
}
@media (min-width: 835.99px) and (max-width: 1025.98px){
    .masterContent {
        margin-left: 0 !important;
    }
}