// .table {width:100%;margin-bottom:1rem;background-color:transparent}
// .table td,.table th{padding:.75rem;vertical-align:top;border-top:1px solid #dee2e6}
// .table thead th{vertical-align:bottom;border-bottom:2px solid #dee2e6}
// .table tbody+tbody{border-top:2px solid #dee2e6}
// .table .table{background-color:#fff}

.cartTable .table {
    margin: 0;
    white-space: nowrap;
}
.cartTable .tableBordered {
    border: 1px solid #dee2e6;
}
.cartTable .tableBordered td,.tableBordered th{border:1px solid #dee2e6}
.cartTable .tableBordered thead td,.tableBordered thead th{border-bottom-width:2px}
.cartTable .table thead {
    background-color: #bc9051;
}
.cartTable .table thead tr th {
    border-color: #dee2e6;
    border-bottom: 0 solid transparent;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    padding: 15px 20px;
    text-align: center;
    text-transform: uppercase;
}

.myaccountHistory.cartTable .table tbody tr td {
    border-color: #ccc;
    text-align: center;
    padding: 10px 20px;
    vertical-align: middle;
    font-weight: 400;
    color: #333333;
    background-color: white;
}

.cartTable .table tbody tr td {
    border-color: #ccc;
    text-align: center;
    padding: 10px 20px;
    vertical-align: middle;
    font-weight: 600;
    color: #333333;
}

.cartTable .table tbody tr td a {
    color: #333333;
    font-weight: 500;
    text-transform: capitalize;
}

.cartTable .table tbody tr td a:hover {
    color: #bc9051;
}

.cartTable .table tbody tr td a.btn {
    color: #fff;
}

.cartTable .table tbody tr td a.btn:hover {
    color: #fff;
}

.cartTable .table tbody tr td a.check-btn {
    color: #fff;
}

.cartTable .table tr .proThumbnails,
.cartTable .table tr .proPrice,
.cartTable .table tr .proQuantity,
.cartTable .table tr .proSubtotal,
.cartTable .table tr .proRemove {
    width: 140px;
}
.cartTable .table tbody tr .proThumbnails {
    padding: 10px !important;
}
.cartTable .table tbody tr .proTitle {
    text-align: start !important;
}
.cartTable .table tbody tr .proTitle a {
    font-size: 17px;
}
.cartTable .table tbody tr .proTitle p {
    font-weight: 400;
    font-size: 14px;
}
.cartTable .table tbody tr .proPrice,
.cartTable .table tbody tr .proSubtotal {
    text-align: end !important;
}

.cartTable .table tr .quantityBox {
    width: 90px;
    height: 40px;
    border: 1px solid #ddd;
    padding: 0 15px;
    float: left;
}

.cartTable .table tr .quantityBox .qtybtn {
    width: 15px;
    display: block;
    float: left;
    line-height: 38px;
    cursor: pointer;
    text-align: center;
    font-size: 22px;
    font-weight: 400;
    color: #666666;
}

.cartTable .table tr .quantityBox input {
    width: 28px;
    float: left;
    border: none;
    height: 40px;
    line-height: 34px;
    padding: 0;
    text-align: center;
    background-color: transparent;
}

.cartTable .cartIcon {
    min-height: 400px;
}

.cartTable .cartIcon .icon {
    font-size: 250px;
    color: #3bb75e;
}

.cartTable .cartIcon .successText{
    color: #3bb75e;
    font-size: 25px;
}
.cartUpdateLogin,
.cartUpdateOption {
    border: 1px solid #ccc;
    margin-top: 15px;
    padding: 15px 20px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

@media only screen and (min-width: 1200px), only screen and (min-width: 992px) and (max-width: 1199.98px) {
    .cartUpdateOption {
        margin-top: 0;
        border-top: 0 solid transparent;
    }
    .cartUpdateLogin {
        margin-top: 0;
    }
}

.cartUpdateOption .applyCouponWrapper,
.cartUpdateLogin .applyCouponWrapper{
    width: 55%;
}

@media only screen and (max-width: 767.98px), only screen and (min-width: 768px) and (max-width: 991.98px) {
    .cartUpdateOption .applyCouponWrapper, .cartUpdateLogin .applyCouponWrapper{
        width: auto;
    }
}

.cartUpdateOption .applyCouponWrapper form,
.cartUpdateLogin .applyCouponWrapper form,
.cartUpdateLogin .cartUpdate form {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    white-space: nowrap;
}

.cartUpdateOption .applyCouponWrapper form input,
.cartUpdateLogin .applyCouponWrapper form input,
.cartUpdateLogin .cartUpdate form input {
    border: 1px solid #ccc;
    font-size: 14px;
    padding: 6px 10px;
    outline: none;
    margin-right: 15px;
    width: 100%;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

.cartUpdateOption .applyCouponWrapper form input:focus, .cartUpdateOption .applyCouponWrapper form input:active,
.cartUpdateLogin .applyCouponWrapper form input:focus, .cartUpdateLogin .applyCouponWrapper form input:active,
.cartUpdateLogin .cartUpdate form input:focus, .cartUpdateLogin .cartUpdate form input:active {
    border-color: #bc9051;
}

@media only screen and (max-width: 767.98px) {
    .cartUpdateOption .applyCouponWrapper button,
    .cartUpdateLogin .applyCouponWrapper button,
    .cartUpdateLogin .cartUpdate button {
        display: block;
        width: 100%;
        margin-top: 15px;
        border-radius: 0;
    }
    .cartUpdateLogin .applyCouponWrapper button {
        margin-bottom: 25px;
    }
    .cartUpdateLogin .w35 {
        width: 100% !important;
    }
    .cartUpdateLogin .applyCouponWrapper form input,
    .cartUpdateLogin .cartUpdate form input {
        width: 100%;
        display: block;
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 767.98px) {
    .cartUpdateOption .cartUpdate .btn,
    .cartUpdateLogin .cartUpdate .btn {
        width: 100%;
    }
    .proThumbnails .cartThumbnail {
        height: 103.6px !important;
    }
}
@media (min-width: 768px) and (max-width: 799px) {
    .proThumbnails .cartThumbnail {
        height: 103.6px !important;
    }
}
@media (min-width: 800px) and (max-width: 835.98px) {
    .proThumbnails .cartThumbnail {
        height: 105.7px !important;
    }
}

.checkoutBillingDetailsWrapper h2,
.orderSummaryDetails h2 {
    color: #333333;
    font-size: 20px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 15px;
    margin-bottom: 30px;
}

.checkoutname {
    font-size: 20px;
    font-family: 'Lato', sans-serif;
}
.checkoutBillingDetailsWrapper .billingFormWrapper {
    margin-top: -20px;
}
.singleInputItem {
    margin-top: 20px;
    margin-bottom: 5px;
}
.singleInputItem label {
    margin-bottom: .5rem;
}
.singleInputItem label.required::after {
    content: '*';
    color: red;
    font-size: 14px;
    margin-left: 3px;
    margin-top: 5px;
}
.singleInputItem input, .singleInputItem textarea {
    color: #666666 !important;
    border: 1px solid #ccc !important;
    padding: 15px 10px !important;
    width: 100% !important;
    font-size: 14px !important;
    height: 50px !important;
    background-color: #f7f7f7 !important;
    border-radius: 0px !important;
}
.checkoutBoxWrapper .customControl {
    padding-left: 0;
    // margin-left: 1.5rem;
}
.customControl {
    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
}
.customControlInput {
    opacity: 0;
    position: absolute;
    margin-left: -20px;
}

.customControlLabel::after {
    // position: absolute;
    // top: .25rem;
    // left: -1.5rem;
    display: flex;
    margin-right: 1.5rem;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
}
.customCheckbox .customControlLabel::before {
    border-radius: .25rem;
}
.customControlLabel::before {
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
.customControlLabel::before {
    // position: absolute;
    // top: .25rem;
    // left: -1.5rem;
    display: flex;
    margin-right: 1.5rem;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #dee2e6;
}
@media (max-width: 767.98px) {
    .customControlLabel::before {
        margin-right: 9px;
    }
    .profilecheckoutcheck .customCheckbox input ~ svg,
    .customCheckbox input ~ svg {
        bottom: 10px !important;
    }
    .customCheckbox label::before, .customCheckbox .customControlLabel::before {
        width: 34px !important;
    }
}
@media (min-width: 768px) and (max-width: 835px) {
    .customCheckbox label::before, .customCheckbox .customControlLabel::before {
        width: 26px !important;
    }
    .profilecheckoutcheck .customCheckbox input ~ svg,
    .customCheckbox input ~ svg {
        bottom: 10px !important;
    }
}
@media (min-width: 836px) and (max-width: 1025px){
    .customCheckbox label::before, .customCheckbox .customControlLabel::before {
        width: 19px !important;
    }
    .profilecheckoutcheck .customCheckbox input ~ svg,
    .customCheckbox input ~ svg {
        bottom: 10px !important;
    }
}
.customCheckbox label, .customCheckbox .customControlLabel,
.customRadio label, .customRadio .customControlLabel {
    color: #333333;
    line-height: 1;
    cursor: pointer;
    font-size: 14px;
    display: flex;
}
.customCheckbox label::before, .customCheckbox .customControlLabel::before,
.customRadio label::before, .customRadio .customControlLabel::before {
    background-color: #ddd;
    border: 1px solid #ddd;
    border-radius: 0;
    height: 17px;
    top: 0;
    width: 17px;
}
.customCheckbox label::after, .customCheckbox .customControlLabel::after,
.customRadio label::after, .customRadio .customControlLabel::after {
    background-size: 10px 10px;
    height: 17px;
    top: 0;
    width: 17px;
}
.profilecheckoutcheck .customCheckbox input:checked ~ .customControlLabel::before,
.profilecheckoutcheck .customCheckbox input:checked label::before,
.customCheckbox input:checked ~ .customControlLabel::before,
.customCheckbox input:checked label::before,
.customCheckbox .customControlInput:checked ~ .customControlLabel::before,
.customCheckbox .customControlInput:checked label::before,
.customRadio input:checked ~ .customControlLabel::before,
.customRadio input:checked label::before,
.customRadio .customControlInput:checked ~ .customControlLabel::before,
.customRadio .customControlInput:checked label::before {
    background-color: #bc9051;
    border-color: #bc9051;
}

.customRadio label::before,
.customRadio .customControlLabel::before {
    border-radius: 50%;
    height: 17px;
    width: 17px;
}
.customRadio label::after,
.customRadio .customControlLabel::after {
    background-position: 1px 1px;
    background-size: 9px 8px;
    left: -22px;
    top: 2px;
    height: 10px;
    width: 10px;
}

.customControl input {
    height: 23px !important;
    z-index: 9;
}
.profilecheckoutcheck .customCheckbox input:checked ~ svg,
.customCheckbox input:checked ~ svg,
.customRadio input:checked ~ svg {
    display: block;
}
.customCheckbox input ~ svg {
    margin: auto;
	position: absolute;
	left: 2px;
	top: 0;
	bottom: 7px;
	display: block;
	width: 4;
    height: 4;
    display: none;
}
.profilecheckoutcheck .customCheckbox input ~ svg {
    margin: auto;
	position: absolute;
	left: 1px;
	top: 0;
	bottom: 11px;
	display: block;
	width: 4;
    height: 4;
    display: none;
}
.customRadio input ~ svg {
    margin: auto;
	position: absolute;
	left: 5px;
	top: 0;
	bottom: 7px;
	display: block;
	width: 4;
    height: 4;
    display: none;
}
.customCheckbox .customControlLabel span {
    margin-top: 1px;
}

/////////////////////////////////////////////
/////////////// ORDER SUMMARY ///////////////
/////////////////////////////////////////////
.orderSummaryContent .tableResponsive {
    display: block;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}
.orderSummaryContent .tableResponsive > .tableBordered {
    border: 0;
}
.orderSummaryTable {
    background-color: #f7f7f7;
    margin-top: 28px;
}
.orderSummaryTable .table tr td, .orderSummaryTable .table tr th, .orderSummaryTable table tr td, .orderSummaryTable table tr th {
    font-weight: 500;
    vertical-align: middle;
    padding: 15px 10px;
    border-width: 1px;
}
.orderSummaryTable .table tr td.textRight, .orderSummaryTable table tr td.textRight {
    padding: 15px;
}
.orderSummaryTable .tableBordered thead td, .orderSummaryTable .tableBordered thead th {
    border-bottom-width: 2px;
}
.orderSummaryTable .tableBordered th {
    border-bottom: 0 !important;
}
.orderSummaryTable .tableBordered th, .orderSummaryTable .tableBordered td {
    vertical-align: bottom;
    border: 1px solid #dee2e6;
}
.orderPaymentMethod {
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    padding: 3px 20px 20px;
    margin-top: 20px;
}
// .orderSummaryTable .table, .orderSummaryTable table {
//     color: #333333;
//     margin-bottom: 0;
//     white-space: nowrap;
// }
// .customControl label::after {
//     display: inline-block;
//     font-size: 11px;
//     height: 19px;
//     left: 4px;
//     position: absolute;
//     top: -1px;
//     transition: border 0.2s linear 0s, color 0.2s linear 0s;
//     width: 19px;
// }
// .customControl.customCheckbox input[type="checkbox"]:checked + label::before {
//     border-color: #58748B;
// }
// .customControl input[type="checkbox"]:checked + label::before {
//     border-width: 10px;
// }
// .customControl input[type="checkbox"]:checked + label::after {
//     color: #fff;
//     content: "\f00c";
//     font-family: "FontAwesome"
// }
// .customControl label::before {
//     background-color: #ffffff;
//     border: 1px solid #c8c7cc;
//     content: "";
//     display: inline-block;
//     height: 20px;
//     left: 0;
//     margin-right: 10px;
//     position: absolute;
//     width: 20px;
//     border-radius: 0;
//     top: 1px;
//     transition: border 0.2s linear 0s, color 0.2s linear 0s;
// }
// .customControl label::after {
//     display: inline-block;
//     font-size: 11px;
//     height: 19px;
//     left: 4px;
//     position: absolute;
//     top: -1px;
//     transition: border 0.2s linear 0s, color 0.2s linear 0s;
//     width: 19px;
// }
// .customControl label {
//     cursor: pointer;
//     display: inline-block;
//     font-size: 13px;
//     margin-right: 15px;
//     padding-left: 30x !important;
//     position: relative;
//     line-height: 23px;
//     transition: border 0.2s linear 0s, color 0.2s linear 0s;
//     white-space: nowrap;
// }
// .customCheckbox label, .customCheckbox .customControlLabel {
//     color: #333333;
//     line-height: 1;
//     cursor: pointer;
// }
// .singleInputItem .customCheckbox input {
//     height: 15px !important;
// }


// .customCheckbox input, .customCheckbox .customControlInput {
//     outline: none;
// }
// .customControlInput {
//     position: absolute;
//     z-index: -1;
//     opacity: 0;
// }
// .customControlInput:checked ~ .customControlLabel::before {
//     color: #fff;
// }

// .customCheckbox .customControlInput:checked ~ .customControlLabel::after {
//     background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E")
// }

// .customCheckbox label::before, .customCheckbox .customControlLabel::before {
//     border: 1px solid #ddd;
//     border-radius: 0;
//     height: 15px;
//     top: 0;
//     width: 15px;
// }






// @media (max-width:575.98px){
//     .table-responsive-sm{display:block;width:100%;overflow-x:auto;-webkit-overflow-scrolling:touch;-ms-overflow-style:-ms-autohiding-scrollbar}
//     .table-responsive-sm>.table-bordered{border:0}
// }
// @media (max-width:767.98px){
//     .table-responsive-md{display:block;width:100%;overflow-x:auto;-webkit-overflow-scrolling:touch;-ms-overflow-style:-ms-autohiding-scrollbar}
//     .table-responsive-md>.table-bordered{border:0}
// }
// @media (max-width:991.98px){
//     .table-responsive-lg{display:block;width:100%;overflow-x:auto;-webkit-overflow-scrolling:touch;-ms-overflow-style:-ms-autohiding-scrollbar}
//     .table-responsive-lg>.table-bordered{border:0}
// }
// @media (max-width:1199.98px){
//     .table-responsive-xl{display:block;width:100%;overflow-x:auto;-webkit-overflow-scrolling:touch;-ms-overflow-style:-ms-autohiding-scrollbar}
//     .table-responsive-xl>.table-bordered{border:0}
// }
.cartTableResponsive{display:block;width:100%;overflow-x:auto;-webkit-overflow-scrolling:touch;-ms-overflow-style:-ms-autohiding-scrollbar}
.cartTableResponsive > .table-bordered{border:0}

.subtotalMainWrapper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap:wrap;
    flex-wrap: wrap;
}

.subtotalWrapper {
    margin-top: 30px;
    background-color: #f8f8f8;
}

.subtotalWrapper h3 {
    color: #333333;
    font-size: 18px;
    font-weight: 500;
    padding: 27px 15px 25px;
    margin-bottom: 0;
}

.subtotalWrapper .subtotalItems {
    font-weight: 500;
}

.subtotalWrapper .subtotalItems .table {
    margin-bottom: 0;
}

.subtotalWrapper .subtotalItems .table tr td {
    color: #333333;
    padding: 15px 20px;
}

.subtotalWrapper .subtotalItems .table tr td:nth-child(2) {
    color: #333333;
    text-align: right;
}

.subtotalWrapper .subtotalItems .table tr td.totalAmount {
    color: #bc9051;
    font-weight: 700;
}

.subtotalWrapper a {
    border-radius: 0;
    text-align: center;
}

.cartmobilepadding {
    padding: 100px 0;
}

@media (max-width: 767.98px) {
    .cartmobilepadding {
        padding: 20px 0;
    }
}
@media (min-width: 768px) and (max-width: 835px) {
    .cartmobilepadding {
        padding: 30px 0;
    }
}
/********************************
             Loading
********************************/
.thumbnailLoading {
    width: 119px;
    height: 19px;
}
.titleheadLoading {
    width: 300px;
    height: 19px;
}
.priceheadLoading {
    width: 90px;
    height: 19px;
}
.quantityheadLoading {
    width: 90px;
    height: 19px;
}
.subtotalheadLoading {
    width: 90px;
    height: 19px;
}
.removeheadLoading {
    width: 90px;
    height: 19px;
}

.cartImageLoading {
    width: 119px;
    height: 119px;
}
.titleLoading {
    width: 300px;
    height: 19px;
}
.priceLoading {
    width: 90px;
    height: 19px;
}
.quantityLoading {
    width: 90px;
    height: 19px;
}
.subtotalLoading {
    width: 90px;
    height: 19px;
}
.removeLoading {
    width: 90px;
    height: 19px;
}
.inputLoading {
    width: 487px;
    height: 19px;
}
.cartTable.tableLoading .tableBordered {
    border: 1px solid #e2e2e2;
}
.cartTable.tableLoading .table thead {
    background-color: #ffffff !important;
}
.cartTable.tableLoading .table thead tr th {
    border-color: #ffffff;
}
.cartTable.tableLoading .table tbody tr td {
    border-color: #ffffff;
}
.cartTable.tableLoading .table tbody tr .proThumbnails {
    padding: 10px 20px !important;
}
.cartUpdateOption.cartUpdateOptionLoading {
    border: 0;
}
.cartUpdateOption.cartUpdateOptionLoading .applyCouponWrapper{
    width: 40%;
}
.cartUpdateOption.cartUpdateOptionLoading .applyCouponWrapper .inputLoading{
    width: 550px;
}

// /* Base for label styling */
// [type="checkbox"]:not(:checked),
// [type="checkbox"]:checked {
//   position: absolute;
//   left: -9999px;
// }
// [type="checkbox"]:not(:checked) + label,
// [type="checkbox"]:checked + label {
//   position: relative;
//   padding-left: 1.95em;
//   cursor: pointer;
// }

// /* checkbox aspect */
// [type="checkbox"]:not(:checked) + label:before,
// [type="checkbox"]:checked + label:before {
//   content: '';
//   position: absolute;
//   left: 0; top: 0;
//   width: 1.25em; height: 1.25em;
//   border: 2px solid #ccc;
//   background: #fff;
//   border-radius: 4px;
//   box-shadow: inset 0 1px 3px rgba(0,0,0,.1);
// }
// /* checked mark aspect */
// [type="checkbox"]:not(:checked) + label:after,
// [type="checkbox"]:checked + label:after {
//   content: '\2713\0020';
//   position: absolute;
//   top: .15em; left: .22em;
//   font-size: 1.3em;
//   line-height: 0.8;
//   color: #09ad7e;
//   transition: all .2s;
//   font-family: 'Lucida Sans Unicode', 'Arial Unicode MS', Arial;
// }
// /* checked mark aspect changes */
// [type="checkbox"]:not(:checked) + label:after {
//   opacity: 0;
//   transform: scale(0);
// }
// [type="checkbox"]:checked + label:after {
//   opacity: 1;
//   transform: scale(1);
// }
// /* disabled checkbox */
// [type="checkbox"]:disabled:not(:checked) + label:before,
// [type="checkbox"]:disabled:checked + label:before {
//   box-shadow: none;
//   border-color: #bbb;
//   background-color: #ddd;
// }
// [type="checkbox"]:disabled:checked + label:after {
//   color: #999;
// }
// [type="checkbox"]:disabled + label {
//   color: #aaa;
// }
// /* accessibility */
// [type="checkbox"]:checked:focus + label:before,
// [type="checkbox"]:not(:checked):focus + label:before {
//   border: 2px dotted blue;
// }

// /* hover style just for information */
// label:hover:before {
//   border: 2px solid #4778d9!important;
// }
