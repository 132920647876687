.rdtPicker {
    padding: 10px !important;
    box-shadow: 0 1px 3px rgba(0,0,0,.4) !important
}
.rdtPicker th {
    padding: 4px;
}
.rdtPicker td {
    padding: 6px !important;
}
.rdtPicker .rdtMonths td,.rdtPicker .rdtYears td{
    padding: 17px 6px 6px 6px !important;
}
.rdtPicker th.rdtSwitch {
    padding: 6px !important;
}