@import 'colorfonts';

/***********************************************
                    MASTER
***********************************************/

.masterContainer {
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    // min-height: 100vh;
    -webkit-transition: margin-left .3s ease-out;
    transition: margin-left .3s ease-out;
    background: #ccc;
}
.masterContent {
    // margin-left: 260px;
    margin-left: 200px;
    padding: 30px 30px 0;
    -webkit-box-flex: 1;
    flex: 1 0 auto;
    overflow: hidden;
}
.profileContainer {
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    min-height: 100vh;
    -webkit-transition: margin-left .3s ease-out;
    transition: margin-left .3s ease-out;
}
.profileContent {
    padding: 30px 30px 0;
    -webkit-box-flex: 1;
    flex: 1 0 auto;
}
.progress {
    display: -ms-flexbox;
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: .75rem;
    background-color: #e9ecef;
    border-radius: .25rem;
}
.bgDanger {
    background-color: #f96868 !important;
    color: #fff;
}
.progressBar {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #33cabb;
    transition: width .6s ease;
}
/***********************************************
                    CUSTOMERS
***********************************************/
.flexbox {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
}
.flexbox > :first-child {
    margin-left: 0;
}
.flexbox > :last-child {
    margin-right: 0;
}
.flexbox > * {
    margin-left: 4px;
    margin-right: 4px;
}
.lookup {
    position: relative;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
}
.lookup::before {
    font-size: 16px;
    position: absolute;
    top: 52%;
    left: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    color: rgba(77,82,89,0.4);
    width: 36px;
    text-align: center;
    cursor: text;
}
.lookup > input {
    color: #4d5259 !important;
    border: 1px solid #ebebeb !important;
    border-radius: 18px !important;
    height: 36px !important;
    width: 200px;
    max-width: 100% !important;
    padding-left: 36px !important;
    padding-right: 18px !important;
    font-size: 15px !important;
    font-weight: 300 !important;
    letter-spacing: .5px !important;
    outline: none !important;
    -webkit-transition: .5s;
    transition: .5s !important;
}

@media (max-width: 767.98px){
    .masterContent {
        margin-left: 0 !important;
    }
}