/***********************************************
                    BACKDROP
***********************************************/
.backdrop{
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 105;
}
.memberbackdrop{
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 850;
}
.dashboardbackdrop{
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 900;
}
.doublebackdrop{
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1500;
}