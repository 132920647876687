@import 'colorfonts';

/***********************************************
                    FOOTER
***********************************************/
footer .logo {
    text-align: center;
    font-family: 'Monoton', cursive;
    color: #ffffff;
    font-size: 40px;
    padding: 11px 0px;
    border-bottom: 1px solid #4d4d4d;
}

footer .wrapper {
    display: flex;
    color: #828282;
}

footer .left,
footer .right {
    flex: 1;
    padding: 11px;
}

footer .business_info {
    display: flex;
    flex-wrap: wrap;
    padding-top: 11px;
}

footer .business_info .tag {
    flex-grow: 1;
    position: relative;
    width: 50%;
    margin-bottom: 30px;
}
footer .business_info .icon {
    position: absolute;
    font-size: 26px;
    top: 3px;
}

footer .business_info .info {
    padding-left: 40px;
}
.websiteFooter {
    display: inline-block;
    width: 100%;
}
.siteFooter {
    margin-left: 260px;
    background-color: $colorWhite;
    font-size: 14px;
    color: $footerTextColor;
    // border-top: 1px solid #f1f2f3;
    padding: 15px 30px;
    box-shadow: 0 1px 12px rgba(0,0,0,0.1);
}
.siteFooter p {
    margin-bottom: 0;
    font-weight: 400;
}
.profileFooter {
    background-color: $colorWhite;
    font-size: 14px;
    color: $footerTextColor;
    // border-top: 1px solid #f1f2f3;
    padding: 15px 30px;
    box-shadow: 0 1px 12px rgba(0,0,0,0.1);
}
.profileFooter p {
    margin-bottom: 0;
    font-weight: 400;
}
.textMdLeft {
    text-align: left;
}


/***********************************************
                    NEW FOOTER
***********************************************/
.footerpertama img {
    margin-bottom: 25px;
}
.footerpertama p {
    line-height: 32px;
    color: #ffffff;
    font-size: 15px;
}
.footerkedua {
    padding-left: 35px;
}
.footerkedua h3 {
    color: #ffffff;
}
.footerkedua .list {
    list-style: none;
    margin: 0;
    padding: 0;
}
.footerkedua .list li:not(:last-child){
    margin-bottom: 10px;
}
.footerkedua .list li a {
    font-size: 18px;
    color: #ffffff;
    -webkit-transition: all 600ms linear 0s;
    -o-transition: all 600ms linear 0s;
    transition: all 600ms linear 0s;
}
.footersocial h3 {
    color: #ffffff;
}
.footersocial ul {
    padding-top: 5px;
}
.footersocial ul li {
    display: inline-block;
    text-align: center;
    margin-right: 10px;
}
.footersocial ul li a {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 14px;
    color: #ffffff;
    border-radius: 3px;
    background: #aaaaaa;
    display: inline-block;
    -webkit-transition: all 0.2s linear;
    -o-transition:all 0.2s linear;
    transition: all 0.2s linear;
}
.footertext h3 {
    color: #ffffff;
}
.footertext .list {
    list-style: none;
    margin: 0;
    padding: 0;
}
.footertext .list li:not(:last-child){
    margin-bottom: 10px;
}
.footertext .list li a {
    font-size: 18px;
    color: #ffffff;
    -webkit-transition: all 600ms linear 0s;
    -o-transition: all 600ms linear 0s;
    transition: all 600ms linear 0s;
}
.footercopy p {
    margin-bottom: 0;
    font-size: 16px;
    color: #ffffff;
    padding: 20px 0;
}