$workSans: 'Work Sans', sans-serif;
$arimo: 'Arimo', sans-serif;
$Roboto: 'Roboto', sans-serif;
$Lato: 'Lato', sans-serif;

$bodyColor: #404040;
$bodyBackgroundColor: #ffffff;
// $bodyBackgroundColor: #f5f6fa;
$headerBgColor: #ffffff;
$headerBottomColor: #272723;
$navigationItemsAColorActive: #4d5259;
$navigationItemsAColor: rgba(77,82,89,0.7);
$navigationItemsAHoverColor: #7266BA;
$toggleButton: #000000;
$sideDrawerBg: #272723;
$sideDrawerAColor: #a7b1c2;
$sideDrawerAHover: #ffffff;
$sideDrawerBGColor: #181816;
$sideDrawerRightBorder: #7266BA;
$addToCartBackground: #FF8D25;
$footerTextColor: #616a78;
$textSuccess: #15c377;
$loadingColor: #E2E2E2;

$colorWhite: #ffffff;
$colorBlue: #521751;
$colorMoreBlue: #0190fe;
$colorBlack: #212121;
$colorMoreBlack: #000000;
$colorGrey: #878787;
$colorGray: #868e96;
$colorOrange: #ff4c3b;
$colorLight: #cccccc;
$colorDark: #272723;
$colorRed: #DC143C;
$colorGreen: #8FBC8F;