/***********************************************
                MAIN DASHBOARD
***********************************************/
.masterDashboard {
    margin-left: 200px;
    flex: 1 0 auto;
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    // width: 100%;
    // min-width: 100%;
    position: relative;
    overflow: hidden;
}
.masterDashboard.rightsidebar > .center {
    order: 1;
}
.masterDashboard.simple.rightsidebar > .center {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    z-index: 3;
    min-width: 0;
    width: 100%;
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
}
.ps {
    overflow: hidden !important;
    overflow-anchor: none;
    -ms-overflow-style: none;
    touch-action: auto;
    -ms-touch-action: auto;
}
.headerDashboard {
    flex-direction: column;
    box-sizing: border-box;
    display: flex;
    place-content: stretch space-between;
    align-items: stretch;
}
.center > .headerDashboard {
    padding-bottom: 20px;
}

.accent {
    background-color: #213e96 !important;
    color: #fff!important;
}
.headerDashboardTitle {
    flex-direction: column;
    box-sizing: border-box;
    display: flex;
    
    align-items: center;
}
.headerDashboardName {
    font: 400 34px/40px Muli,Helvetica Neue,Arial,sans-serif;
}
.masterDashboard > .center > .contentDashboard {
    background: #f5f5f5;
    flex: 1;
}
.masterDashboard > .center > .contentDashboard .contentDashboardWrapper {
    height: 100%;
}
.contentDashboardWrapper {
    display: flex;
    flex-direction: column;
}
.masterDashboard .contentDashboardHeader {
    border-bottom: 1px solid rgba(0,0,0,.12);
}
.contentDashboardHeader {
    display: flex;
    overflow: hidden;
    position: relative;
    flex-shrink: 0;
}
.masterDashboard > .center > .contentDashboard .contentDashboardWrapper .contentDashboardBody {
    flex-grow: 1;
}
.contentDashboardBody {
    position: relative;
    overflow: hidden;
    display: flex;
    transition: height .5s cubic-bezier(.35,0,.25,1);
}
.masterDashboard > .center > .contentDashboard .contentDashboardWrapper .contentDashboardBody .contentDashboardBodyWrapper{
    overflow-y: hidden;
}
.contentDashboardBodyWrapper.active{
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 1;
    flex-grow: 1;
}
.contentDashboardBodyWrapper{
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    display: block;
    overflow: hidden;
    flex-basis: 100%;
}
.contentDashboardBodyContent {
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    transform: none;
}
.dynamicHeight .contentDashboardBodyContent {
    height: 100%;
    overflow: hidden;
}
.widgetDashboardGroup {
    flex-flow: row wrap;
    box-sizing: border-box;
    display: flex;
    flex: 1 1 100%;
    max-width: 100%;
}
.widgetDashboard {
    flex-direction: column;
    box-sizing: border-box;
    display: flex;
    padding: 12px;
}
.widgetDashboard .widgetFront {
    background: #fff;
    border-color: rgba(0,0,0,.12) !important;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    position: relative;
    overflow: hidden;
    visibility: visible;
    width: 100%;
    opacity: 1;
    z-index: 10;
    border-radius: 8px;
    transition: visibility 0s ease-in .2s,opacity 0s ease-in .2s,-webkit-transform .5s ease-out 0s;
    transition: transform .5s ease-out 0s,visibility 0s ease-in .2s,opacity 0s ease-in .2s;
    transition: transform .5s ease-out 0s,visibility 0s ease-in .2s,opacity 0s ease-in .2s,-webkit-transform .5s ease-out 0s;
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border: 1px solid;
}
.widgetrow1, .sidewidget1 {
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}
.widgetrow2, .sidewidget2 {
    flex-direction: column;
    box-sizing: border-box;
    display: flex;
    place-content: center;
    align-items: center;
}
.widgetrow3 {
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    place-content: center flex-start;
    align-items: center;
    border-top: 1px solid rgba(0,0,0,.12);
    padding: 16px;
}
.widgetrow1 {
    height: 52px !important;
    min-height: 52px !important;
    max-height: 52px !important;
}
.widgetrow1, .sidewidget1 {
    padding-left: 16px!important;
    padding-right: 8px!important;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
}
.widgetrow2 {
    padding-top: 8px!important;
    padding-bottom: 32px!important;
}
.widgetrow1Text {
    display: inline-block;
    position: relative;
    text-align: left;
    font: 400 16px/1.125 Muli,Helvetica Neue,Arial,sans-serif;
}
.widgetrow2Text {
    color: #03a9f4 !important;
}
.widgetrow2Text {
    font-size: 72px;
    line-height: 72px;
}
.widgetrow3Text {
    font-size: 15px;
    font-weight: 400;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: rgba(0,0,0,.54);
}
.widgetrow2Subtext {
    color: rgba(0,0,0,.54);
    font-weight: 500;
    font-size: 16px;
}
.widgetrow3Subtext {
    font-size: 15px;
    font-weight: 400;
    margin-left: 8px !important;
}
.widgetrow1TextWrapper {
    padding: 18px 0;
    font-family: Muli,Helvetica Neue,Arial,sans-serif;
}
.widgetrow1Button {
    padding: 0;
    min-width: 0;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    line-height: 40px;
    border-radius: 50%;
}
.widgetrow1Button {
    box-sizing: border-box;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    outline: 0;
    border: none;
    -webkit-tap-highlight-color: transparent;
    display: inline-block;
    white-space: nowrap;
    text-decoration: none;
    vertical-align: baseline;
    text-align: center;
    margin: 0;
    min-width: 64px;
    line-height: 32px;
    padding: 6px 0px 4px 16px; 
    border-radius: 4px;
    overflow: visible;
    background-color: transparent;
}
.widgetrow1ButtonWrapper {
    line-height: normal;
}
.widgetrow1ButtonWrapper svg {
    font-size: 18px;
}

/////////////////////////////////////
/////////////////////////////////////
///////////// Right Side ////////////
/////////////////////////////////////
/////////////////////////////////////

.masterDashboard > .sidebar {
    width: 280px;
    min-width: 280px;
    max-width: 280px;
}
.masterDashboard.simple.rightsidebar>.sidebar.locked-open {
    background: 0 0;
    box-shadow: none;
}
.masterDashboard.simple.rightsidebar>.sidebar {
    order: 2;
    overflow: hidden;
}
.masterDashboard .posRelative {
    position: relative !important;
}

.masterDashboard .rightposition {
    right: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    transition: transform 0.2s ease;
    will-change: transform;
}
.sidebarDashboard {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    width: 280px;
    min-width: 280px;
    max-width: 280px;
    z-index: 860;
    box-shadow: 0 2px 3px 0 rgba(0,0,0,.35);  
}
.sidebarDashboard.lockedopen {
    position: relative;
}
////////////////////////////////////////////////////////////////
/////////////////////////// NOTIFICATION ///////////////////////
////////////////////////////////////////////////////////////////
.sidebarNotification {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    width: 280px;
    min-width: 280px;
    max-width: 280px;
    z-index: 1000;
    box-shadow: 0 2px 3px 0 rgba(0,0,0,.35);  
    position: absolute !important;
    top: 0 !important;
    bottom: 0 !important;
}
.sidebarNotification.lockedopen {
    position: relative;
}
.sidebarNotification.rightposition {
    right: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    transition: transform 0.2s ease;
    will-change: transform;
}
.sidebarNotification.open {
    -webkit-transform: translateX(0)!important;
    transform: translateX(0)!important;
}
.sidebarContent {
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    flex: 1 1 auto;
    background-color: #f5f5f5;
}
.sidebarWidgetGroup {
    flex-direction: column;
    box-sizing: border-box;
    display: flex;
    flex: 1 1 100%;
    max-width: 100%;
}
.sidebarWidget {
    display: block;
    position: relative;
    -webkit-perspective: 3000px;
    perspective: 3000px;
    padding: 12px;
}
.sidebarWidget > .sidebarWidgetFront {
    background: #fff;
    border-color: rgba(0,0,0,.3) !important;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    position: relative;
    overflow: hidden;
    visibility: visible;
    width: 100%;
    opacity: 1;
    z-index: 10;
    border-radius: 8px;
    transition: visibility 0s ease-in .2s,opacity 0s ease-in .2s,-webkit-transform .5s ease-out 0s;
    transition: transform .5s ease-out 0s,visibility 0s ease-in .2s,opacity 0s ease-in .2s;
    transition: transform .5s ease-out 0s,visibility 0s ease-in .2s,opacity 0s ease-in .2s,-webkit-transform .5s ease-out 0s;
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border: 1px solid;
}
.sidewidget1Text {
    font-size: 16px;
    font-weight: 400;
}

.sidewidget2 {
    padding-bottom: 24px;
    padding: 16px;
}
.sidewidget2Text, .sidewidget2Text3 {
    font-size: 24px;
    font-weight: 400;
    color: rgba(0,0,0,.54);
    font-family: Muli,Helvetica Neue,Arial,sans-serif;
}
.sidewidget2Text2 {
    line-height: 88px;
    font-size: 72px;
    font-weight: 400;
    color: rgba(0,0,0,.74);
    font-family: Muli,Helvetica Neue,Arial,sans-serif;
}

//////////////// DASHBOARD
/// 
.dashboardCard {
    background: #fff;
    border-color: rgba(0,0,0,.3) !important;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    position: relative;
    overflow: hidden;
    visibility: visible;
    width: 100%;
    opacity: 1;
    z-index: 10;
    border-radius: 8px;
    border: 1px solid;
}

.dashboardCard .cardTitles {
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: 400;
    color: rgba(0,0,0,.74);
    font-family: Muli,Helvetica Neue,Arial,sans-serif;
    padding: 20px
}

.dashboardCard .cardBodys {
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 44px;
    font-weight: 400;
    color: rgba(0,0,0,.74);
    font-family: Muli,Helvetica Neue,Arial,sans-serif;
    padding-bottom: 20px;
}

@media (max-width: 767.98px) {
    .headerDashboardName {
        font: 400 27px/32px Muli,Helvetica Neue,Arial,sans-serif;
    }
    .loginMemberTitle {
        display: none;
    }

  }
  @media (max-width: 1025px) {
    .masterDashboard {
        margin-left: 0;
    }
    .sidebarDashboard {
        position: absolute !important;
        top: 0 !important;
        bottom: 0 !important;
    }
    .masterDashboard .open {
        -webkit-transform: translateX(0)!important;
        transform: translateX(0)!important;
    }
  }
  @media (min-width: 1026px) {
    .masterDashboard .open {
        -webkit-transform: translateX(0)!important;
        transform: translateX(0)!important;
    }
  }