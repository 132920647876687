.AppDrop {
    text-align: center;
    // background-color: rgb(206, 213, 223);
    min-height: 25vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
  }
  
  .CardDrop {
    background-color: white;
    // padding: 44px;
    // display: flex;
    display: block;
    align-items: center;
    justify-content: center;
    // box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
    //   0 5px 15px 0 rgba(0, 0, 0, 0.08);
    box-sizing: border-box;
    text-align: -webkit-center;
    text-align: -moz-center;
    width: 100%;
  }

  .uploadImageWrap {
    width: 100%;
    float: left;
  }

  .uploadImageWrap img {
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 2px 1px -1px rgba(0,0,0,0.12);
    cursor: pointer;
    width: 100px;
    height: 100px;
  }

  .Dropzone {
    height: 100px;
    width: 100px;
    background-color: #fff;
    border: 2px dashed rgb(187, 186, 186);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 20px;
    margin-bottom: 20px;
  }
  .FileInput {
    display: none;
  }
  .Highlight {
    background-color: rgb(188, 185, 236);
  }
#drop-area {
    border: 2px dashed #ccc;
    border-radius: 20px;
    width: 480px;
    font-family: sans-serif;
    margin: 100px auto;
    padding: 20px;
  }
  #drop-area.highlight {
    border-color: purple;
  }
  p {
    margin-top: 0;
  }
  .my-form {
    margin-bottom: 10px;
  }
  #gallery {
    margin-top: 10px;
  }
  #gallery img {
    width: 150px;
    margin-bottom: 10px;
    margin-right: 10px;
    vertical-align: middle;
  }
  .button {
    display: inline-block;
    padding: 10px;
    background: #ccc;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  .button:hover {
    background: #ddd;
  }
  #fileElem {
    display: none;
  }