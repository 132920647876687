.pageWrapper .memberpageLeftNav{
    position: fixed;
    top: 56px;
    left: 0;
    bottom: 0;
    width: 200px;
    // background-color: #3f4a59;
    // background-color: #272723; last one
    background-color: #fff;
    white-space: nowrap;
    // -webkit-box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.2);
    // box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    display: -webkit-box;
    display: flex;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    z-index: 45;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transition: .3s ease;
    -moz-transition: .3s ease;
    transition: .3s ease;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: transform;
}
.memberpageLeftNav .navigationHeader {
    font-size: 0.6875rem;
    display: block;
    text-transform: uppercase;
    font-weight: 500;
    color: #272723;
    padding: 11px 15px;
}
.memberpageLeftNav .version {
    font-size: 0.8rem;
    display: block;
    color: #272723;
    padding: 11px 15px;
}
.memberpageLeftNav nav ul li a.menuLink {
    color: #213e96;
}

.memberpageLeftNav nav ul .menuItem {
    vertical-align: top;
    -webkit-transition: opacity 0.5s linear;
    transition: opacity 0.5s linear;
}
.memberpageLeftNav nav ul .menuItem a {
    text-decoration: none;
}
.memberpageLeftNav nav ul .menuItem > a:hover {
    background-color: #213e96;
    color: #fff;
    border-right: 4px solid #fff;
}
.memberpageLeftNav nav ul .menuItem > a.active {
    // background-color: #2ecd99;
    background-color: #213e96;
    color: #fff;
    border-right: 4px solid #fff;
}
.menu {
    list-style: none;
    padding-left: 0;
    margin-bottom: 30px;
    margin-top: 0;
}
.menuItem {
    vertical-align: top;
    -webkit-transition: opacity 0.2s linear;
    transition: opacity 0.2s linear;
}
.menuItem a {
    text-decoration: none;
}
.menuItem > a.active {
    // background-color: #2ecd99;
    background-color: #168970;
    color: #fff;
}
.menuLink {
    position: relative;
    height: 40px;
    padding: 0 12px;
    font-weight: 400;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-transition: .2s linear;
    transition: .2s linear;
    color: #fff;
}
.pageLeftNav .navigationHeader {
    font-size: 0.6875rem;
    display: block;
    text-transform: uppercase;
    font-weight: 500;
    color: #a1a1a1;
    padding: 11px 15px;
}
.pageWrapper {
    margin: 0;
    width: 100%;
}
.pageWrapper .pageRight {
    margin-left: 260px;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    min-height: 100vh;
    -webkit-transition: margin-left .3s ease-out;
    transition: margin-left .3s ease-out;
}
.pageWrapper .pageContainer .pageInner .pageRight h2{
    font-size: 18px;
    font-weight: 600;
    margin: 0;
}
.pageWrapper .pageContainer .pageInner .pageRight p{
    padding: 0 15px;
    margin: 0;
}
.pageWrapper .pageContainer .pageInner .pageRight .pageRightWrapper{
    padding: 0 15px 15px 15px;
    // display: flex;
}
.pageRightWrapper .pageRightInner {
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    width: 100%;
}

@media (max-width: 767.98px){
    .pageWrapper {
        padding: 0;
    }
    .pageWrapper .pageContainer .pageInner{
        padding: 2rem !important;
        display: table !important;
    }
    .pageWrapper .pageContainer .pageInner .pageLeftNav{
        position: fixed;
        left: 0;
        width: 298px;
        margin-left: 0;
        bottom: 0;
        z-index: 45;
        border-right: 1px solid #2a3e4c;
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        transition: all 0.4s ease;
    }
    .pageWrapper .pageContainer .pageInner .memberpageLeftNav{
        position: fixed;
        left: 0;
        width: 298px;
        margin-left: 0;
        bottom: 0;
        z-index: 45;
        border-right: 1px solid #2a3e4c;
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        transition: all 0.4s ease;
    }
    .pageWrapper .pageContainer .pageInner .pageLeftNav .pageLeftNavScroll{
        position: relative;
        overflow: hidden;
        width: auto;
        height: 100%;
    }
    .pageWrapper .pageContainer .pageInner .memberpageLeftNav .pageLeftNavScroll{
        position: relative;
        overflow: hidden;
        width: auto;
        height: 100%;
    }
    .pageWrapper .pageContainer .pageInner .pageRight{
        width: 298px;
        display: block;
    }
    .pageWrapper .pageContainer .pageInner .pageRight .pageRightWrapper{
        padding: 0;
    }
    .pageWrapper .pageContainer .pageInner .pageRight .pageRightWrapper .pageRightInner{
        display: block;
    }
    .pageWrapper .pageContainer .pageInner .pageRight h2{
        font-size: 18px;
        font-weight: 600;
    }
    .pageWrapper .pageContainer .pageInner .pageRight p{
        padding-bottom: 1rem;
    }
    .innerLeft {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .innerRight {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}