@import 'colorfonts';

.productInfo h3 {
    font-size: 24px;
    font-weight: 700;
    line-height: 26px;
    margin-top: 0;
    margin-bottom: 0;
    text-transform: capitalize;
    font-family: $Lato;
}
.productInfo .productInfoBrand {
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
}
.productInfo .productInfoPrice {
    padding-top: 10px;
}
.productInfo .productInfoPrice .regularPrice {
    font-size: 22px;
    font-weight: 700;
    line-height: 24px;
    font-family: $Lato;
}
.productInfo .availability svg{
    font-size: 13px;
    color: #90EE90;
}
.productInfo .availability span {
    color: #666666;
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
    padding-left: 3px;
    text-transform: uppercase;
    font-family: $Lato;
}
.productInfo .quantityCart {
    margin-right: 15px;
}
.productInfo .quantityCart .quantityBox {
    width: 90px;
    height: 46px;
    border: 1px solid #ddd;
    padding: 0 15px;
    float: left;
}
.productInfo .quantityCart .quantityBox .qtybtn {
    width: 15px;
    display: block;
    float: left;
    line-height: 40px;
    cursor: pointer;
    text-align: center;
    font-size: 22px;
    color: #666666;
}
.productInfo .quantityCart .quantityBox input {
    width: 28px;
    float: left;
    border: none;
    height: 40px;
    line-height: 40px;
    padding: 0;
    text-align: center;
    background-color: transparent;
}

.productInfo .quantityCart .quantityBox .qtybtn:hover {
    color: #bc9051;
}
.productInfo .addtocartButton .buyBtn {
    color: #fff;
    height: 46px;
    font-weight: 500;
    padding: 14px 26px;
    display: inline-block;
    background-color: #bc9051;
    margin-left: 10px;
    font-family: $Lato;
    cursor: pointer;
}
.productInfo .addtocartButton .buyBtn:hover {
    background-color: #333333;
}
.productInfo .addtocartButton .buyBtn span {
    padding-left: 10px;
}
.productInfo .tagLine {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.productInfo .tagLine h5 {
    color: #333333;
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 0;
    margin-right: 10px;
    text-transform: uppercase;
}
.productInfo .tagLine a {
    color: #666666;
    font-size: 14px;
    margin-right: 10px;
    text-transform: uppercase;
}
.productInfo .tagLine .tagLineDetail {
    color: #666666;
    font-size: 14px;
    margin-right: 10px;
    text-transform: uppercase;
}
.productInfo .likeIcon {
    padding-top: 20px;
    border-top: 1px solid #e5e5e5;
}
.productInfo .likeIcon a.facebook {
    background-color: #3B5999;
}
.productInfo .likeIcon a.twitter {
    background-color: #1DA1F2;
}
.productInfo .likeIcon a.pinterest {
    background-color: #CB2028;
}
.productInfo .likeIcon a {
    color: #fff;
    text-align: center;
    display: inline-block;
    font-size: 12px;
    line-height: 22px;
    padding: 0 8px;
    margin-right: 6px;
    border-radius: 3px;
    text-transform: capitalize;
}
.productInfo .likeIcon a span {
    padding-left: 5px;
}
.productInfo .shareIcon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.productInfo .shareIcon h5 {
    color: #333333;
    font-size: 15px;
    font-weight: 700;
    line-height: 1;
    margin-right: 10px;
    text-transform: uppercase;
    margin-bottom: 0;
}
.productInfo .shareIcon a {
    color: #666666;
    text-align: center;
    display: inline-block;
    font-size: 16px;
    padding: 0 8px;
    margin-right: 5px;
}
.mainImage .slick-list{
    border: 1px solid rgba(0,0,0,0.125);
    margin-bottom: 10px;
}
.mainImage .slick-list .slick-track img {
    display: inline-block;
}
.thumbnail1pxSolid li{
    border: 1px solid rgba(0,0,0,0.125);
    margin-right: 5px;
}
.thumbnail1pxSolid li a img {
    opacity: 0.7;
}

.thumbnail1pxSolid li.slick-active a img{
    // -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
    // filter: grayscale(0%);
    opacity: 1;
}

@media (max-width: 767.98px) {
    .thumbnail1pxSolid li a img{
        width: 100px;
        height: 100px;
    }
}
