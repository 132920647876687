@import 'reset';
@import 'colorfonts';
@import 'loading';
@import 'frontend';
@import 'header';
@import 'login';
@import 'maindashboard';
@import 'card';
@import 'gridbars';
@import 'cart';
@import 'shop';
@import 'productinfo';
@import 'profile';
@import 'admin';
@import 'master';
@import 'mainsidedashboard';
@import 'mainsidedrawer';
@import 'table';
@import 'collapse';
@import 'overlay';
@import 'backdrop';
@import 'grid';
@import 'buttons';
@import 'newbtn';
@import 'footer';
@import 'droparea';
@import 'cardloading';
@import 'ringloader';
@import 'productloading';
@import 'notifications';
@import 'reactdatepicker';
@import 'backendmedia';

/***********************************************
                    GENERAL
***********************************************/
*, ::after, ::before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
::selection {
    background: #58748B;
    text-shadow: none;
    color: #ffffff;
}
.clearfix:before, .clearfix:after, .dl-horizontal dd:before, .dl-horizontal dd:after, .container:before, .container:after, .container-fluid:before, .container-fluid:after, .row:before, .row:after, .form-horizontal .form-group:before, .form-horizontal .form-group:after, .btn-toolbar:before, .btn-toolbar:after, .btn-group-vertical > .btn-group:before, .btn-group-vertical > .btn-group:after, .nav:before, .nav:after, .navbar:before, .navbar:after, .navbar-header:before, .navbar-header:after, .navbar-collapse:before, .navbar-collapse:after, .pager:before, .pager:after, .panel-body:before, .panel-body:after, .modal-footer:before, .modal-footer:after, #topbar:before, #topbar:after {
    content: " ";
    display: table;
}
.clearfix:after, .dl-horizontal dd:after, .container:after, .container-fluid:after, .row:after, .form-horizontal .form-group:after, .btn-toolbar:after, .btn-group-vertical > .btn-group:after, .nav:after, .navbar:after, .navbar-header:after, .navbar-collapse:after, .pager:after, .panel-body:after, .modal-footer:after, #topbar:after {
    clear: both;
}
html, body, img, figure {
    max-width: 100%;
}
html, body {
    // height: 100%;
    overflow-y: auto;
}
img {
    vertical-align: middle;
    border: 0;
    border-style: none;
}
body {
    font-family: $workSans;
    // color: #fff;
    background: #222;
}
.container {
    width:1140px;
    margin: 0 auto;
}
.page_container {
    min-height: 90vh;
    padding-top: 65px;
    display: block;
}
a {
    // color: #33cabb;
    color: #1E90FF;
    text-decoration: none;
    background-color: transparent;
}
.h1, h1 {
    font-size: 2.5rem;
}
.h3, h3 {
    font-size: 23px;
}
.h4, h4 {
    font-size: 1.5rem;
}
.h5, h5 {
    font-size: 1.2rem;
}
.h6, h6 {
    font-size: 14px;
    font-weight: 400;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin-bottom: .5rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: inherit;
}
h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
}
b, strong {
    font-weight: bolder;
}
p {
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;
    // margin-block-start: 1em;
    // margin-block-end: 1em;
    // margin-inline-start: 0px;
    // margin-inline-end: 0px;
}
.fw200 {
    font-weight: 200 !important;
}
.fw500 {
    font-weight: 500 !important;
}
.fs10 {
    font-size: 10px !important;
}
.fs12 {
    font-size: 12px !important;
}
.fs14 {
    font-size: 14px !important;
}
.fs16 {
    font-size: 16px !important;
}
.fs17 {
    font-size: 17px !important;
}
.fs20 {
    font-size: 20px !important;
}
.fs28 {
    font-size: 28px !important;
}
.fs40 {
    font-size: 40px !important;
}
.fs170 {
    font-size: 170px !important;
}
.fs200 {
    font-size: 200px;
}
.borderTop1pxSolid {
    border-top: 1px solid;
}
.borderBottom1pxSolid {
    border-bottom: 1px solid;
}
// .bg_ui_general {
//     background-image: url(/assets/images/general.png);
// }
.bg_white {
    background: $colorWhite;
}
.bg_light {
    background: $colorLight;
}
.bg_dark {
    background: $colorDark;
}
.bg_table {
    background: #33cabb;
}
.textBlack {
    color: $colorBlack !important;
}
.textGray {
    color: $colorGray !important;
}
.textWhiteImportant {
    color: $colorWhite !important;
}
.textWhite {
    color: $colorWhite;
}
.textDanger {
    color: $colorRed !important;
}
.textSuccess {
    color: $textSuccess !important;
}
.textCenter {
    text-align: center !important;
}
.textRight {
    text-align: right !important;
}
.textUppercase {
    text-transform: uppercase !important;
}
input {
    outline: none !important;
    font: inherit;
    color: inherit;
}
  
button, input {
    box-shadow: none !important;
    overflow: visible;
}

button, select {
    text-transform: none;
}
.small, small {
    font-size: 80%;
    font-weight: 400;
}

input[type=text], input[type=password], input[type=email], textarea {
    appearance: none !important;
}

input[type="checkbox"], input[type="radio"] {
    box-sizing: border-box;
    cursor: pointer;
    line-height: normal;
}

input[type=checkbox], input[type=radio] {
    margin: 4px 0 0;
    padding: 0;
}

[type=reset],
[type=submit],
button,
html [type=button] {
    -webkit-appearance: button;
}
  
label {
    display: inline-block;
    margin-bottom: 0;
    max-width: 100%;
}
.checkbox, .radio {
    position: relative;
    display: block;
}

.clipCheck {
    margin-bottom: 10px;
    margin-top: 10px;
    padding-left: 0;
}
.mainLoader {
    position: absolute; /* or absolute */
    top: 50%;
    left: 50%;
    margin-top: -20px;
    margin-left: -20px;
}
.border1pxSolid {
    border: 1px solid rgba(0,0,0,.125) !important;
}
.border1pxRight {
    border-right: 1px solid rgba(0,0,0,.125);
}
.borderRight {
    border-right: 1px solid #eaeaea;
}
.textNowrap {
    white-space: nowrap !important;
}
.donut {
    display: inline-block;
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 100%;
    border: 4px solid #acb7bf;
    position: relative;
    top: .15rem;
    margin-right: .3rem;
}
.donutDanger {
    border-color: $colorRed;
}
.textMuted {
    color: #c0bdd0 !important;
}
.cartLink {
    position:absolute;
    background-color:#fe4536;
    color:#fff;
    font-size:13px;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    font-weight: 500;
    border-radius: 10px;
    line-height: 21px;
    top:10px;
    left:auto;
    right: -2px;
    padding: 0 0 0 1px;
    height: 20px;
    min-width: 20px;
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    display: inline-block;
    // -webkit-transform:translate(-65%,-90%);
    // -moz-transform:translate(-65%,-90%);
    // transform:translate(-65%,-90%);
    // padding:3px;
    // border-radius:100%;
    // background-color: rgba(231,76,60,0.8);
}
.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.textSecondary {
    color: #e4e7ea !important;
}
.lead {
    font-size: 16px;
    line-height: 32px;
    font-weight: 300;
}
.floatRight {
    float: right !important;
}
.bgBlackOp15 {
    background-color: rgba(0, 0, 0, 0.15);
}
.bgBlackOp20 {
    background-color: rgba(0, 0, 0, 0.2);
}
.opacity60 {
    opacity: .6 !important;
}
.opacity70 {
    opacity: .7 !important;
}
.opacity80 {
    opacity: .8 !important;
}
.opacity90 {
    opacity: .9 !important;
}
.loadingContainer {
    display: flex;
    min-height: 70px;
}
.vaInherit {
    vertical-align: inherit !important;
}
.grayBackground {
    background: #2F4F4F !important;
    color: white !important;
    border-color: #2F4F4F !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
}
.greenBackground {
    background: #008000 !important;
    color: white !important;
    border-color: #008000 !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
}
.blueBackground {
    background: $colorMoreBlue !important;
    color: white !important;
    border-color: $colorMoreBlue !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
}
.redBackground {
    background: $colorRed !important;
    color: white !important;
    border-color: $colorRed !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
}
.salmonBackground {
    background:#E9967A !important;
    color:white !important;
    border-color:#E9967A !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
}
.midnightblueBackground {
    background:#191970 !important;
    color:white !important;
    border-color:#191970 !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
}
.purpleBackground {
    background:#800080 !important;
    color:white !important;
    border-color:#800080 !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
}
.lightblueBackground {
    background:#add8e6 !important;
    color:black !important;
    border-color:#add8e6 !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
}
.border4 {
    border-radius: 4px;
}
.divider {
    border-left: 1px solid rgba(77,82,89,1);
    height: 20px;
    align-self: center;
    margin: 0 12px;
}
.borderbottom {
    border-bottom: 1px solid #868e96 !important;
}
.borderbottomaddress {
    border-bottom: 1px solid #e4e9f0 !important;
}
.disabled {
    pointer-events: none;
    color: rgba(0, 0, 0, 0.3) !important;
}

.disabled2 {
    pointer-events: none;
    color: rgba(0, 0, 0, 0.3) !important;
    background-color: rgba(0, 0, 0, 0.3) !important;
    border-color: rgba(0, 0, 0, 0.3) !important;
}

.slick-initialized .slick-slide {
    outline: none;
}

.cursorpointer {
    cursor: pointer;
}
tr.verticalCenter td {
    vertical-align: inherit !important;
}

/***********************************************
                AUTH CONTAINER
***********************************************/
.auth_container {
    min-height: 90vh;
    display: flex;
}
/***********************************************
                TOGGLE BUTTON
***********************************************/
.toggle_button {
    display: flex;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
}

.toggle_button:focus,.toggle_button:active,.toggle_button:hover {
    outline: none !important;
}

.toggle_button .icon {
    font-size: 26px;
}

.toggle_button .icon:focus, .toggle_button .icon:active, .toggle_button .icon:hover {
    outline: none !important;
}

/***********************************************
                   FILTER
***********************************************/
.filterDrawer{
    height: 100%;
    background: #ffffff;
    box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    max-width: 400px;
    z-index: 200;
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
    transition: transform 0.2s ease;
    will-change: transform;
}
.filterDrawer.open {
    transform: translateX(0);
    overflow: hidden;
    position: absolute;
}
/***********************************************
                SIDE DRAWER
***********************************************/
.sideDrawerLogo {
    // padding: 15px;
    padding: 3px;
    text-align: center;
    border-bottom: 1px solid rgba(255,255,255,0.2)
}
.sideDrawer{
    height: 100%;
    background: $sideDrawerBg;
    box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    max-width: 400px;
    z-index: 950;
    -webkit-transform: translateX(-110%);
    -moz-transform: translateX(-110%);
    -ms-transform: translateX(-110%);
    transform: translateX(-110%);
    transition: transform 0.2s ease;
    will-change: transform;
}
.sideDrawer.open {
    transform: translateX(0);
    overflow-y: auto;
    // height: 100%;
    top: 0%;
    bottom: 0%;
    position: fixed;
    // -webkit-overflow-scrolling: touch;
}
.sideDrawer.open ul {
    max-height: 50vh;
    
}
.sideDrawer ul {
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // padding-left: 10px;
}
.sideDrawer a, .sideDrawer .icon {
    color: $sideDrawerAColor;
    text-decoration: none;
    font-size: 1.2rem;
}
.sideDrawer a:hover,
.sideDrawer a:active {
    color: $sideDrawerAHover;
}
.sideDrawer > ul > li > a{
    border: none;
    display: block;
    position: relative;
    padding: 5px;
    border-radius: 0;
    margin: 0;
    text-align: -webkit-match-parent;
}
.sideDrawer > ul > li > a.active {
    background-color: $sideDrawerBGColor;
    border-right: 4px solid $sideDrawerRightBorder;
}
.sideDrawer > ul > li a.active,
.sideDrawer > ul > li a.active .icon{
    color: $sideDrawerAHover;
}
.sideDrawer .itemContent {
    align-items: center;
    display: table-row;
}
.sideDrawer .itemIcon {
    padding: 10px 15px;
    width: 55px;
}
.sideDrawer .itemInner {
    display: table-cell;
    width: 100%;
    vertical-align: middle;
    white-space: nowrap;
    align-items: center;
    padding: 10px 15px 10px 10px;
}
.sideDrawer .iconContent {
    background: $sideDrawerBg;
    box-shadow: none;
    height: 53px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.sideDrawer .iconContent > ul {
    flex-direction: row;
    position: relative;
    justify-content: space-between;
    // float: right;
}
.sideDrawer .iconContent > ul > li {
    float: left;
    position: relative;
    display: block;
}
.sideDrawer .iconContent > li:before {
    content: "";
    position: absolute;
    width: 1px;
    right: 0;
    top: 5px;
    bottom: 5px;
    background: -moz-linear-gradient(top, rgba(206,206,206,0) 0%, rgba(206,206,206,0.2) 50%, rgba(206,206,206,0) 100%);
    background: -webkit-linear-gradient(top, rgba(206,206,206,0) 0%, rgba(206,206,206,0.2) 50%, rgba(206,206,206,0) 100%);
    background: linear-gradient(to bottom, rgba(206,206,206,0) 0%, rgba(206,206,206,0.2) 50%, rgba(206,206,206,0) 100%);
    box-sizing: border-box;
}
.sideDrawer .iconContent > ul > li > a {
    letter-spacing: 0;
    opacity: 0.9;
    padding: 7px;
    position: relative;
    text-align: center;
    display: block;
    
}
.sideDrawer .iconContent > ul > li > a.active {
    background-color: $sideDrawerBGColor;
    border-bottom: 4px solid $sideDrawerRightBorder;
}
.sideDrawer .iconContent > ul > li > a.active > .iconWrapper > .icon {
    color: $colorWhite;
}
.sideDrawer .iconContent .iconWrapper {
    padding: 9px;
}
.sidenavTitle {
    padding: 5px 20px;
    background-color: #353535;
    color: #a1a1a1;
    text-transform: uppercase;
    font-weight: 500;
    display: block;

}
/***********************************************
                   DASHBOARD
***********************************************/

.pageWrapper .pageLeftNav{
    position: fixed;
    top: 56px;
    left: 0;
    bottom: 0;
    width: 260px;
    // background-color: #3f4a59;
    background-color: #272723;
    white-space: nowrap;
    -webkit-box-shadow: 0px 0px 5px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 5px rgba(0,0,0,0.1);
    display: -webkit-box;
    display: flex;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    z-index: 45;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transition: .3s ease;
    -moz-transition: .3s ease;
    transition: .3s ease;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: transform;
}
.sidebarNavigation {
    position: relative;
    overflow: hidden;
    -webkit-box-flex: 1;
    flex: 1 1;
}
.agContainer {
    -ms-touch-action: auto;
    touch-action: auto;
    overflow: hidden !important;
    -ms-overflow-style: none;
}
.menu {
    list-style: none;
    padding-left: 0;
    margin-bottom: 30px;
    margin-top: 0;
}
.menuItem {
    vertical-align: top;
    -webkit-transition: opacity 0.2s linear;
    transition: opacity 0.2s linear;
}
.menuItem a {
    text-decoration: none;
}
.menuItem > a.active {
    // background-color: #2ecd99;
    background-color: #168970;
    color: #fff;
}
.menuLink {
    position: relative;
    height: 40px;
    padding: 0 12px;
    font-weight: 400;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-transition: .2s linear;
    transition: .2s linear;
    // color: #fff;
}
.pageLeftNav .navigationHeader {
    font-size: 0.6875rem;
    display: block;
    text-transform: uppercase;
    font-weight: 500;
    color: #a1a1a1;
    padding: 11px 15px;
}

// .pageWrapper {
//     min-height: 100vh;
//     // display: inherit;
//     align-items: center;
//     justify-content: center;
//     margin: auto;
//     position: relative;
//     width: 100%;
//     // background: #fafafa;
//     // background-size: cover;
//     // background-image: url(/assets/images/login.jpg);
//     // background-repeat: no-repeat;
//     overflow: hidden;
// }
// .pageWrapper .pageContainer {
//     padding: 1rem 5rem 5rem 5rem;
//     margin-bottom: 5.38rem;
//     width: 100%;
//     align-self: center;
//     position: relative;
//     z-index: 2;
// }
// .pageWrapper .pageContainer .pageInner{
//     min-width: 14rem;
//     max-width: 82rem;
//     margin: 0 auto;
//     padding: 3rem;
//     border-radius: 10px;
//     overflow: hidden;
//     position: relative;
//     z-index: 2;
//     display: flex;
// }
// .pageWrapper .pageContainer .pageInner .pageLeftNav{
//     width: 250px;
//     display: inline-table;
//     // padding: 2rem;
//     margin-right: 20px;
//     // box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 1px 5px 0 rgba(0, 0, 0, 0.1);
// }
// .pageLeftNav .navigationHeader {
//     position: relative;
//     font-family: Roboto,sans-serif;
//     display: block;
//     text-transform: uppercase;
//     letter-spacing: 1px;
//     font-size: 0.6875rem;
//     font-weight: 500;
//     line-height: 32px;
//     padding: 1rem 20px 0.5rem;
//     color: #fff;
//     opacity: 0.7;
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
// }
// .pageWrapper .pageLeftNav .pageLeftNavScroll{
//     position: relative;
//     overflow: hidden;
//     width: auto;
//     height: 100%;
// }
// .pageWrapper .pageLeftNav .pageLeftNavScroll ul.sideNav {
//     background: #2a3e4c;
//     border: none;
//     height: 100%;
//     width: 100%;
//     position: relative;
//     border-radius: 0;
//     margin: 0;
//     overflow-y: auto;
//     background-color: #fff;
//     backface-visibility: hidden;
// }
// .pageWrapper .pageLeftNav .pageLeftNavScroll .navbarNav {
//     float: left;
//     margin: 0;
// }
// .pageWrapper .pageLeftNav .pageLeftNavScroll .nav {
//     padding-left: 0;
//     margin-bottom: 0;
//     list-style: none;
// }
// .pageLeftNav .sideNav li {
//     width: 260px;
// }
// .navbarNav > li {
//     float: left;
// }
// .nav > li {
//     position: relative;
//     display: block;
// }
// .pageLeftNav .sideNav li.navigationHeader > span {
//     font-size: 11px;
//     display: block;
//     text-transform: uppercase;
//     font-weight: 500;
//     color: #878787;
//     padding: 11px 15px;
// }
// .pageWrapper .pageContainer .pageInner .pageLeftNav .pageLeftNavWrapper{
//     background-color: $colorWhite;
//     // box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
//     box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.15);
// }
// .pageWrapper .pageContainer .pageInner .pageLeftNav h2{
//     font-size: 18px;
//     font-weight: 600;
//     border-bottom: 1px solid rgba(0, 0, 0, 0.2);
//     padding: 15px;
//     margin: 0 !important;
// }
// .pageWrapper .pageContainer .pageInner .pageLeftNav .links{
//     display: flex;
//     flex-direction: column;
    
// }
// .pageWrapper .pageContainer .pageInner .pageLeftNav .links .linksEach{
//     color: $colorDark;
//     text-decoration: none;
//     border-bottom: 1px solid rgba(0, 0, 0, 0.2);
//     padding: 10px 15px;
// }
// .pageWrapper .pageContainer .pageInner .pageLeftNav .links .linksEach:last-child{
//     border-bottom: 0;
// }
// .pageWrapper .pageContainer .pageInner .pageLeftNav .links a:hover,
// .pageWrapper .pageContainer .pageInner .pageLeftNav .links a:active,
// .pageWrapper .pageContainer .pageInner .pageLeftNav .links a.active{
//     background-color: $colorDark;
//     color: $colorWhite;
// }
// .pageWrapper .pageRight{
//     // width: 1200px;
//     min-height: 617px;
//     margin-left: 225px;
//     padding: 70px 20px;
//     background: #edf1f5;
//     position: relative;
//     -webkit-transition: all 0.4s ease;
//     -moz-transition: all 0.4s ease;
//     transition: all 0.4s ease;
//     left: 0;
//     // display: flex;
//     // width: 100%;
//     // background-color: $colorWhite;
//     // box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 1px 5px 0 rgba(0, 0, 0, 0.1);
// }

.innerWrapper {
    padding-right: 1.15rem;
    position: relative;
    width: 100%;
    min-height: 1px;
}
.innerLeft {
    // padding-left: 1.15rem;
    padding-right: 1.15rem;
    position: relative;
    width: 100%;
    min-height: 1px;
}
.innerLeft .profileHeader{
    background-size: cover;
    // background-image: url(/assets/images/login.jpg);
    background-repeat: no-repeat;
    // box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.15);
}
.innerRight {
    padding-left: 1.15rem;
    padding-right: 1.15rem;
    position: relative;
    // width: 100%;
    min-height: 1px;
    width: 100%;
    // background-color: $colorWhite;
    // box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 1px 5px 0 rgba(0, 0, 0, 0.1);
}
.profileInfo, .profileSocialCounts {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
}
.masterContent .card.profileInfo {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1.53rem;
    padding-bottom: 1.15rem;
    box-shadow: 0 2px 3px rgba(0,0,0,0.03);
    // box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
    // box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.15);
}
.profileContent .card.profileInfo {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1.53rem;
    padding-bottom: 1.15rem;
    box-shadow: 0 2px 3px rgba(0,0,0,0.03);
    // box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
    // box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.15);
}
.profileInfo .profileSocialCounts {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    color: #514d6a;
}
.avatar {
    width: 2.69rem;
    height: 2.69rem;
    background-color: #eef0f4;
    border-color: #b8beca;
    display: inline-block;
    overflow: hidden;
    border-radius: 500px;
}
.avatarNew {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    border-radius: 100%;
    background-color: #f5f6f7;
    color: #8b95a5;
    text-transform: uppercase;
}
.avatarBordered {
    border: 4px solid rgba(255,255,255,0.5);
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
}
.avatarXl {
    width: 64px;
    height: 64px;
    line-height: 64px;
    font-size: 1.25rem;
}
.avatar110 {
    width: 6rem;
    height: 6rem;
}
.avatarBorder {
    border: 4px solid #fff;
}
.avatar img {
    width: 100%;
    max-width: 100%;
    height: auto;
    vertical-align: middle;
    border-style: none;
}

/***********************************************
                    FORM
***********************************************/
.formWrapper {
    font-size: 15px;
    line-height: 15px;
    color: rgba(0,0,0,.65);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    display: inline-block;
    width: 100%;
}
.mobileIconPosition {
    position: inherit;
    top: 100%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    line-height: 0;
    color: rgba(0,0,0,.25);
    z-index: 3;
    margin-left: 10px;
    text-align: center;
    right: 0 !important;
    cursor: pointer;
}
.iconPosition {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    line-height: 0;
    color: rgba(0,0,0,.25);
    z-index: 3;
    margin-left: 10px;
    cursor: pointer;
}
.agraicon {
    display: inline-block;
    font-style: normal;
    vertical-align: -.125em;
    text-align: center;
    text-transform: none;
    line-height: 1;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.formGroup {
    display: block;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 40px;
    position: relative;
}
.formGroup .controlLabel {
    font-weight: normal;
    color: #858585;
    display: inline-block;
    max-width: 100%;
    margin-left: 1px;
    margin-bottom: 10px;
    position: relative;
}
.formGroup .formGroupWrapper {
    margin: 0;
    padding: 0;
    list-style: none;
    line-height: unset;
    cursor: pointer;
    display: inline-block;
}
.formGroup .formGroupWrapper .checkboxWrapper {
    margin: 0;
    padding: 0;
    list-style: none;
    white-space: nowrap;
    cursor: pointer;
    outline: none;
    display: inline-block;
    line-height: 1;
    position: relative;
    vertical-align: middle;
    top: -.09em;
}
// .clipCheck label {
//     cursor: pointer;
//     display: inline-block;
//     margin-right: 15px;
//     padding-left: 30px;
//     position: relative;
//     line-height: 23px;
//     transition: border 0.2s linear 0s, color 0.2s linear 0s;
//     white-space: nowrap;
//     min-height: 20px;
//     margin-bottom: 0;
// }
// .clipCheck input[type="checkbox"]:checked + label::before {
//     border-color: #58748B;
//     border-width: 10px;
// }
// .clipCheck label::before {
//     background-color: $colorWhite;
//     border: 1px solid #c8c7cc;
//     content: "";
//     display: inline-block;
//     height: 20px;
//     left: 0;
//     margin-right: 10px;
//     position: absolute;
//     width: 20px;
//     border-radius: 0;
//     top: 1px;
//     transition: border 0.2s linear 0s, color 0.2s linear 0s;
// }
// .clipCheck input[type=checkbox]:checked + label::after {
//     color: $colorBlack;
//     content: "\f00c";
//     font-family: "FontAwesome";
// }
// .clipCheck label::after {
//     display: inline-block;
//     font-size: 11px;
//     height: 19px;
//     left: 4px;
//     position: absolute;
//     top: -1px;
//     transition: border 0.2s linear 0s, color 0.2s linear 0s;
//     width: 19px;
// }
// .clipCheck input[type="checkbox"] {
//     opacity: 0;
// }
// .checkbox input[type=checkbox], .radio input[type=radio] {
//     position: absolute;
//     margin-left: -20px;
// }
.formActions {
    border-top: 1px solid #e4e9f0;
    padding-top: 1.9rem;
    margin: 1.53rem 0 0.5rem 0;
}
.loginLink {
    display: inline-flex;
    margin: 6px;
}
.loginLink .myButton {
    padding: 0 6px;
}
.loginLink .linkText {
    margin-left: 6px;
    margin-right: 6px;
    display: contents;
}
.registerLink {
    display: inline-flex;
    margin-bottom: 0.5rem;
}
.registerLink .myButton {
    padding: 0 6px 0 0;
}
.registerLink .linkText {
    margin-left: 6px;
    margin-right: 6px;
    display: contents;
}
.form-control {
    padding-left: 32px !important;
}
.form-control::-moz-placeholder {
    color: #9b9b9f;
    opacity: 1;
}
.form-control:-ms-input-placeholder {
    color: #9b9b9f;
    opacity: 1;
}
.form-control::-webkit-input-placeholder {
    color: #9b9b9f;
    opacity: 1;
}
//   textarea, input[type="text"], input[type="password"], input[type="datetime"], input[type="daterange"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"] {
//     -webkit-appearance: none;
//     -moz-appearance: none;
//     appearance: none;
//     background-color: #FFFFFF !important;
//     background-image: none !important;
//     border: 1px solid #c8c7cc;
//     border-radius: 4px;
//     width: 100%;
//     color: #5b5b60 !important;
//     font-family: inherit !important;
//     font-size: 14px !important;
//     line-height: 1.2 !important;
//     padding: 5px 4px !important;
//     box-shadow: none !important;
//   }
textarea, input[type="text"], input[type="password"], input[type="datetime"], input[type="daterange"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    display: inline-block;
    padding: 7px 13px;
    width: 100%;
    height: 42px;
    font-size: 15px;
    line-height: 1.5;
    color: rgba(0,0,0,.65);
    background-color: $colorWhite;
    background-image: none;
    border: 1px solid #cccccc !important;
    border-radius: 4px;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}
textarea:hover, input[type="text"]:hover, input[type="password"]:hover, input[type="datetime"]:hover, input[type="daterange"]:hover, input[type="datetime-local"]:hover, input[type="date"]:hover, input[type="month"]:hover, input[type="time"]:hover, input[type="week"]:hover, input[type="number"]:hover, input[type="email"]:hover, input[type="url"]:hover, input[type="search"]:hover, input[type="tel"]:hover, input[type="color"]:hover {
    border-color: #aeacb4 !important;
}
textarea:focus, input[type="text"]:focus, input[type="password"]:focus, input[type="datetime"]:focus, input[type="daterange"]:focus, input[type="datetime-local"]:focus, input[type="date"]:focus, input[type="month"]:focus, input[type="time"]:focus, input[type="week"]:focus, input[type="number"]:focus, input[type="email"]:focus, input[type="url"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="color"]:focus {
    box-shadow: none !important;
    border-color: #bbbac0 !important;
    background-color: #F8F8F8 !important;
}
select {
    background-color: #FFFFFF !important;
    background-image: none !important;
    border: 1px solid #e4e9f0;
    border-radius: 0;
    color: rgba(0, 0, 0, 0.65) !important;
    font-family: inherit !important;
    font-size: 14px !important;
    line-height: 1.2 !important;
    padding: 5px 4px !important;
    box-shadow: none !important;
    width: 100%;
    height: 32px;
}
select:hover {
    border-color: #aeacb4 !important;
}
select:focus {
    box-shadow: none !important;
    border-color: #bbbac0 !important;
    background-color: #F8F8F8 !important;
}
.hasError {
    border-color: $colorRed !important;
}
.errorLabel {
    position: absolute;
    font-size: 14px;
    color: $colorRed;
    margin: 3px;
}
.successLabel {
    position: relative;
    font-size: 14px;
    color: $colorGreen;
    margin: 3px;
}
.closeRed {
    color: $colorRed;
}
/***********************************************
                    HOME
***********************************************/
.featuredContainer {
    width: 100%;
    float: left;
}
.featuredContainer .featuredRow {
    margin: 0 auto;
    max-width: 1200px;
}
.featuredContainer .featuredSlider {
    margin: 0 auto;
}
.featuredBg {
    background-repeat: no-repeat !important;
    background-position: center !important;
}
.featuredBgImage {
    height: 650px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.featuredBgImage .contentInner {
    padding: 0 80px;
}
.featuredImage {
    width: 570px;
    height: 570px;
    position:relative;
    background-size: cover !important;
    background-position: center left !important;
}
.featuredAction {
    position: absolute;
    top: 65%;
    left: 8%;
}
.featuredAction .tag {
    // background: #82828287;
    // color: #ffffff;
    // padding: 0px 10px;
    text-transform: uppercase;
    display: table;
}
.featuredAction .tag.title {
    font-size: 35px;
    font-weight: 700;
}
.featuredAction .tag.lowTitle {
    font-size: 25px;
    font-weight: 300;
}
/***********************************************
                   TRISULA
***********************************************/
.trisula {
    color: $colorDark;
    background-color: rgba(0, 0, 0, 0.2);
    height: 80px;
    position: relative;
    // -webkit-border-radius: 6px;
    // border-radius: 6px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}
.trisula a {
    text-decoration: none;
    color: inherit;
}
.trisulaInner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    padding: 56px 0 56px;
    position: relative;
}
.trisulaBorder {
    border-bottom: 1px solid #e5e5e5;
}
.trisulaFeatures {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-right: 130px;
}
.trisulaBorder a .trisulaFeatures {
    border-right: 1px solid #eaeaea;
}
.trisulaBorder a:last-child .trisulaFeatures {
    padding-right: 0;
    border-right: none;
}
.trisulaBorder .featuresIcon {
    font-size: 34px;
    padding-right: 20px;
}
.trisulaFeatures .featuresContent h5 {
    color: #333333;
    font-size: 16px;
    line-height: 1.2;
    font-weight: 400;
    padding-bottom: 0;
    margin-bottom: 0;
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
}

.trisulaFeatures .featuresContent p {
    color: #333333;
    font-size: 15px;
    line-height: 1.5;
    margin-bottom: 0;
}

.trisulaOhid {
    overflow: hidden;
    z-index: 1;
    // margin-bottom: 27px;
    margin-top: 50px;
}
.trisulaLink {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: block;
    z-index: 3;
}
.trisulaCaptionLeft {
    right: auto;
    left: 5%;
}
.trisulaCaptionLeft, .trisulaCaptionRight {
    top: 55%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.trisulaCaptionLeft, .trisulaCaptionRight {
    z-index: 2;
    width: 70%;
    position: absolute;
}
.trisulaImg {
    -webkit-filter: drop-shadow(1px 1px 3px rgba(0,0,0,0.2));
    position: absolute;
    width: 120px;
    right: 1%;
    top: 5%;
    z-index: 5;
}
.trisulaTitle {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    display: table;
}
p.trisulaDesc {
    margin-top: 0;
    margin-bottom: 5px;
}
/***********************************************
                   BANNER
***********************************************/
.banner {
    color: $colorDark;
    background-color: $colorWhite;
    height: 171.5px;
    position: relative;
    -webkit-box-shadow: 0 2px 3px rgba(0,0,0,0.1);
    box-shadow: 0 2px 3px rgba(0,0,0,0.1);
    // -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.15), 0 0 1px rgba(0,0,0,.15);
    // box-shadow: 0 1px 1px rgba(0,0,0,.15), 0 0 1px rgba(0,0,0,.15);
    // border: 1px solid rgba(0, 0, 0, 0.2);
    // -webkit-border-radius: 6px;
    // border-radius: 6px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}
.banner a {
    text-decoration: none;
    color: inherit;
}
.bannerOhid {
    overflow: hidden;
    z-index: 1;
    margin-bottom: 27px;
}
.bannerOhid:last-child {
    margin-bottom: 0;
}
.bannerLink {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: block;
    z-index: 3;
}
.bannerCaptionLeft {
    right: auto;
    left: 5%;
}
.bannerCaptionLeft, .bannerCaptionRight {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.bannerCaptionLeft, .bannerCaptionRight {
    z-index: 2;
    width: 70%;
    position: absolute;
}
.bannerImg {
    -webkit-filter: drop-shadow(1px 1px 3px rgba(0,0,0,0.2));
    position: absolute;
    width: 150px;
    right: 0%;
    top: 7%;
}
.bannerTitle {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    display: table;
}
p.bannerDesc {
    margin-top: 0;
    margin-bottom: 0;
}
p.bannerDesc2 {
    margin-top: 0;
}
/***********************************************
                   BUTTON
***********************************************/

.agrapanaBtn, .agrapanaBtn:active, .agrapanaBtn:focus {
    outline: 0;
}

.agrapanaBtn::before {
    position: absolute;
    top: -1px;
    left: -1px;
    bottom: -1px;
    right: -1px;
    background: $colorWhite;
    opacity: .35;
    content: "";
    border-radius: inherit;
    z-index: 1;
    -webkit-transition: opacity .2s;
    -o-transition: opacity .2s;
    transition: opacity .2s;
    pointer-events: none;
    display: none;
}

.agrapanaBtn {
    line-height: 1;
    display: initial;
    font-weight: 400;
    text-align: center;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 5px 15px;
    font-size: 14px;
    border-radius: 4px;
    height: 34px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: all .3s cubic cubic-bezier(.645,.045,.355,1);
    -o-transition: all .3s cubic cubic-bezier(.645,.045,.355,1);
    transition: all .3s cubic cubic-bezier(.645,.045,.355,1);
    position: relative;
    -webkit-box-shadow: 0 2px 0 rgba(0,0,0,.015);
    box-shadow: 0 2px 0 rgba(0,0,0,.015);
    color: rgba(0,0,0,.65);
    background-color: $colorWhite;
    border-color: #e4e9f0;
    text-decoration: none;
}

.agrapanaBtn > span {
    pointer-events: none;
}

.agrapanaBtnPrimary {
    color: $colorWhite !important;
    background-color: #0190fe;
    border-color: #0190fe;
    text-shadow: 0 -1px 0 rgba(0,0,0,.12);
    -webkit-box-shadow: 0 2px 0 rgba(0,0,0,.035);
    box-shadow: 0 2px 0 rgba(0,0,0,.035);
}

.agrapanaBtn.purpleBackground.p8.mr5.br50,
.agrapanaBtn.greenBackground.p8.mr5.br50,
.agrapanaBtn.lightblueBackground.p8.mr5.br50,
.agrapanaBtn.redBackground.p8.mr5.br50 {
    width: 20px;
    height: 20px;
}

.btnGroup {
    position: relative;
    display: inline-block;
}

.btnGroup > .agraBtn:first-child:not(:last-child) {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
}

.btnGroup > .agraBtn:last-child {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
}

.btnGroup > .agraBtn:first-child {
    margin-left: 0;
}

.btnGroup .agraBtn {
    border-radius: 0;
}

.btnGroup > .agraBtn {
    position: relative;
    line-height: 30px;
}

.agraBtn, .agraBtn:active, .agraBtn:focus {
    outline: 0;
}

.agraBtn {
    line-height: 1.5;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 1px 23px 0 23px;
    font-size: 13px;
    border-radius: 4px;
    height: 32px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: all .3s cubic cubic-bezier(.645,.045,.355,1);
    -o-transition: all .3s cubic-bezier(.645,.045,.355,1);
    transition: all 3s cubic-bezier(.645,.045,.355,1);
    position: relative;
    -webkit-box-shadow: 0 2px 0 rgba(0,0,0,.015);
    box-shadow: 0 2px 0 rgba(0,0,0,.015);
    color: rgba(0,0,0,.65);
    background-color: $colorWhite;
    border-color: #e4e9f0;
}

.agraBtn > span {
    pointer-events: none;
}

// CUSTOMER TABLE
.tableResponsiveSm #customerTable tr td:first-child {
    max-width: 52px;
    width: 52px;
}
.tableResponsiveSm #customerTable tr td.customerTableId {
    max-width: 220px;
    width: 220px;
}
.tableResponsiveSm #customerTable tr td.customerTableName {
    max-width: 325px;
    width: 325px;
}

// PRODUCT TABLE
.tableResponsiveSm #productTable tr td:first-child {
    max-width: 65px;
    width: 65px;
}
.tableResponsiveSm #productTable tr td.productTableName {
    max-width: 520px;
    width: 520px;
}
.tableResponsiveSm #productTable tr td.productTableId,
.tableResponsiveSm #productTable tr td.productTableCategories,
.tableResponsiveSm #productTable tr td.productTablePrice {
    max-width: 200px;
    width: 200px;
}
.tableResponsiveSm #productTable tr td.productTableQuantity {
    max-width: 100px;
    width: 100px;
}
/***********************************************
                    MEDIA
***********************************************/
@media(max-width: 575.98px) {
    .tableResponsiveSm {
        display: block;
        width: 100%;
        overflow-x:auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    .tableResponsiveSm > .table > tbody > tr > td, .tableResponsiveSm > .table > tbody > tr > th, .tableResponsiveSm > .table > tfoot > tr > td, .tableResponsiveSm > .table > tfoot > tr > th, .tableResponsiveSm > .table > thead > tr > td, .tableResponsiveSm > .table > thead > tr > th {
        white-space: nowrap;
    }
}
@media (max-width: 767.98px) {
    .zoid-outlet {
        width: 270px !important;
    }
    .footercopy {
        padding-bottom: 30px;
    }
    .footersocial ul li {
        margin-right: 3px;
    }
    .footertext .list li a {
        font-size: 13px;
    }
    .footerpertama img, .footerkedua .mb30, .footertext .mb30, .footersocial .mb30 {
        margin-bottom: 0 !important;
    }
    .footerkedua {
        padding-left: 0;
    }
    .trisulaBorder a .trisulaFeatures {
        border-right: none;
    }
    .trisulaBorder a:last-child .trisulaFeatures {
        padding: 0 15px;
        border-right: none;
    }
    .trisulaFeatures {
        display: block;
        padding: 0 15px;
        margin-bottom: 30px;
    }
    .trisulaFeatures .featuresIcon {
        margin-bottom: 10px;
    }
    .trisulaInner {
        display: block;
        padding: 47px 0 20px;
        text-align: center;
    }
    .page_container {
        min-height: 70vh;
    }
    .flexbox {
        display: block !important;
    }
    .centerXs {
        text-align: center !important;
    }
    .btnToolbar {
        margin-top: 10px;
    }
    .siteFooter {
        margin-left: 0 !important;
    }
    .hFullscreen {
        height: 70vh !important;
    }
    .col-agxs-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        width: 100%;
    }
    .xsNoPadding {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
    .col-xs-12 {
        width: 100% !important;
    }
    .col-xs-6 {
        width: 50% !important;
        padding: 5px !important;
        // padding-right: 0 !important;
        // padding-left: 0 !important;
    }
    .ml35 {
        margin-left: 35px !important;
    }
    .mt15xs {
        margin-top: 15px !important;
    }
    .bannerOhid {
        margin-bottom: 10px !important;
    }
    .container {
        width: 320px;
    }
    // .navigation_items {
    //     display: none;
    // }
    .mobileDisplayNone {
        display: none;
    }
    .logo .logo_img.mobileHidden {
        display: none;
    }
    footer .wrapper {
        display: block;
    }
    footer .business_info .tag {
        position: relative;
        width: 100%;
        margin-bottom: 20px;
    }
    .pageLeftNav {
        display: none !important;
    }
    .memberpageLeftNav {
        display: none !important;
    }
    .pageRight {
        margin-left: 0 !important;
    }
    header.ml260 {
        margin-left: 0 !important;
    }
    .masterContent {
        margin-left: 0 !important;
    }
    .profileContent {
        margin-left: 0 !important;
    }
    .pageWrapper {
        padding: 0;
    }
    .pageWrapper .pageContainer .pageInner{
        padding: 2rem !important;
        display: table !important;
    }
    .pageWrapper .pageContainer .pageInner .pageLeftNav{
        position: fixed;
        left: 0;
        width: 298px;
        margin-left: 0;
        bottom: 0;
        z-index: 45;
        border-right: 1px solid #2a3e4c;
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        transition: all 0.4s ease;
    }
    .pageWrapper .pageContainer .pageInner .memberpageLeftNav{
        position: fixed;
        left: 0;
        width: 298px;
        margin-left: 0;
        bottom: 0;
        z-index: 45;
        border-right: 1px solid #2a3e4c;
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        transition: all 0.4s ease;
    }
    .pageWrapper .pageContainer .pageInner .pageLeftNav .pageLeftNavScroll{
        position: relative;
        overflow: hidden;
        width: auto;
        height: 100%;
    }
    .pageWrapper .pageContainer .pageInner .memberpageLeftNav .pageLeftNavScroll{
        position: relative;
        overflow: hidden;
        width: auto;
        height: 100%;
    }
    .pageWrapper .pageContainer .pageInner .pageRight{
        width: 298px;
        display: block;
    }
    .pageWrapper .pageContainer .pageInner .pageRight .pageRightWrapper{
        padding: 0;
    }
    .pageWrapper .pageContainer .pageInner .pageRight .pageRightWrapper .pageRightInner{
        display: block;
    }
    .pageWrapper .pageContainer .pageInner .pageRight h2{
        font-size: 18px;
        font-weight: 600;
    }
    .pageWrapper .pageContainer .pageInner .pageRight p{
        padding-bottom: 1rem;
    }
    .innerLeft {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .innerRight {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .loginContainer {
        min-height: 95vh !important;
    }
    .loginWrapper .loginContainer .loginInner{
        margin: 0 30px;
        padding: 2rem;
    }
    .formGroup {
        margin-bottom: 20px;
    }
    .formGroup .controlLabel {
        font-size: 14px;
        margin-left: 1px;
        margin-bottom: 3px;
    }
    .formActions .registerLink {
        display: block;
        margin: 10px 0 0 0;
    }
    .registerLink .myButton {
        padding-left: 0 !important;
        margin-bottom: 5px;
        display: inline-flex;
    }
    .registerLink .linkText {
        margin: 0 !important;
        font-size: 15px;
    }
    .featuredContainer .featuredRow {
        margin: 0 auto;
        max-width: 340px;
    }
    .featuredRow .featuredImage {
        display: block;
        max-width: 100%;
        width: 100% !important;
        height: 100% !important;
        margin-top: 10px;
        background-size: 100% !important;
    }
    .featured_action {
        height: 320px !important;
    }
    .featuredAction .tag.title {
        font-size: 25px;
        font-weight: 700;
    }
    .featuredAction .tag.lowTitle {
        font-size: 15px;
        font-weight: 300;
    }
    .hiddenXs {
        display: none !important;
    }
    .cardItemWrapper {
        margin-bottom: 0;
    }
    .cardItemWrapper .addToCart{
        margin-left: 0 !important;
    }
    .cardItemWrapper .tags .price, .cardItemWrapper .tags .brand{
        font-size: 13px;
    }
    .trisulaOhid {
        margin-bottom: 25px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .zoid-outlet {
        width: 314px !important;
    }
    .trisulaFeatures .featuresIcon {
        padding-right: 0;
        margin-bottom: 10px;
    }
    .hiddenSm {
        display: none !important;
    }
}

@media (min-width: 1200px) {
    .innerWrapper {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .pageRightWrapper .pageRightInner .innerLeft {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
    .pageRightWrapper .pageRightInner .innerRight {
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }
}

@media (min-width: 850px) {
    .toggleButton {
        display: none;
    }
}
@media (min-width: 768px) {

    header .logo {
        margin-left: 0;
    }
    .logo .logo_img.pcHidden {
        display: none;
    }
    .sideDrawer {
        display: none;
    }
    // .overlayForm {
    //     display: none;
    // }
}
