@import 'colorfonts';

/***********************************************
                    HEADER
***********************************************/
.topBar{
    padding: 0 25px;
    height: 65px;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 101;
    -webkit-transition: margin-left 0.3s ease;
    transition: margin-left 0.3s ease;

    // width: 100%;
    // box-shadow: 1px 0px 3px rgba(0, 0, 0, 0.5);
}
.topBar.transparent {
    background-color: transparent;
}
.topBar.white {
    background-color: #ffffff;
    -webkit-box-shadow: 4px 0 5px rgba(0,0,0,0.3);
    box-shadow: 4px 0 5px rgba(0,0,0,0.3);
}
.topBar.black {
    background-color: rgba(25, 25, 25, 0.95);
}
.topBarLeft {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}
.topBarRight {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    flex-direction: row-reverse;
}
.bgImg {
    position: relative;
    border-bottom: none;
    background-position: center;
    -webkit-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
}
.header {
    position: relative;
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    background-color: #fff;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center center;
    border-bottom: 1px solid #ebebeb;
}
.headerInfo {
    // margin: 50px 0;
    padding: 0 30px;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}
.media {
    padding: 16px 12px;
    -webkit-transition: background-color .2s linear;
    transition: background-color .2s linear;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
}
.media .avatarNew {
    flex-shrink: 0;
}
.media > * {
    margin: 0 8px;
}
.mediaBody > * {
    margin-bottom: 0;
}
.mediaBody {
    min-width: 0;
    -ms-flex: 1;
    flex: 1;
}
.alignItemsEnd {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
}
header .logo {
    /* font-family: 'Monoton', cursive;
    color: #ffffff;
    font-size: 40px;
    */
    padding: 0;
    margin-top: 0;
    margin-left: 1rem;
}

header .navigation {
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0 1rem;
}

header .clear {
    flex: 1;
}

header .navigation_items ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

header .navigation_items li {
    // padding: 0 1rem;
    position: relative;
    float: left;
    display: block;
}

// header .navigation_items li::before {
//     content: "";
//     position: absolute;
//     width: 1px;
//     right: 0;
//     top: 5px;
//     bottom: 5px;
//     background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0) 100%);
//     background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0) 100%);
//     background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0) 100%);
//     filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#00ffffff',GradientType=0 );
// }

header .navigation_items a{
    color: #fff;
    text-decoration: none;
    display: inline-block;
    font-weight: 600;
    font-family: $workSans;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 1rem 1rem 0.6rem 1rem;
    border-bottom: 3px solid transparent;
    background-color: transparent !important;
    -webkit-transition: .2s linear;
    transition: .2s linear;
}

header .navigation_items a:hover,
header .navigation_items a:active,
header .navigation_items a.active {
    color: #f63a2a;
    // border-bottom-color: #33cabb;
}

header .navigation_items button{
    color: #fff;
    outline: none;
    white-space: nowrap;
    text-decoration: none;
    border: 0;
    cursor: pointer;
    display: inline-block;
    font-weight: 600;
    font-family: $workSans;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 1rem 1rem 0.6rem 1rem;
    border-bottom: 3px solid transparent;
    background-color: transparent !important;
    -webkit-transition: .2s linear;
    transition: .2s linear;
}

header .navigation_items button:hover,
header .navigation_items button:active,
header .navigation_items button.active {
    color: #f63a2a;
    // border-bottom-color: #33cabb;
}

// li.active a{
//     color: blue;
// }
// $navigationItemsAHoverColor

// li a.active-class {
//     color: $navigationItemsAColorActive;
// }

header .navigation_items .icon {
    font-size: 18px;
}

header .navigation_items .itemContent {
    align-items: center;
    display: table-row;
}

header .navigation_items .itemIcon {
    padding: 7px 3px;
}

header .navigation_items .itemInner {
    display: table-cell;
    width: 100%;
    vertical-align: middle;
    white-space: nowrap;
    align-items: center;
    padding: 10px 5px 10px 5px;
}
.flexShrink {
    flex-shrink: 0;
    position: relative;
}
.itemIcon.hasNew {
    position: relative;
}
.itemIcon.hasNew::after {
    content: '';
    position: absolute;
    top: -3px;
    right: -3px;
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    border: 2px solid #fff;
    background-color: #f96868;
}
@media (max-width: 767.98px) {
    .headerInfo {
        padding: 0;
    }
  }
/* header a {
    margin-left: 20px;
}

header .container {
    display: flex;
}

header .container .left{
    width: 250px;
}

header .container .right{
   flex-grow: 1;
   border-left: 1px solid #eeeeee;
   border-right: 1px solid #eeeeee;
}
header .container .right .top ,
header .container .right .bottom {
    text-transform: uppercase;
    font-weight: 700;
    text-align: right;
    color:#ffffff;
    font-size: 15px;
    padding: 10px 20px;
}

header .container .right .top {
    color:#c9c9c9;
    border-bottom: 1px solid #ffffff;
}

header .cart_link {
    display: inline-block;
    position: relative;
}

header .cart_link span {
    position: absolute;
    background: red;
    top: 2px;
    color: #ffff;
    left: -8px;
    border-radius: 50%;
    padding: 0px 7px 2px 7px;
    font-size: 12px;
}
header .log_out_link {
    cursor: pointer;
    display: inline-block;
    margin-left: 20px;
} */
/***********************************************
                   ADMIN
***********************************************/
#main {
    position: relative;
    min-height: 100%;
    background: #f1f3fa;
}
#main:before {
    content: "";
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #f1f3fa;
}
.navbar {
    z-index: 1030;
    margin-bottom: 0;
    height: 56px;
    color: #30333a;
    background-color: #fff;
    border-radius: 0;
    border: 0;
    box-shadow: none;
}
.navbar .nav {
    margin: 0;
}
.navbar .nav > li {
    float: left;
}
.nav > li {
    position: relative;
    display: block;
}
.nav > li > a,
.nav > li > .memberDiv {
    position: relative;
    display: block;
    padding: 10px 15px;
}
.nav > li > a .name,
.nav > li > .memberDiv .name {
    color: #30333a;
    padding-left: 5px;
    font-size: 16px;
    font-weight: 400;
    // margin-right: -3px;
}
.nav .dropdown [class*='profile-'] {
    margin-left: 2px;
    position: relative;
    display: inline-block;
}
.nav > li > a .profile-online:before,
.nav > li > .memberDiv .profile-online:before {
    content: '';
    position: absolute;
    right: 0;
    bottom: 8px;
    cursor: default;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid #fff;
    background-color: #73cd61;
}
.nav > li > a img,
.navbar .nav > li > .memberDiv img {
    border-radius: 50%;
    border: 3px solid #dfdfdf;
    max-width: 49px;
    margin-top: 2px;
    margin-bottom: 5px;
}
.navbar .nav > li > a.dropdowntoggle,
.navbar .nav > li > .memberDiv.dropdowntoggle {
    margin-left: 22px;
}
.navbar .nav li a.dropdowntoggle svg,
.navbar .nav li .memberDiv svg {
    font-size: 10px;
    position: relative;
    top: -2px;
    text-align: center;
    width: 20px;
}
.navbarnav.navbarleft > li > a {
    padding-right: 12px;
    padding-left: 7px;
    margin-top: 2px;
}
.navbar .nav > li > a,
.navbar .nav > li > .memberDiv {
    color: #30333a;
    font-size: 16px;
    font-weight: 400;
    line-height: 55px;
    padding-top: 0;
    padding-bottom: 0;
    height: 55px;
    max-height: 55px;
    cursor: pointer;
}

.navbar .nav > li.dropdown.open .dropdownmenu {
    margin-top: 0;
    border-radius: 0;
}
.navbar .nav > li.dropdown .dropdownmenu {
    margin-top: 0;
    border-radius: 0;
    padding: 18px 0 13px 0;
}
.navbar .nav > li.dropdown .dropdownmenu li > a,
.navbar .nav > li.dropdown .dropdownmenu li > .memberHeaderLink {
    padding: 0 33px;
    line-height: initial;
    font-size: 15px;
    cursor: pointer;
}
.navbar .nav > li.dropdown .dropdownmenu .divider {
    margin: 7px 0 22px;
    background-color: #dfdfdf;
}
.navbar .nav > li.dropdown .dropdownmenu li:not(.divider):not(.media):not(.timeline-item) {
    height: 30px;
}
.navbar .navbarbtn {
    padding: 12px 5px 12px 5px;
}
.navbar .navbarbtn.btngroup > .btn svg {
    color: #30333a;
}
.navbar .navbarbtn.btngroup > .btn {
    color: #b3b3b3;
    background-color: #fff;
    border: 2px solid #30333a;
    border-radius: 50%;
    font-size: 10px;
    width: 31px;
    height: 31px;
    line-height: 31px;
    padding: 0;
    z-index: 5;
}
.navbar .navbarbtn.btngroup > .btn.bgcolor1 {
    background-color: #10cfbd;
}
.navbar .navbarbtn.btngroup > .btn.bgcolor2 {
    background-color: #6d5cae;
}
.navbar .navbarbtn.btngroup > .btn + .btn {
    color: #fff !important;
    width: initial;
    z-index: 5;
    width: 16px;
    height: 16px;
    line-height: 13px;
    border-radius: 50%;
    border: 1px solid #fff;
    position: absolute;
    bottom: 10px;
    left: 25px;
}
.navbar .navbarbtn.btngroup.open > .btn svg, 
.navbar .navbarbtn.btngroup > .btn.active svg {
    color: #222;
}
.open > .dropdownmenu {
    display: block;
}
.navbarnav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 20px;
}

.dropdownmenu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    padding: 14px 0;
    margin: 0;
    list-style: none;
    font-size: 13px;
    text-align: left;
    background-color: #fff;
    border: 0;
    border-radius: 0;
    -webkit-box-shadow: 0 1px 3px rgba(0,0,0,.3);
    box-shadow: 0 1px 3px rgba(0,0,0,.3);
    background-clip: padding-box;
}
.dropdownmenu > li > a,
.dropdownmenu > li > .memberHeaderLink {
    display: block;
    padding: 5px 18px;
    clear: both;
    color: #666;
    font-weight: normal;
    line-height: 1.49;
    white-space: nowrap;
}
.dropdownmenu > li > a:hover,
.dropdownmenu > li > .memberHeaderLink:hover,
.navbar .nav .listgroup > li.listgroupitem svg:hover {
    color: #213e96;
}
.dropdownmenu .divider {
    height: 1px;
    margin: 9px 18px;
    overflow: hidden;
    background-color: #4c536e;
}
.divider {
    height: 1px;
    margin: 25px 0;
    background: #e2e2e2;
}
.navbarfixedtop {
    top: 0;
    border-width: 0 0 1px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    // box-shadow: 4px 0 5px rgba(0,0,0,.2);
}
.navbarfixedtop, .navbarfixedbottom {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 890;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}
.navbarlogowrapper {
    border-bottom: 0;
    float: left;
    height: 56px;
    margin-right: 0;
    overflow: hidden;
    position: relative;
    width: 245px;
}
.navbarlogoimage {
    display: block;
    margin: 8px 0 0 20px;
}
.navbar .navbarleft {
    border-left: 1px solid #dfdfdf;
}
.navbarnav.navbarright {
    border-left: 1px solid #dfdfdf;
}
.navbar .navbarnav.navbarright, .navbar .navbarnav.navbarright:last-child {
    float: right;
    margin: 0 30px 0 0;
}
.navbarnav.navbarright .dropdowntoggle {
    margin-top: 1px;
}
.navbarform.searchform {
    min-height: 56px;
}
.navbarform {
    margin: 0;
    padding: 14px 20px 13px 18px;
}

.inputgroup {
    position: relative;
    display: table;
    border-collapse: separate;
}
.navbarform.searchform.square input {
    height: initial;
    border-radius: 0;
    border: none;
    outline: none;
    max-width: 170px;
    font-size: 16px;
}
.navbarform.searchform input {
    height: 38px;
    line-height: 36px;
    padding: 0 12px;
    -webkit-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
    box-shadow: none;
    border-radius: 20px;
    border-color: #EEE;
}
.inputgroup .formcontrol {
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    margin-bottom: 0;
    padding-left: 54px;
}
.formcontrol {
    display: block;
    width: 100%;
    height: 60px;
    padding: 6px 15px;
    font-size: 14px;
    line-height: 1.49;
    color: #555555;
    background-color: #fff;
    background-image: none;
    border: 3px solid #cfdce2;
    border-radius: 0;
    -webkit-appearance: none;
    -webkit-transition: border-color ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s;
    transition: border-color ease-in-out .15s;
}
.inputgroupbtn {
    position: relative;
    font-size: 0;
    white-space: nowrap;
}
.inputgroupaddon, .inputgroupbtn {
    width: 1%;
    white-space: nowrap;
    vertical-align: middle;
}
.inputgroupaddon, .inputgroupbtn, .inputgroup .formcontrol {
    display: table-cell;
}
.navbarform.searchform .btn {
    background-color: transparent;
    border-color: transparent;
    border-radius: 0;
    color: #000;
    line-height: 38px;
    margin-left: 0;
    padding: 0 6px;
    width: initial;
    font-size: 14px;
}
.inputgroupbtn > .btn {
    position: relative;
}
.navbarform.searchform .btn svg {
    vertical-align: 0;
}
.navbarnav.navbarleft {
    float: left;
    position: relative;
    max-height: 56px;
}
.navbarform {
    margin-left: -7px;
    margin-right: -7px;
    padding: 10px 7px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.1);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.1);
    // margin-top: 15px;
    // margin-bottom: 15px;
}


.listgroup {
    margin-bottom: 20px;
    padding-left: 0;
}

.navbar .nav .listgroup > li.listgroupitem {
    border: 0;
    padding: 0;
}

.listgroupitem:first-child {
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
}

.listgroupitem {
    position: relative;
    display: block;
    padding: 10px 15px;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid #e7e7e7;
}

.navbar .navbarright > li:last-child > .dropdownmenu > li > a,
.navbar .navbarright > li:last-child > .dropdownmenu > li > .memberHeaderLink {
    white-space: inherit;
}
.navbar .nav .listgroup > li.listgroupitem svg, 
.navbar .nav .listgroup > li.listgroupitem > a .glyphicon,
 .navbar .nav .listgroup > li.listgroupitem > a .glyphicons {
    padding-right: 0;
    float: right;
    color: #cfdce2;
}

.navbar .navbarbtn > .btn > span.ad, 
.navbar .navbarbtn > .btn > span > svg, 
.navbar .navbarbtn > .btn > span.glyphicon, 
.navbar .navbarbtn > .btn > span.glyphicons {
    position: relative;
    top: -3px;
    font-size: 17px;
    vertical-align: middle;
}

.btngroup, .btngroupvertical {
    position: relative;
    display: inline-block;
    vertical-align: middle;
}
.btngroup > .btn:first-child {
    margin-left: 0;
}
.btngroup > .btn, .btngroupvertical > .btn {
    position: relative;
    float: left;
}

header .navigationicon {
    color: rgba(77, 82, 89, 0.7);
    text-decoration: none;
    display: inline-block;
    font-weight: 600;
    font-family: "Work Sans", sans-serif;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 1rem 1rem 0.6rem 1rem;
    border-bottom: 3px solid transparent;
    background-color: transparent !important;
    transition: .2s linear;
    cursor: pointer;
}

header .navigationicon:hover,
header .navigationicon:active,
header .navigationicon.active {
    color: $navigationItemsAColorActive;
    border-bottom-color: #33cabb;
}
/////////////////////////////////////////////////////////
// .navigation_items li.dropdown .dropdownmenu {
//     margin-top: 0;
//     border-radius: 0;
//     padding: 18px 0 13px 0;
// }
// .navigation_items li.dropdown .dropdownmenu li:not(.divider):not(.media):not(.timeline-item) {
//     height: 30px;
// }
// .navigation_items .listgroup > li.listgroupitem {
//     border: 0;
//     padding: 0;
// }
// .navigation_items li.dropdown .dropdownmenu li > a, .navigation_items li.dropdown .dropdownmenu li > .memberHeaderLink {
//     padding: 0 33px;
//     line-height: initial;
//     font-size: 15px;
//     cursor: pointer;
// }
// .navigation_items .listgroup > li.listgroupitem svg, 
// .navigation_items .listgroup > li.listgroupitem > a .glyphicon, 
// .navigation_items .listgroup > li.listgroupitem > a .glyphicons {
//     padding-right: 0;
//     float: right;
//     color: #cfdce2;
// }
// .navigation_items li.dropdown .dropdownmenu .divider {
//     margin: 7px 0 22px;
//     background-color: #dfdfdf;
// }
/////////////////////////////////////////////////////////


@media (min-width: 480px) {
    .navbar {
        border-radius: 0;
    }
    .navbarnav {
        float: left;
        margin: 0;
    }
    .navbarnav > li {
        float: left;
    }
    .navbarRight .dropdownmenu {
        left: auto;
        right: 0;
    }
    .navbarfixedtop {
        border-radius: 0;
    }
    .navbarform {
        width: auto;
        border: 0;
        margin-left: 0;
        margin-right: 0;
        padding-top: 0;
        padding-bottom: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    .navbarleft {
        float: left;
        float: left !important;
    }
    .navbarright {
        float: right;
        float: right !important;
    }
}
@media (min-width: 768px) {
    .navbarform .inputgroup {
        display: inline-table;
        vertical-align: middle;
        padding-top: 9px;
    }
    .navbarform .inputgroup > .formcontrol {
        width: 100%;
    }
    .navbarform .formcontrol {
        display: inline-block;
        width: auto;
        vertical-align: middle;
    }
    .navbarform .inputgroup .inputgroupaddon, 
    .navbarform .inputgroup .inputgroupbtn,
    .navbarform .inputgroup .formcontrol {
        width: auto;
    }
    .navbarform.searchform input {
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .navbarform.searchform {
        margin-left: 0 !important;
        // padding: 18px;
    }
}
@media (min-width: 850px) {
    .memberToggleButton {
        display: none;
    }
}
@media (max-width: 360px) {
    .nav > li > .memberDiv {
        padding: 10px 0;
    }
    .nav .ml20 {
        margin-left: 5px !important;
    }
    .nav.mr25 {
        margin-right: 5px !important;
    }
}
@media (max-width: 479px) {
    .navbarform.searchform .btn {
        line-height: 45px !important;
    }
    .navbarnav .open .dropdownmenu {
        position: static;
        float: none;
        width: auto;
        margin-top: 0;
        background-color: transparent;
        border: 0;
        box-shadow: none;
    }
    .navbar .nav > li > a.dropdowntoggle,
    .navbar .nav > li > .memberDiv.dropdowntoggle {
        margin-left: 6px;
    }
    .navbarleft .dropdownmenu {
        left: 0 !important;
    }
    .navbarright .profilemenu {
        left: -200% !important;
    }
    .nav > li > .memberDiv {
        padding: 10px 5px;
    }
    .nav .ml20 {
        margin-left: 10px !important;
    }
    .nav.mr25 {
        margin-right: 10px !important;
    }
    .menuLink {
        padding: 0 !important;
    }
}
// @media (max-width: 599px) {
    
// }
@media (max-width: 767.98px) {

    input[placeholder] {
        text-overflow: ellipsis;
    }

}
@media (max-width: 849px) {
    .memberSideDrawerLogo {
        box-shadow: 4px 0 5px rgba(0, 0, 0, 0.2);
    }
    .memberSideDrawer {
        box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
    }
    .memberToggleButton{
        width: 53px;
        line-height: 56px;
        margin-top: 1px;
        float: left;
    }
    .navbarform.searchform .btn {
        line-height: 30px;
    }
    .navbarleft .dropdownmenu,
    .navbarright .dropdownmenu, 
    .navbarleft .open .dropdownmenu, 
    .navbarright .open .dropdownmenu {
        z-index: 1000;
        margin-top: 0 !important;
        float: left;
        position: absolute;
        top: 99%;
        left: -100%;
        min-width: 101%;
        list-style: none;
        background-color: #fff;
        border: 1px solid rgba(0, 0, 0, 0.15);
        background-clip: padding-box;
        -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    }
}
@media (max-width: 900px) {
    .navbarlogowrapper {
        width: 0;
    }
    .navbarlogowrapper .navbarlogoimage img.mobileHidden {
      display: none;
    }
    .navbarform.searchform {
        padding: 6px 16px;
        font-size: 16px;
        margin: 0;
        cursor: pointer;
        width: 61px;
        float: left;
        height: 56px;
    }
    .navbarform.searchform input {
        opacity: 0;
        z-index: -1;
        visibility: hidden;
        position: absolute;
        top: -56px;
        left: 0;
        width: 100%;
        height: 66px;
        margin: 0;
        font-size: 24px;
        color: #AAA;
        border-radius: 0;
        border: 0;
        text-align: center;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }
    .navbarform.searchform.square input {
        -webkit-border-radius: 5px;
        border-radius: 5px;
    }
    .navbarform.searchform > .inputgroup.addon {
        position: inherit;
    }
}