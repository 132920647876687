.grid_bars {
    position: relative;
    height: 222px;
    // width: 100%;
    // padding: 15px !important;
    // overflow: hidden;
    // height: 175px;
}
.grid_bars .cardImage {
    padding: 0;
    width: 52.98%;
    float: left;
}
.grid_bars .cardImage .image {
    width: 100%;
    // height: 232px; 4 products
    height: 212.5px;
    background-position: center !important;
    background-size: cover !important;
    // border: 1px solid #f0f0f0;
}
.grid_bars .cardPadding {
    padding: 0 10px 10px 10px;
    width: 46.98%;
    float: left;
}
.paddingShop .grid_bars .image {
    width: 142px;
    height: 142px;
    position: relative;
    float: left;
}
.grid_bars .tags {
    position: relative;
    border: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
}
.grid_bars .name {
    height: 30px !important;
}
.grid_bars .description {
    overflow: hidden;
    height: 50px !important;
}
.grid_bars .description p{
    margin: 0 !important;
    font-size: 13px;
}
.grid_bars .brand {
    height: 23px;
}
.grid_bars .cardItemWrapper .tags .brand,
.grid_bars .cardItemWrapper .tags .category,
.grid_bars .cardItemWrapper .tags .name,
.grid_bars .cardItemWrapper .tags .price {
    // text-align: center;
    margin: 0;
    overflow: hidden;
}
.grid_bars .name, 
.grid_bars .brand, 
.grid_bars .category,
.grid_bars .price, 
.grid_bars .description {
    // margin: 0 !important;
    padding: 0 0 5px 5px !important;
}
.grid_bars .addToCart {
    right: 0;
    bottom: 0;
    position: absolute;
    width: 100%;
    // padding: 10px !important;
    // margin: 20px 0 0 10px;
}
// .grid_bars .addToCart .myButton .bag_link span {
//     display: none;
// }
// .grid_bars .additionaldata {
//     display: block;
//     position: absolute;
//     bottom: 40px;
// }
// .grid_bars .additionalitem {
//     font-size: 14px;
// }
// .grid_bars .category {
//     display: none;
// }
.cardItemWrapper.grid_bars {
    height: 250px;
    margin-bottom: 20px !important;
}
@media (max-width: 767.98px) {
    .grid_bars {
        height: 240px !important;
        margin-bottom: 20px;
    }
    .grid_bars .image {
        height: 181.5px !important;
    }
    .grid_bars .cardImage {
        width: 53.98%;
    }
    .grid_bars .cardPadding {
        width: 45.98%;
    }
    .grid_bars .name, 
    .grid_bars .brand, 
    .grid_bars .category,
    .grid_bars .price, 
    .grid_bars .description {
    // margin: 0 !important;
        padding: 0 !important;
    }
    .grid_bars .addToCart{
        right: 0;
        bottom: 0;
        padding: 0;
    }
    .grid_bars .name {
        height: 35px !important;
    }
    .grid_bars .description {
        height: 65px !important;
    }
    .grid_bars .brand {
        height: 27px;
    }
}
@media (min-width: 768px) and (max-width: 835px) {
    .grid_bars {
        height: 220px !important;
        margin-bottom: 20px;
    }
    .grid_bars .name, 
    .grid_bars .brand, 
    .grid_bars .category,
    .grid_bars .price, 
    .grid_bars .description {
    // margin: 0 !important;
        padding: 0 !important;
    }
    .grid_bars .cardImage .image {
        height: 177.6px !important;
    }
}
@media (min-width: 800px) and (max-width: 835.98px) {
    .grid_bars {
        height: 235px !important;
        margin-bottom: 20px;
    }
    .grid_bars .cardImage .image {
        height: 195px !important;
    }
}
@media (min-width: 836px) and (max-width: 1025px){
    .grid_bars {
        height: 220px !important;
        margin-bottom: 20px;
    }
    .grid_bars .cardImage .image {
        height: 177.6px !important;
    }
    .grid_bars .name, 
    .grid_bars .brand, 
    .grid_bars .category,
    .grid_bars .price, 
    .grid_bars .description {
    // margin: 0 !important;
        padding: 0 !important;
    }
}