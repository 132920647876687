@import 'colorfonts';

/***********************************************
                   CARD
***********************************************/
.card {
    margin-bottom: 2.3rem;
    border-radius: 7px;
    border: none;
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: $colorWhite;
    background-clip: border-box;
    // border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    // box-shadow: 1px 0 5px rgba(0,0,0,.2);
}
.h4.cardTitle, h4.cardTitle {
    font-size: 17px;
}
.cardTitle {
    font-family: $workSans;
    font-weight: 300;
    line-height: 1.5;
    margin-bottom: 0;
    padding: 15px 20px;
    border-bottom: 1px solid rgba(77,82,89,0.07);
}
.cardBody {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem;
}
.cardBody > :last-child {
    margin-bottom: 0;
}
.cardImage {
    padding: 0;
    border: 1px solid #e5e5e5;
}
.cardPadding {
    padding: 0 15px 5px 15px;
}
.cardItemWrapper {
    // flex-grow: 1;
    // padding: 0 20px;
    // box-sizing: border-box;
    // position: relative;
    // overflow: hidden;
    // -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    // box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    // -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.15), 0 0 1px rgba(0,0,0,.15);
    // box-shadow: 0 1px 1px rgba(0,0,0,.15), 0 0 1px rgba(0,0,0,.15);
    -webkit-border-radius: 4px;
    border-radius: 4px;
    background: $colorWhite;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    -ms-transition: 0.3s;
    transition: 0.3s;
    position: relative;
    margin-bottom: 20px;
}

// .cardItemWrapper:hover {
//     -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);
//     box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);
// }

.cardItemWrapper .image {
    width: 100%;
    // height: 232px; 4 products
    height: 185.5px;
    background-position: center !important;
    background-size: cover !important;
    // border: 1px solid #f0f0f0;
}

.cardItemWrapper .tags {
    position: relative;
    border-top: 1px solid #e5e5e5;
    margin-top: 10px;
    padding-top: 3px;
}

.tags .brand,
.tags .name,
.tags .category,
.tags .price,
.tags .description {
    // text-align: center;
    margin: 5px 0;
    overflow: hidden;
}

.cardItemWrapper .tags .brand {
    font-size: 15px;
    font-weight: 700;
    color: #767676;
}

.cardItemWrapper .tags .category {
    font-size: 11px;
    color: #767676;
}

.cardItemWrapper .tags .name {
    font-size: 13px;
    height: 50px;
}

.cardItemWrapper .tags .price {
    font-weight: 700;
    font-size: 15px;
}

.cardItemTitle {
    font-size: 30px;
    margin-top: 2rem;
    margin-bottom: .7142857143rem;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    padding-top: 40px;
    padding-bottom: 16px;
}
.cardItemTitle span {
    color: rgba(77, 82, 89, 0.7);
}

.addToCart {
    position: relative;
    z-index: 5;
    // padding: 7px;
    // background: $colorDark;
    // color: $colorWhite;
    // cursor: pointer;
    // width: 100%;
    text-align: center;
}

.bag_link {
    font-weight: 500;
}
.cardTransparent {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
}
.myCard {
    border: 0;
    border-radius: 0px;
    margin-bottom: 30px;
    -webkit-box-shadow: 0 2px 3px rgba(0,0,0,0.03);
    box-shadow: 0 2px 3px rgba(0,0,0,0.03);
    -webkit-transition: .5s;
    transition: .5s;
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba (0,0,0,.125);
    // border-radius: .25rem;
}
.myCard > hr {
    margin-right: 0;
    margin-left: 0;
}
hr {
    border-top-color: rgba(77,82,89,0.07);
    margin: 2rem auto;
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

@media (max-width: 767.98px) {
    .cardItemWrapper .image {
        height: 158px !important;
    }
}
@media (min-width: 768px) and (max-width: 799px) {
    .cardItemWrapper .image {
        height: 152.5px !important;
    }
}
@media (min-width: 800px) and (max-width: 835.98px) {
    .cardItemWrapper .image {
        height: 169px !important;
    }
}
@media (min-width: 836px) and (max-width: 1025px){
    .cardItemWrapper .image {
        height: 152.5px !important;
    }
}