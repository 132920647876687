@import 'colorfonts';

/***********************************************
                    COLLAPSE
***********************************************/
.collapseItemsWrapper {
    cursor: pointer;
}
.collapseItemsWrapper span {
    padding-left: 20px;
}
.collapseItemsWrapper label {
    padding-left: 14px;
}
// .collapseItemsWrapper .checkBoxPadding {
//     padding: 0;
// }
.collapseTitle > span{
    font-weight: 500;
}
.itemCheckbox, .itemRadio {
    padding: 0 !important;
}
// .filtersCol {
//     background-color: $colorWhite;
//     box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
//     padding-top: 8px;
//     padding-bottom: 8px;
//     margin: 0;
//     padding: 0;
//     position: relative;
//     list-style: none;
//     border-bottom: 1px solid rgb(219, 219, 219);
// }
// .filtersCollapse, .itemWrapper {
//     width: 100%;
//     display: flex;
//     position: relative;
//     box-sizing: border-box;
//     text-align: left;
//     align-items: center;
//     padding-top: 11px;
//     padding-bottom: 11px;
//     justify-content: flex-start;
//     text-decoration: none;
// }
// .filtersCollapse{
//     padding: 10px 23px 10px 0px;
// }
// .itemWrapper{
//     padding: 10px 0;
// }
// .filtersCollapseTitle:first-child, .itemInner:first-child{
//     padding-left: 0;
// }
// .filtersCollapseTitle, .itemInner {
//     flex: 1 1 auto;
//     padding: 0 16px;
//     min-width: 0;
// }
// .primaryTitle, .itemRoot {
//     font-size: 1rem;
//     line-height: 1.5em;
//     font-weight: 400;
//     font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//     color: rgba(0,0,0,.87);
//     margin: 0;
//     display: block;
// }
// .filtersItemContainer {
//     min-height: 0px;
//     height: auto;
//     transition-duration: 0ms;
//     overflow: hidden;
//     transition: height 300ms cubic-bezier(0,4, 0, 0.2, 1) 0ms;
// }
// .filtersItemWrapper {
//     display: flex;
// }
// .filtersItemInner {
//     width: 100%;
// }
// .filtersItemRoot {
//     margin: 0;
//     padding: 0;
//     position: relative;
//     list-style: none;
// }

// .itemContainer{
//     position: relative;
// }
// .item2ndWrapper{
//     top: 50%;
//     right: 4px;
//     position: absolute;
//     transform: translateY(-50%);
// }
// .item2ndRoot{
//     color: inherit;
//     border: 0;
//     margin: 0;
//     cursor: pointer;
//     display: inline-flex;
//     outline: none;
//     padding: 0;
//     position: relative;
//     align-items: center;
//     user-select: none;
//     border-radius: 0;
//     vertical-align: middle;
//     justify-content: center;
//     -moz-appearance: none;
//     text-decoration: none;
//     background-color: transparent;
//     -webkit-appearance: none;
//     -webkit-tap-highlight-color: transparent;
// }
// .itemChecked{
//     flex: 0 0 auto;
//     color: rgba(0,0,0,.54);
//     padding: 12px;
//     overflow: visible;
//     font-size: 1.5rem;
//     text-align: center;
//     transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
//     border-radius: 50%;
// }
// .itemOther{
//     display: inline-flex;
//     transition: none;
//     align-items: center;
// }
// .itemColor{
//     color: rgba(0,0,0,.54);
// }
// .itemLabel{
//     width: 100%;
//     display: flex;
//     align-items: inherit;
//     justify-content: inherit;
// }