@import 'colorfonts';

/***********************************************
                    BUTTONS
***********************************************/
.btn {
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 5px 16px;
    line-height: inherit;
    color: #8b95a5;
    letter-spacing: 1px;
    border: 1px solid transparent;
    border-radius: 2px;
    background-color: #fff;
    border-color: #ebebeb;
    outline: none !important;
    -webkit-transition: 0.15s linear;
    transition: 0.15s linear;
}
.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}
.btnGroup, .btnGroupVertical {
    vertical-align: initial;
    position: relative;
    display: -ms-inline-flexbox;
    display: inline-flex;
    // vertical-align: middle;
}
.btnGroup > .btnGroup:not(:last-child) > .btn, .btnGroup > .btn:not(:last-child):not(.dropdownToggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.btnGroup > .btnGroup:not(:first-child) > .btn, .btnGroup > .btn:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.btnGroup .btn + .btn, .btnGroup .btn + .btnGroup, .btnGroup .btnGroup + .btn, .btnGroup .btnGroup + .btnGroup, .btnGroupVertical .btn + .btn, .btnGroupVertical .btn + .btnGroup, .btnGroupVertical .btnGroup + .btn, .btnGroupVertical .btnGroup + .btnGroup {
    margin-left: -1px;
}
.btnGroup > .btn:first-child {
    margin-left: 0;
}
.btnGroupSM > .btn, .btnSM {
    font-size: 12px !important;
    padding: 7px 8px;
    line-height: 20px !important;
    border-radius: .2rem;
}
.btnGroupVertical > .btn, .btnGroup > .btn {
    position: relative;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
}
.btnXs,
.btnGroupXs > .btn {
    font-size: 11px;
    padding: 9px 8.5px 6px;
    // line-height: 18px;
}
.btnPrimary {
    background-color: #33cabb;
    border-color: #33cabb;
    color: #fff;
}
.btnToolbar {
    display: -ms-flexbox;
    display: inline-flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
}
.divBtn {
    padding: 7px 15px !important;
}
.divBtn2 {
    padding: 7px 19px !important;
}

/***********************************************
            MEMBER LOGIN BUTTONS
***********************************************/
.submitButton {
    width: 100%;
    margin: 16px auto;
    display: block;
}
.submitButtonShadow:not([class*=mat-elevation-z]) {
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
}
.submitButtonShadow {
    box-sizing: border-box;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    outline: 0;
    border: none;
    -webkit-tap-highlight-color: transparent;
    display: inline-block;
    white-space: nowrap;
    text-decoration: none;
    vertical-align: baseline;
    text-align: center;
    margin: 0;
    min-width: 64px;
    line-height: 36px;
    padding: 0 16px;
    border-radius: 4px;
    overflow: visible;
    transform: translate3d(0,0,0);
    transition: background .4s cubic-bezier(.25,.8,.25,1),box-shadow 280ms cubic-bezier(.4,0,.2,1);
}
.submitButtonShadow.blueColor {
    background-color: #213e96;
    color: #fff;
}
.khususaddress .disabled {
    background-color: rgba(188, 144, 81, 0.5) !important;
    border: 1px solid rgba(188, 144, 81, 0.5) !important;
}
.khususcheckout.disabled {
    background-color: rgba(188, 144, 81, 0.5) !important;
    border: 1px solid rgba(188, 144, 81, 0.5) !important;
}