.hometitle {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1000px;
    height: 160px;
    -webkit-transform: translate(-50%, -50%);  
    transform: translate(-50%, -50%);
}
.hometitlemobile {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    padding: 0px 10px;
    height: 160px;
    -webkit-transform: translate(-50%, -50%);  
    transform: translate(-50%, -50%);
}
.hometitle .big_white {
    color:#fff;
    font-size: 45px;
    margin: 10px 15px;
    text-transform: uppercase;
    line-height: 70px;
    font-weight: 700;
}
.hometitlemobile .big_whitemobile {
    color:#fff;
    font-size: 25px;
    margin: 10px 15px;
    text-transform: uppercase;
    line-height: 30px;
    font-weight: 700;
}
.hometitlemobile .hero-copy {
    color:#fff;
    margin: 0 15px;
    font-size: 18px;
    line-height: 22px;
    font-weight: 300;
}
.hometitle .hero-copy {
    color:#fff;
    margin: 0 15px;
    font-size: 22px;
    line-height: 32px;
    font-weight: 300;
}
.hometitle .btn-holder {
    display: block;
    margin: 30px 15px;
}
.big_white span {
    color:#f64747;
}
.big_whitemobile span {
    color:#f64747;
    display: block;
}
.lm-button {
    border: 1px solid #f64747;
    color:#f64747;
}
.lm-button {
    font-family: 'Montserrat', 'Open Sans', sans-serif;
    font-size: 13px;
    letter-spacing: 1.5px;
    color: #fff;
    display: inline-block;
    padding: 10px 36px;
    border: 1px solid #888;
    border-radius: 5px;
    text-transform: uppercase;
    border: 1px solid #f64747;
    color: #f64747;
}
.slidercontainer {
    width: 100%;
    margin: 0 auto;
}
.carouselimage {
    background-size: cover !important;
}
// .overlay {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
// }
// .overlay.lighterbg {
//     background: rgba(0, 0, 0, 0.45);
// }
/***********************************************
                  ABOUT US
***********************************************/
#aboutus.menuin {
    padding-top: 80px;
    // padding-bottom: 80px;
    display: block;
}
h2.sectiontitleku {
    color: #aaa;
    font-size: 41px;
    line-height: 70px;
    font-weight: 700;
    text-align: center;
    padding-bottom: 10px;
}
.sectiontitleku::after {
    background: #f64747;
    position: relative;
    display: block;
    content: "";
    height: 2px;
    width: 30px;
    margin: 10px auto 20px;
}
.sectiontagline {
    font: 18px/29px 'Open Sans', Arial, sans-serif;
    font-weight: normal;
    font-weight: 300;
    // color: #808080;
    text-align: center;
    max-width: 550px;
    margin: auto auto 80px auto;
    color: #aaa;
}
.abouticons {
    border-top: 1px solid #111;
    border-bottom: 1px solid #111;
    text-align: center;
}
.abouticons .column {
    overflow: hidden;
    position: relative;
    padding: 120px 50px;
    min-height: 380px;
}
.abouticons .column:hover {
    background-color: rgba(25, 25, 25, 0.95);
}
.abouticons .column.border{
    border-right: 1px solid #111;
}
.abouticons .column.bordermobile{
    border-bottom: 1px solid #111;
}
.column {
    float: left;
    margin: 0;
    padding-left: 15px;
    padding-right: 15px;
}
.column.four {
    width: 33.33333%;
}
.column.six {
    width: 50%;
}
.abouticons h2 {
    color: #aaa;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    font-weight: 500;
    margin: 20px 0;
}
.abouticons .main {
    -webkit-transition: all 300ms cubic-bezier(.07, .58, .39, 1);
    -moz-transition: all 300ms cubic-bezier(.07, .58, .39, 1);
    -o-transition: all 300ms cubic-bezier(.07, .58, .39, 1);
    -ms-transition: all 300ms cubic-bezier(.07, .58, .39, 1);
    transition: all 300ms cubic-bezier(.07, .58, .39, 1);
}
.abouticons span.desc {
    // color: #aaa;
    opacity: 0;
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    padding: 0 50px;
    -webkit-transform: perspective(500px) rotateX(-90deg) rotateY(0deg) rotateZ(0deg);
    -moz-transform: perspective(500px) rotateX(-90deg) rotateY(0deg) rotateZ(0deg);
    -o-transform: perspective(500px) rotateX(-90deg) rotateY(0deg) rotateZ(0deg);
    -ms-transform: perspective(500px) rotateX(-90deg) rotateY(0deg) rotateZ(0deg);
    transform: perspective(500px) rotateX(-90deg) rotateY(0deg) rotateZ(0deg);
    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);

    font: 16px/20px 'Open Sans', Arial, sans-serif;
    font-weight: normal;
    font-weight: 300;
    // color: #808080;
    text-align: center;
    // max-width: 550px;
    // margin: auto auto 80px auto;
    color: #aaa;
}
.abouticons span.description {
    // color: #aaa;
    opacity: 1;
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    padding: 0 50px;
    font: 16px/20px 'Open Sans', Arial, sans-serif;
    font-weight: normal;
    font-weight: 300;
    // color: #808080;
    text-align: center;
    // max-width: 550px;
    // margin: auto auto 80px auto;
    color: #aaa;
}
.abouticons .main svg {
    backface-visibility: hidden;
}
.abouticons svg {
    font-size: 64px;
    color: #f64747;
}
.abouticons .main svg, #abouticons .main h2 {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
#contact {
    text-align: center;
}
#contact .title {
    font-size: 30px;
    line-height: 34px;
    color:#fff;
    font-weight: 300;
    text-align: center;
}
#contact .column svg {
    font-size: 40px;
    color: #f64747;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
#contact span {
    display: block;
    color:#fff;
    margin: 5px 0 30px 0;
}
#contact .column a, #contact .column span {
    color:#808080;
    margin: 15px 0 0 0;
    display: block;
    font: 15px/26px 'Open Sans', sans-serif;
    text-decoration: none;
    -webkit-transform: translateZ(0);
    -webkit-transition: 0.3s all linear;
    -moz-transition: 0.3s all linear;
    transition: 0.3s all linear;
}

.abouticons .column:hover .service-intro {
    opacity: 0;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -o-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
}
.abouticons .column:hover .desc {
    opacity: 1;
    -webkit-transform: perspective(500px) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    -moz-transform: perspective(500px) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    -o-transform: perspective(500px) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    -ms-transform: perspective(500px) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    transform: perspective(500px) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
}
.abouticons .column:hover .main {
    margin-top: -75px;
}
.abouticons .column .mainmobile {
    margin-top: -75px;
}
.htmlparallax {
    padding: 20;
    position: "absolute";
    top: "50%";
    left: "50%";
    transform: "translate(-50%,-50%)";
}
.quote {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
    padding: 170px 0;
}
.parallaxquote {
    color:#fff;
    font-weight: 300;
    font-family: 'Open Sans', Arial, sans-serif;
    text-align: center;
    font-size: 34px;
    line-height: 48px;
}
.quoteauthor {
    margin-top: 20px;
    color:#fff;
    text-transform: uppercase;
    text-align: center;
    display: block;
    font-size: 14px;
    font-weight: 700;
}
.locationtag {
    position: absolute;
    bottom: 0px;
    width: 100%;
}
.locationtag div{
    background: #2c2c2c;
    color: #ffffff;
    text-transform: uppercase;
    width: 220px;
    margin: 0 auto;
    font-size: 29px;
    padding: 15px 20px;
    text-align: center;
}
// .react-parallax-bgimage {
//     background-attachment: fixed !important;
// }
footer {
    background-color: #222222;
    text-align: center;
    padding: 60px 0;
}
footer.light {
    background-color: #f3f3f3;
}
footer .block {
    color: #808080;
    display: block;
}
footer .block a {
    color: #808080;
}
footer .block a:hover {
    color: #d3145a;
}
footer ul {
    margin: 0;
}
footer img {
    margin-bottom: 15px;
}
.gray {
    background-color: rgba(25, 25, 25, 0.95);
}
.frontendtable ul {
    margin-bottom: 20px;
}
/***********************************************
                PRICING TABLE
***********************************************/
#pricing-tables {
    padding-bottom: 90px;
}
.frontendtable {
    border: 1px solid rgba(246,58,42,0.5);
    padding: 80px 35px;
    text-align: center;
    -o-transition: .4s;
    -ms-transition: .4s;
    -moz-transition: .4s;
    -webkit-transition: .4s;
    transition: .4s;
    max-width: 350px;
    margin: auto;
}
.frontendtable.popular, .frontendtable:hover {
    border: 1px solid #f64747;
    background-color: rgba(160,160,160,0.1);
}
.frontendtable ul li {
    font: 17px/20px 'Open Sans', Arial, sans-serif;
    font-weight: normal;
    font-weight: 300;
    // color: #808080;
    text-align: center;
    // max-width: 550px;
    // margin: auto auto 80px auto;
    color: #aaa;
}
.frontendtable ul li {
    list-style: none;
    text-align: center;
}
.tabletitle {
    font: 17px/20px 'Open Sans', Arial, sans-serif;
    // color: #808080;
    color: #aaa;
    display: block;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    margin-bottom: 20px;
}
.frontendtable .lm-button {
    margin: 35px 0 0 0;
    text-decoration: none!important;
    border: 1px solid #d3145a;
    color: #d3145a;
}
.frontendtable .lm-button:hover {
    border: 1px solid #d3145a;
    background-color: #d3145a;
    color: #fff;
}
.frontendtable .lm-button.fill {
    background-color: #d3145a;
    color: #fff;
}
.frontendtable .lm-button.fill:hover {
    background-color: transparent;
    color: #d3145a;
}
.toggleButton.white svg {
    color: #ffffff;
}

.sideDrawerLogo {
    padding: 14px !important;
    background-color: rgba(25, 25, 25, 0.95);
}
.sideDrawer button, .sideDrawer .icon {
    color: #aaa;
    text-decoration: none;
    font-size: 1.2rem;
    background-color: transparent;
    cursor: pointer;
}
.sideDrawer > ul > li > button span:hover,
.sideDrawer > ul > li > button span:active {
    color: #fff;
}
.sideDrawer > ul > li > button{
    border: none;
    display: block;
    position: relative;
    padding: 5px;
    border-radius: 0;
    margin: 0;
    width: 100%;
    text-align: left;
    border-bottom: 1px solid rgba(25, 25, 25, 0.95);
    // text-align: -webkit-match-parent;
}
.sideDrawer > ul > li > button.active {
    background-color: #fff;
    border-right: 4px solid #d3145a;
}
.sideDrawer > ul > li button.active,
.sideDrawer > ul > li button.active .icon{
    color: #fff;
}
.sideDrawer .iconContent > ul > li > button {
    letter-spacing: 0;
    opacity: 0.9;
    padding: 7px;
    position: relative;
    text-align: center;
    display: block;
    
}
.sideDrawer .iconContent > ul > li > button.active {
    background-color: #222222;
    border-bottom: 4px solid #222222;
}
.sideDrawer .iconContent > ul > li > button.active > .iconWrapper > .icon {
    color: #fff;
}

@media (min-width: 850px) {
    .toggleButton {
        display: none;
    }
}
@media (max-width: 767.98px) {
    #aboutus.menuin {
        padding-top: 60px;
    }
    .sectiontagline {
        padding: 0 10px;
    }
    .marginbottom20 {
        margin-bottom: 30px;
    }
    .abouticons h2 {
        margin: 0;
    }
    .abouticons span.description {
        top: 40%;
    }
    .slidercontainer {
        height: 350px !important;
        overflow: hidden
    }
}
@media (min-width: 767.99px) and (max-width: 835.98px) {
    .toggleButton {
        display: none;
    }
    .hometitle {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 600px;
        height: 160px;
        transform: translate(-50%, -50%);
    }
    .hometitle .big_white {
        color:#fff;
        font-size: 45px;
        margin: 10px 15px;
        text-transform: uppercase;
        line-height: 50px;
        font-weight: 700;
    }
    #pricing-tables .table {
        border: 1px solidrgba(246, 58, 42, 0.5);
        padding: 40px 15px;
        text-align: center;
        transition: .4s;
        max-width: 350px;
        margin: auto;
        margin-bottom: auto;
        height: 100%;
    }
    .abouticons .column {
        overflow: hidden;
        position: relative;
        padding: 100px 50px;
        min-height: 380px;
    }
    .abouticons span.description {
        opacity: 1;
        display: block;
        position: absolute;
        top: 40%;
        left: 0;
        width: 100%;
        padding: 0 20px;
        font: 16px/20px 'Open Sans', Arial, sans-serif;
        font-weight: normal;
        font-weight: normal;
        font-weight: 300;
        text-align: center;
        color: 
        #aaa;
    }
    .abouticons h2 {
        margin: 0;
    }
    .slidercontainer {
        height: 435px !important;
        overflow: hidden
    }
}
@media (min-width: 835.99px) and (max-width: 1025.98px) {
    .slidercontainer {
        height: 500px !important;
        overflow: hidden
    }
    .hometitle {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 600px;
        height: 160px;
        transform: translate(-50%, -50%);
    }
    .hometitle .big_white {
        color:#fff;
        font-size: 45px;
        margin: 10px 15px;
        text-transform: uppercase;
        line-height: 50px;
        font-weight: 700;
    }
    .abouticons span.description {
        top: 47%;
    }
    .abouticons h2 {
        margin: 10px;
    }
    #pricing-tables .frontendtable {
        height: 100%;
    }
}

