.imageLoadingWidth {
    width: 470px;
    height: 470px;
    margin-bottom: 10px;
}

.thumbnailLoadingWidth {
    width: 470px;
    height: 158px;
}
.productDetailTitle {
    width: 260px;
    height: 26px;
    margin-bottom: 5px;
}
.productDetailSubtitle {
    width: 100px;
    height: 18px;
    margin-bottom: 1rem;
}
.productDetailCategory {
    width: 160px;
    height: 18px;
    margin-bottom: 1rem;
}
.productDetailPrice {
    width: 120px;
    height: 27px;
    margin-bottom: 1rem;
}
.productDetailAvailable {
    width: 100px;
    height: 18px;
    margin-bottom: 20px;
}
.productLeftButton {
    width: 90px;
    height: 46px;
}
.productRightButton {
    width: 155px;
    height: 46px;
    margin-left: 10px;
}
.productColor, .productCategory {
    width: 100px;
    height: 18px;
    margin-top: 20px;
}
.productSocial, .productShare {
    width: 260px;
    height: 18px;
    margin-bottom: 1rem;
}
.productLine {
    width: 670px;
    height: 1px;
    margin: 2rem auto;
}
.masterContainer .card .rightSideOption.open {
    transform: translateX(0);
    overflow-y: auto;
    top: 0%;
    bottom: 0%;
    scrollbar-width: thin !important;
}
.masterContainer .card .rightSideOption.open .actionTitle {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    line-height: 1.5;
    margin-bottom: 0;
    padding: 15px 20px;
    float: right;
    min-height: 69px;
    border-bottom: 1px solid rgba(0,0,0,0.2);
}
.masterContainer .card .rightSideOption.open .actionTitle .actionTitleText {
    height: 38px;
    vertical-align: middle;
    display: table-cell;
}
.masterContainer .card .rightSideOption.open .rightSideButtonWrapper {
    padding: 15px 20px;
    display: flex;
    flex-direction: row;
    position: relative;
    min-height: 1px;
    width: 100%;
    float: left;
}
.masterContainer .card .rightSideOption.open .rightSideButtonWrapper .rightSideButton {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    -webkit-user-select: none;
    user-select: none;
    border-radius: 3px;
    padding: .375rem .75rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    min-width: 100px;
    opacity: 0.8;
    background-color: $colorGreen;    
    color: $colorWhite;
    border: 1px solid $colorGreen;
}
.masterContainer .card .rightSideOption.open .rightSideButtonWrapper .rightSideButton:hover {
    background-color: $colorGreen;    
    color: $colorWhite;
    border: 1px solid $colorGreen;
    opacity: 1;
}
.masterContainer .card .rightSideOption.open .rightSideButtonWrapper .rightSideButton .rightSideButtonText {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
}
.masterContainer .card .rightSideOption.open .rightSideButtonWrapper .rightSideButton svg {
    margin-right: 0.5rem;
    // font-size: 12px;
}
.masterContainer .card .rightSideOption {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 500px;
    background-color: #ffffff;
    // border: 1px solid rgba(0,0,0,0.2);
    // background-color: #272723; last one
    // background-color: $colorLightDark; <=== latest
    white-space: nowrap;
    // -webkit-box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.2);
    // box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.2);
    // -webkit-box-shadow: 0 1px 2px rgba(255, 255, 255, 0.2);
    // box-shadow: 0 1px 2px rgba(255, 255, 255, 0.2);
    display: -webkit-box;
    display: flex;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    z-index: 999;
    -webkit-transform: translateX(150%);
    -moz-transform: translateX(150%);
    -ms-transform: translateX(150%);
    transform: translateX(150%);
    -webkit-transition: .3s ease;
    -moz-transition: .3s ease;
    transition: .3s ease;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: transform;
}
.masterContainer .card .tablebackdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 995;
    cursor: pointer;
}
@media (max-width: 767.98px) { 
    .masterContainer .card .rightSideOption {
        width: 85%;
    }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}