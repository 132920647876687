/***********************************************
                    LOGIN
***********************************************/
.loginWrapper {
    background: #fafafa;
    background-size: cover;
    // background-image: url(/assets/images/login.jpg);
    background-repeat: no-repeat;
    overflow: hidden;
    margin: auto;
    width: 100%;
    position: relative;
    min-height: 100%;
}
.loginContainer {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    // min-height: 95vh;
}
.loginWrapper .loginContainer .loginTitle{
    padding: 0 0 4.61rem;
    max-width: 61.53rem;
    font-size: 1.23rem;
    line-height: 2rem;
    margin: 0 auto;
}
.loginWrapper .loginContainer .loginInner{
    min-width: 14rem;
    max-width: 26rem;
    margin: 0 auto;
    padding: 2rem;
    border-radius: 10px;
    overflow: hidden;
    background-color: $colorWhite;
    position: relative;
    z-index: 2;
}

/***********************************************
                GENERAL LOGIN
***********************************************/
.loginMemberContainer {
    display: flex;
    flex-direction: column;
    min-height: 95vh;
}
.loginWrapper .loginMemberContainer .loginTitle{
    padding: 0 0 4.61rem;
    max-width: 61.53rem;
    font-size: 1.23rem;
    line-height: 2rem;
    margin: 0 auto;
}
.loginWrapper .loginMemberContainer .loginInner{
    min-width: 14rem;
    max-width: 26rem;
    margin: 0 auto;
    padding: 2rem;
    border-radius: 10px;
    overflow: hidden;
    background-color: $colorWhite;
    position: relative;
    z-index: 2;
}
.loginWrapper .loginMemberContainer .loginMemberTitle {
    flex: 1 1 0%;
    box-sizing: border-box;
    padding: 128px;
}

.loginMemberTitle .memberLoginLogo {
    width: 128px;
    margin-bottom: 32px;
}
.loginMemberTitle .memberLoginTitle {
    font-size: 42px;
    font-weight: 300;
    line-height: 1;
}
.loginMemberTitle .memberLoginDescription {
    padding-top: 16px;
    font-size: 14px;
    max-width: 600px;
}
.loginFlexDirectionRow {
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    place-content: stretch flex-start;
    align-items: stretch;
}
.loginFormWrapper {
    width: 400px;
    min-width: 400px;
    max-width: 400px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    box-shadow: 0 8px 10px -5px rgba(0,0,0,.2), 0 16px 24px 2px rgba(0,0,0,.14), 0 6px 30px 5px rgba(0,0,0,.12);
    background: #fff;
    position: relative;
    overflow: hidden !important;
    overflow-anchor: none;
    transform: translateX(100%);
    transition: transform 0.2s ease;
    will-change: transform;
}
.loginFormWrapperOpen {
    transform: translateX(0) !important;
}
.loginFormInner {
    padding: 128px 48px 48px;
}
.loginFormInner .memberLoginFormLogo {
    margin: 32px auto;
    text-align: center;
}
.loginFormInner .memberLoginFormTitle {
    font-size: 21px;
}
@media (max-width: 767.98px) {
    .loginFormWrapper {
        min-width: 0;
        width: 100%;
        max-width: 100%;
    }
    .loginFormInner {
        padding: 28px;
    }
    .loginMemberTitle {
        display: none;
    }
  }
  @media (min-width: 768px) {
    .memberLoginFormLogo .pcHidden {
        display: none;
    }
  }