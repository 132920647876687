@import 'colorfonts';

/***********************************************
                    SHOP
***********************************************/
.page_top {
    // padding: 20px;
    color: rgba (0,0,0,.87);
    font-size: 1rem;
    font-weight: 400;
    font-family: $workSans;
    // line-height: 1.5em;
    border-bottom: 1px solid;
}

.gridsRow {
    padding: 20px 0px 20px 20px;
}

.active .gridsRowView{
    color: $colorBlack;
}
.gridsRowView{
    color: $colorGrey;
    margin: 0 12px 0 0;
    font-size: 26px;
    line-height: 32px;
    cursor: pointer;
}
.displayFlex {
    display: flex;
}
.paddingShop {
    padding-bottom: 25px !important;
}
.paddingShop .cardItemWrapper .image {
    height: 165px;
}
.paddingShop .addToCart {
    margin-left: 22px;
}
// .cardBlockShopStyle .cardItemWrapper .addToCart {
//     margin-left: 20px !important;
// }

.filterWrapper {
    padding-top: 25px;
}
.filterTitle {
    margin-bottom: 20px;
    position: relative;
}
.filterTitle h3 {
    color: #333333;
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
    margin-top: -3px;
    padding-left: 10px;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
}
.filterLists {
    margin: 0;
    padding: 0;
}
.filterLists li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-bottom: 1px solid #e5e5e5;
}
.filterLists li:last-child {
    border-bottom: none;
}
// .filterLists li:first-child a {
//     padding-top: 0
// }
.filterLists li .filterItem {
    color: #333333;
    font-size: 15px;
    line-height: 1.5;
    padding: 10px;
    display: inline-block;
    text-transform: capitalize;
    width: 100%;
    cursor: pointer;
}
.filterLists li .filterItem:hover {
    background-color: rgba(0,0,0,0.1);
}
.filterLists li span{
    padding-bottom: 2px;
    float: right;
    margin-right: 5px;
}
.filterLists li input {
    float: right;
}
.filterHidden {
    position: absolute;
    z-index: 1000;
    left: 0;
    background-color: #fff;
    width: 250px;
    transform: translateX(-100%);
    transition: transform 0.2s ease;
    will-change: transform;
    top: 0;
}
.filterHidden.open {
    transform: translateX(0) !important;
}
.additionaldata {
    display: none;
}
