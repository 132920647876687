.newbtn:not(:disabled):not(.disabled) {
    cursor: pointer;
}
.newbtn {
    border-radius: 0;
    font-size: 13px;
    font-weight: 400;
    color: #bc9051;
    text-transform: uppercase;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: 0.4s;
    -o-translation: 0.4s;
    transition: 0.4s;
    display: inline-block;
    padding: 7px 21px;
    border: 1px solid #bc9051;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    line-height: 1.5;
}
@media (max-width: 767.98px) {
    .newbtn.checkout {
        font-size: 13px;
        padding: 7px 26px;
    }
}
@media (min-width: 768px) and (max-width: 835px) {
    .newbtn.checkout {
        font-size: 10px;
        padding: 7px 25px;
    }
}

.newbtn:hover {
    background-color: rgba(77, 82, 89, 0.7);
}

.newbtnbg {
    color: #fff;
    background-color: #bc9051;
}
.newbtnaddtocart {
    color: #fff;
    background-color: #bc9051;
    width: 100%;
}