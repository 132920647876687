/***********************************************
                SIDE DRAWER
***********************************************/
.memberSideDrawerLogo {
    padding: 3px 3px 3px 7px;
    // text-align: center;
    // box-shadow: 4px 0 5px rgba(0, 0, 0, 0.2);
    // border-bottom: 1px solid rgba(255,255,255,0.2)
}
.memberSideDrawer{
    height: 100%;
    background: #fff;
    // box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    max-width: 400px;
    z-index: 1200;
    -webkit-transform: translateX(-110%);
    -moz-transform: translateX(-110%);
    -ms-transform: translateX(-110%);
    transform: translateX(-110%);
    transition: transform 0.2s ease;
    will-change: transform;
}
.memberSideDrawer.open {
    transform: translateX(0);
    overflow-y: auto;
    // height: 100%;
    top: 0%;
    bottom: 0%;
    position: fixed;
    // -webkit-overflow-scrolling: touch;
}
.memberSideDrawer.open ul {
    max-height: 50vh;
    
}
.memberSideDrawer ul {
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // padding-left: 10px;
}
.memberSideDrawer a, .sideDrawer .icon {
    color: #213e96;
    text-decoration: none;
    font-size: 1.2rem;
}
.memberSideDrawer a:hover {
    color: #404040;
}
.memberSideDrawer a:active {
    color: $sideDrawerAHover;
}
.memberSideDrawer > ul > li > a{
    border: none;
    display: block;
    position: relative;
    // padding: 5px;
    border-radius: 0;
    margin: 0;
    text-align: -webkit-match-parent;
}
.memberSideDrawer > ul > li > a.active {
    background-color: #213e96;
    border-right: 4px solid #fff;
}
.memberSideDrawer > ul > li a.active,
.memberSideDrawer > ul > li a.active .icon{
    color: $sideDrawerAHover;
}
.memberSideDrawer .itemContent {
    align-items: center;
    display: table-row;
}
.memberSideDrawer .itemIcon {
    padding: 10px 15px;
    width: 55px;
}
.memberSideDrawer .itemInner {
    display: table-cell;
    width: 100%;
    vertical-align: middle;
    white-space: nowrap;
    align-items: center;
    padding: 10px 15px 10px 10px;
}
.memberSideDrawer .iconContent {
    background: $sideDrawerBg;
    box-shadow: none;
    height: 53px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.memberSideDrawer .iconContent > ul {
    flex-direction: row;
    position: relative;
    justify-content: space-between;
    // float: right;
}
.memberSideDrawer .iconContent > ul > li {
    float: left;
    position: relative;
    display: block;
}
.memberSideDrawer .iconContent > li:before {
    content: "";
    position: absolute;
    width: 1px;
    right: 0;
    top: 5px;
    bottom: 5px;
    background: -moz-linear-gradient(top, rgba(206,206,206,0) 0%, rgba(206,206,206,0.2) 50%, rgba(206,206,206,0) 100%);
    background: -webkit-linear-gradient(top, rgba(206,206,206,0) 0%, rgba(206,206,206,0.2) 50%, rgba(206,206,206,0) 100%);
    background: linear-gradient(to bottom, rgba(206,206,206,0) 0%, rgba(206,206,206,0.2) 50%, rgba(206,206,206,0) 100%);
    box-sizing: border-box;
}
.memberSideDrawer .iconContent > ul > li > a {
    letter-spacing: 0;
    opacity: 0.9;
    padding: 7px;
    position: relative;
    text-align: center;
    display: block;
    
}
.memberSideDrawer .iconContent > ul > li > a.active {
    background-color: $sideDrawerBGColor;
    border-bottom: 4px solid $sideDrawerRightBorder;
}
.memberSideDrawer .iconContent > ul > li > a.active > .iconWrapper > .icon {
    color: $colorWhite;
}
.memberSideDrawer .iconContent .iconWrapper {
    padding: 9px;
}
.memberSidenavTitle {
    padding: 5px 20px 8px 20px;
    background-color: #fff;
    color: #272723;
    text-transform: uppercase;
    font-weight: 500;
    display: block;

}
/***********************************************
                TOGGLE BUTTON
***********************************************/
.memberToggleButton .toggle_button {
    display: flex;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    padding: 15px;
}

.memberToggleButton .toggle_button:focus,
.memberToggleButton .toggle_button:active,
.memberToggleButton .toggle_button:hover {
    outline: none !important;
}

.memberToggleButton .toggle_button .icon {
    font-size: 26px;
}

.memberToggleButton .toggle_button .icon:focus, 
.memberToggleButton .toggle_button .icon:active, 
.memberToggleButton .toggle_button .icon:hover {
    outline: none !important;
}

@media (max-width: 1025px){
    .memberpageLeftNav {
        display: none !important;
    }
}